import { Box, Stack, Typography } from '@mui/material'
import { ContainerVisitStatusChip } from '@planning/components/ContainerVisitStatusChip'
import { ContainerVisitsCardBody } from '@planning/pages/Order/components/ContainerVisitsCard/ContainerVisitsCardBody'
import { ContainerVisitsCardHeader } from '@planning/pages/Order/components/ContainerVisitsCard/ContainerVisitsCardHeader'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IIssueItem } from '../Stores/IssueItem'

type Props = {
  issue?: IIssueItem
  railcarName?: string
}

export const ResolveIssueCard: FC<Props> = observer(({ issue, railcarName }) => {
  const { t } = useTranslate()

  const theme = useMinimalsTheme()

  if (!issue?.containerJourney) return <></>

  return (
    <Box>
      <Typography variant='subtitle1'>{t(issue.data.issueType)}</Typography>

      <Box
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.customRadius.medium,
          mt: '1rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ paddingRight: '1rem' }}
        >
          <ContainerVisitsCardHeader
            container={issue.containerJourney.container}
            isDamaged={issue.containerJourney.isDamagedContainer}
          />
          <ContainerVisitStatusChip
            label={t(_(issue.containerJourney.status).lowerFirst())}
            status={issue.containerJourney.status}
          />
        </Stack>

        <Box
          sx={{
            bgcolor: theme.palette.grey[100],
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <ContainerVisitsCardBody containerVisit={issue.containerJourney} isInsideIssueCard />
        </Box>
      </Box>
    </Box>
  )
})
