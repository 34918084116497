/* tslint:disable */
/* eslint-disable */
/**
 * TOM ADMIN SERVICE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AuthConfiguration
 */
export interface AuthConfiguration {
  /**
   *
   * @type {string}
   * @memberof AuthConfiguration
   */
  audience: string
  /**
   *
   * @type {string}
   * @memberof AuthConfiguration
   */
  domain: string
  /**
   *
   * @type {string}
   * @memberof AuthConfiguration
   */
  clientId: string
}
/**
 *
 * @export
 * @interface ContainerDamageLocationDto
 */
export interface ContainerDamageLocationDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageLocationDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationDto
   */
  code?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerDamageLocationDto
   */
  isDefault: boolean
}
/**
 *
 * @export
 * @interface ContainerDamageLocationsPostRequest
 */
export interface ContainerDamageLocationsPostRequest {
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationsPostRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationsPostRequest
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface ContainerDamageLocationsPutRequest
 */
export interface ContainerDamageLocationsPutRequest {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageLocationsPutRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationsPutRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationsPutRequest
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface ContainerDamageTypeDto
 */
export interface ContainerDamageTypeDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageTypeDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypeDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypeDto
   */
  code?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerDamageTypeDto
   */
  isDefault: boolean
}
/**
 *
 * @export
 * @interface ContainerDamageTypesPostRequest
 */
export interface ContainerDamageTypesPostRequest {
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypesPostRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypesPostRequest
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface ContainerDamageTypesPutRequest
 */
export interface ContainerDamageTypesPutRequest {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageTypesPutRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypesPutRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypesPutRequest
   */
  code?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContainerHeight = {
  Standard: 'Standard',
  High: 'High',
  Low: 'Low',
} as const

export type ContainerHeight = (typeof ContainerHeight)[keyof typeof ContainerHeight]

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerType = {
  Unknown: 'Unknown',
  General: 'General',
  GeneralVentilated: 'GeneralVentilated',
  Reefer: 'Reefer',
  OpenTop: 'OpenTop',
  Platform: 'Platform',
  Tank: 'Tank',
  Bulk: 'Bulk',
  Foldable: 'Foldable',
  AirSurface: 'AirSurface',
  Livestock: 'Livestock',
  Auto: 'Auto',
  Fish: 'Fish',
  NonCargoCarrying: 'NonCargoCarrying',
} as const

export type ContainerType = (typeof ContainerType)[keyof typeof ContainerType]

/**
 *
 * @export
 * @interface CreateContainerDamageLocationCommand
 */
export interface CreateContainerDamageLocationCommand {
  /**
   *
   * @type {string}
   * @memberof CreateContainerDamageLocationCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateContainerDamageLocationCommand
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface CreateContainerDamageTypeCommand
 */
export interface CreateContainerDamageTypeCommand {
  /**
   *
   * @type {string}
   * @memberof CreateContainerDamageTypeCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateContainerDamageTypeCommand
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface CreateIsoCodeMappingCommand
 */
export interface CreateIsoCodeMappingCommand {
  /**
   *
   * @type {string}
   * @memberof CreateIsoCodeMappingCommand
   */
  isoCode: string
  /**
   *
   * @type {number}
   * @memberof CreateIsoCodeMappingCommand
   */
  length: number
  /**
   *
   * @type {ContainerHeight}
   * @memberof CreateIsoCodeMappingCommand
   */
  height: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof CreateIsoCodeMappingCommand
   */
  type: ContainerType
}
/**
 *
 * @export
 * @interface CreateTenantCommand
 */
export interface CreateTenantCommand {
  /**
   *
   * @type {string}
   * @memberof CreateTenantCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateTenantCommand
   */
  timezoneId?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTenantCommand
   */
  cultureId?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTenantCommand
   */
  address?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  isInlandJobCreationFlow: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipCraneSplit: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipGateOut: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipVesselVisitReadyForOperation: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipReleaseOrder: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  skipBerthTimestamp: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  showUnifiedOperatorPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  bayRowUniqueIdentificationMode: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  hasGeneralCargo: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantCommand
   */
  hasDamageCatalogue: boolean
  /**
   *
   * @type {Array<UnLocationCodeDto>}
   * @memberof CreateTenantCommand
   */
  unloCodes: Array<UnLocationCodeDto>
}
/**
 *
 * @export
 * @interface CreateUserCommand
 */
export interface CreateUserCommand {
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  lastName: string
  /**
   *
   * @type {number}
   * @memberof CreateUserCommand
   */
  tenantId?: number | null
}
/**
 *
 * @export
 * @interface IsoCodeMappingResponseDto
 */
export interface IsoCodeMappingResponseDto {
  /**
   *
   * @type {number}
   * @memberof IsoCodeMappingResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof IsoCodeMappingResponseDto
   */
  isoCode: string
  /**
   *
   * @type {number}
   * @memberof IsoCodeMappingResponseDto
   */
  length: number
  /**
   *
   * @type {ContainerHeight}
   * @memberof IsoCodeMappingResponseDto
   */
  height: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof IsoCodeMappingResponseDto
   */
  type: ContainerType
}
/**
 *
 * @export
 * @interface IsoCodeMappingsPostRequest
 */
export interface IsoCodeMappingsPostRequest {
  /**
   *
   * @type {string}
   * @memberof IsoCodeMappingsPostRequest
   */
  isoCode: string
  /**
   *
   * @type {number}
   * @memberof IsoCodeMappingsPostRequest
   */
  length: number
  /**
   *
   * @type {ContainerHeight}
   * @memberof IsoCodeMappingsPostRequest
   */
  height: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof IsoCodeMappingsPostRequest
   */
  type: ContainerType
}
/**
 *
 * @export
 * @interface IsoCodeMappingsPutRequest
 */
export interface IsoCodeMappingsPutRequest {
  /**
   *
   * @type {number}
   * @memberof IsoCodeMappingsPutRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof IsoCodeMappingsPutRequest
   */
  isoCode: string
  /**
   *
   * @type {number}
   * @memberof IsoCodeMappingsPutRequest
   */
  length: number
  /**
   *
   * @type {ContainerHeight}
   * @memberof IsoCodeMappingsPutRequest
   */
  height: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof IsoCodeMappingsPutRequest
   */
  type: ContainerType
}
/**
 *
 * @export
 * @interface NamedId
 */
export interface NamedId {
  /**
   *
   * @type {string}
   * @memberof NamedId
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof NamedId
   */
  name: string
}
/**
 *
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
  /**
   *
   * @type {string}
   * @memberof RoleDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RoleDto
   */
  name: string
}
/**
 *
 * @export
 * @interface TenantConfigDto
 */
export interface TenantConfigDto {
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  isInlandJobCreationFlow: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipCraneSplit: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipGateOut: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipVesselVisitReadyForOperation: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipReleaseOrder: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipBerthTimestamp: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  showUnifiedOperatorPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  bayRowUniqueIdentificationMode: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  hasGeneralCargo: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  hasDamageCatalogue: boolean
  /**
   *
   * @type {string}
   * @memberof TenantConfigDto
   */
  currentOperatorViewSettings: string
  /**
   *
   * @type {TerminalType}
   * @memberof TenantConfigDto
   */
  selectedOperatorViewConfigs: TerminalType
}
/**
 *
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
  /**
   *
   * @type {number}
   * @memberof TenantDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TenantDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TenantDto
   */
  timezoneId?: string | null
  /**
   *
   * @type {string}
   * @memberof TenantDto
   */
  cultureId?: string | null
  /**
   *
   * @type {string}
   * @memberof TenantDto
   */
  address?: string | null
  /**
   *
   * @type {TenantConfigDto}
   * @memberof TenantDto
   */
  configs?: TenantConfigDto | null
  /**
   *
   * @type {Array<UnLocationCodeDto>}
   * @memberof TenantDto
   */
  unloCodes: Array<UnLocationCodeDto>
}
/**
 *
 * @export
 * @interface TenantsPostRequest
 */
export interface TenantsPostRequest {
  /**
   *
   * @type {string}
   * @memberof TenantsPostRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TenantsPostRequest
   */
  timezoneId?: string | null
  /**
   *
   * @type {string}
   * @memberof TenantsPostRequest
   */
  cultureId?: string | null
  /**
   *
   * @type {string}
   * @memberof TenantsPostRequest
   */
  address?: string | null
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  isInlandJobCreationFlow: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipCraneSplit: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipGateOut: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipVesselVisitReadyForOperation: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipReleaseOrder: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  skipBerthTimestamp: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  showUnifiedOperatorPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  bayRowUniqueIdentificationMode: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  hasGeneralCargo: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPostRequest
   */
  hasDamageCatalogue: boolean
  /**
   *
   * @type {Array<UnLocationCodeDto>}
   * @memberof TenantsPostRequest
   */
  unloCodes: Array<UnLocationCodeDto>
}
/**
 *
 * @export
 * @interface TenantsPutRequest
 */
export interface TenantsPutRequest {
  /**
   *
   * @type {number}
   * @memberof TenantsPutRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TenantsPutRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TenantsPutRequest
   */
  timezoneId?: string | null
  /**
   *
   * @type {string}
   * @memberof TenantsPutRequest
   */
  cultureId?: string | null
  /**
   *
   * @type {string}
   * @memberof TenantsPutRequest
   */
  address?: string | null
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  isInlandJobCreationFlow: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipCraneSplit: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipGateOut: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipVesselVisitReadyForOperation: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipReleaseOrder: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  skipBerthTimestamp: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  showUnifiedOperatorPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  bayRowUniqueIdentificationMode: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  hasGeneralCargo: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantsPutRequest
   */
  hasDamageCatalogue: boolean
  /**
   *
   * @type {TerminalType}
   * @memberof TenantsPutRequest
   */
  operatorViewConfigs: TerminalType
  /**
   *
   * @type {Array<UnLocationCodeDto>}
   * @memberof TenantsPutRequest
   */
  unloCodes: Array<UnLocationCodeDto>
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerminalType = {
  Hig: 'HIG',
  Inland: 'INLAND',
  Unknown: 'UNKNOWN',
} as const

export type TerminalType = (typeof TerminalType)[keyof typeof TerminalType]

/**
 *
 * @export
 * @interface TimezonesAndCulturesDto
 */
export interface TimezonesAndCulturesDto {
  /**
   *
   * @type {Array<NamedId>}
   * @memberof TimezonesAndCulturesDto
   */
  timezones: Array<NamedId>
  /**
   *
   * @type {Array<NamedId>}
   * @memberof TimezonesAndCulturesDto
   */
  cultureCodes: Array<NamedId>
}
/**
 *
 * @export
 * @interface UnLocationCodeDto
 */
export interface UnLocationCodeDto {
  /**
   *
   * @type {string}
   * @memberof UnLocationCodeDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof UnLocationCodeDto
   */
  isDefault: boolean
}
/**
 *
 * @export
 * @interface UpdateContainerDamageLocationCommand
 */
export interface UpdateContainerDamageLocationCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateContainerDamageLocationCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateContainerDamageLocationCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateContainerDamageLocationCommand
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface UpdateContainerDamageTypeCommand
 */
export interface UpdateContainerDamageTypeCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateContainerDamageTypeCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateContainerDamageTypeCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateContainerDamageTypeCommand
   */
  code?: string | null
}
/**
 *
 * @export
 * @interface UpdateIsoCodeMappingCommand
 */
export interface UpdateIsoCodeMappingCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateIsoCodeMappingCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateIsoCodeMappingCommand
   */
  isoCode: string
  /**
   *
   * @type {number}
   * @memberof UpdateIsoCodeMappingCommand
   */
  length: number
  /**
   *
   * @type {ContainerHeight}
   * @memberof UpdateIsoCodeMappingCommand
   */
  height: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof UpdateIsoCodeMappingCommand
   */
  type: ContainerType
}
/**
 *
 * @export
 * @interface UpdateTenantCommand
 */
export interface UpdateTenantCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateTenantCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateTenantCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateTenantCommand
   */
  timezoneId?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateTenantCommand
   */
  cultureId?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateTenantCommand
   */
  address?: string | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  isInlandJobCreationFlow: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipCraneSplit: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipGateOut: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipVesselVisitReadyForOperation: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipReleaseOrder: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  skipBerthTimestamp: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  showUnifiedOperatorPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  bayRowUniqueIdentificationMode: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  hasGeneralCargo: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantCommand
   */
  hasDamageCatalogue: boolean
  /**
   *
   * @type {TerminalType}
   * @memberof UpdateTenantCommand
   */
  operatorViewConfigs: TerminalType
  /**
   *
   * @type {Array<UnLocationCodeDto>}
   * @memberof UpdateTenantCommand
   */
  unloCodes: Array<UnLocationCodeDto>
}
/**
 *
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserCommand
   */
  lastName: string
  /**
   *
   * @type {number}
   * @memberof UpdateUserCommand
   */
  tenantId?: number | null
}
/**
 *
 * @export
 * @interface UpdateUserRolesCommand
 */
export interface UpdateUserRolesCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateUserRolesCommand
   */
  userId: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserRolesCommand
   */
  roleIds: Array<string>
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  given_name: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  family_name: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  user_id: string
  /**
   *
   * @type {number}
   * @memberof UserDto
   */
  tenant_id?: number | null
  /**
   *
   * @type {any}
   * @memberof UserDto
   */
  userMetadata?: any | null
}
/**
 *
 * @export
 * @interface UsersPostRequest
 */
export interface UsersPostRequest {
  /**
   *
   * @type {string}
   * @memberof UsersPostRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UsersPostRequest
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof UsersPostRequest
   */
  lastName: string
  /**
   *
   * @type {number}
   * @memberof UsersPostRequest
   */
  tenantId?: number | null
}
/**
 *
 * @export
 * @interface UsersPutRequest
 */
export interface UsersPutRequest {
  /**
   *
   * @type {string}
   * @memberof UsersPutRequest
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof UsersPutRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UsersPutRequest
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof UsersPutRequest
   */
  lastName: string
  /**
   *
   * @type {number}
   * @memberof UsersPutRequest
   */
  tenantId?: number | null
}
/**
 *
 * @export
 * @interface UsersUpdateUserRolesRequest
 */
export interface UsersUpdateUserRolesRequest {
  /**
   *
   * @type {string}
   * @memberof UsersUpdateUserRolesRequest
   */
  userId: string
  /**
   *
   * @type {Array<string>}
   * @memberof UsersUpdateUserRolesRequest
   */
  roleIds: Array<string>
}
/**
 *
 * @export
 * @interface WebAppConfiguration
 */
export interface WebAppConfiguration {
  /**
   *
   * @type {WebAppConfigurationAuth}
   * @memberof WebAppConfiguration
   */
  auth: WebAppConfigurationAuth
  /**
   *
   * @type {string}
   * @memberof WebAppConfiguration
   */
  environment: string
}
/**
 *
 * @export
 * @interface WebAppConfigurationAuth
 */
export interface WebAppConfigurationAuth {
  /**
   *
   * @type {string}
   * @memberof WebAppConfigurationAuth
   */
  audience: string
  /**
   *
   * @type {string}
   * @memberof WebAppConfigurationAuth
   */
  domain: string
  /**
   *
   * @type {string}
   * @memberof WebAppConfigurationAuth
   */
  clientId: string
}

/**
 * ContainerDamageLocationsApi - axios parameter creator
 * @export
 */
export const ContainerDamageLocationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsDelete: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('containerDamageLocationsDelete', 'id', id)
      const localVarPath = `/admin/api/ContainerDamageLocations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ContainerDamageLocationsPostRequest} [containerDamageLocationsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsPost: async (
      containerDamageLocationsPostRequest?: ContainerDamageLocationsPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        containerDamageLocationsPostRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {ContainerDamageLocationsPutRequest} [containerDamageLocationsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsPut: async (
      id: number,
      containerDamageLocationsPutRequest?: ContainerDamageLocationsPutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('containerDamageLocationsPut', 'id', id)
      const localVarPath = `/admin/api/ContainerDamageLocations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        containerDamageLocationsPutRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainerDamageLocationsApi - functional programming interface
 * @export
 */
export const ContainerDamageLocationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainerDamageLocationsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocationsDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocationsDelete(
        id,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocationsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerDamageLocationDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocationsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ContainerDamageLocationsPostRequest} [containerDamageLocationsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocationsPost(
      containerDamageLocationsPostRequest?: ContainerDamageLocationsPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocationsPost(
        containerDamageLocationsPostRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {ContainerDamageLocationsPutRequest} [containerDamageLocationsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocationsPut(
      id: number,
      containerDamageLocationsPutRequest?: ContainerDamageLocationsPutRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocationsPut(
        id,
        containerDamageLocationsPutRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainerDamageLocationsApi - factory interface
 * @export
 */
export const ContainerDamageLocationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainerDamageLocationsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocationsDelete(id, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsGet(options?: any): AxiosPromise<Array<ContainerDamageLocationDto>> {
      return localVarFp
        .containerDamageLocationsGet(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {ContainerDamageLocationsPostRequest} [containerDamageLocationsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsPost(
      containerDamageLocationsPostRequest?: ContainerDamageLocationsPostRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .containerDamageLocationsPost(containerDamageLocationsPostRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {ContainerDamageLocationsPutRequest} [containerDamageLocationsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocationsPut(
      id: number,
      containerDamageLocationsPutRequest?: ContainerDamageLocationsPutRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocationsPut(id, containerDamageLocationsPutRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainerDamageLocationsApi - object-oriented interface
 * @export
 * @class ContainerDamageLocationsApi
 * @extends {BaseAPI}
 */
export class ContainerDamageLocationsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageLocationsApi
   */
  public containerDamageLocationsDelete(id: number, options?: AxiosRequestConfig) {
    return ContainerDamageLocationsApiFp(this.configuration)
      .containerDamageLocationsDelete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageLocationsApi
   */
  public containerDamageLocationsGet(options?: AxiosRequestConfig) {
    return ContainerDamageLocationsApiFp(this.configuration)
      .containerDamageLocationsGet(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ContainerDamageLocationsPostRequest} [containerDamageLocationsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageLocationsApi
   */
  public containerDamageLocationsPost(
    containerDamageLocationsPostRequest?: ContainerDamageLocationsPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ContainerDamageLocationsApiFp(this.configuration)
      .containerDamageLocationsPost(containerDamageLocationsPostRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {ContainerDamageLocationsPutRequest} [containerDamageLocationsPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageLocationsApi
   */
  public containerDamageLocationsPut(
    id: number,
    containerDamageLocationsPutRequest?: ContainerDamageLocationsPutRequest,
    options?: AxiosRequestConfig,
  ) {
    return ContainerDamageLocationsApiFp(this.configuration)
      .containerDamageLocationsPut(id, containerDamageLocationsPutRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ContainerDamageTypesApi - axios parameter creator
 * @export
 */
export const ContainerDamageTypesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesDelete: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('containerDamageTypesDelete', 'id', id)
      const localVarPath = `/admin/api/ContainerDamageTypes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ContainerDamageTypesPostRequest} [containerDamageTypesPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesPost: async (
      containerDamageTypesPostRequest?: ContainerDamageTypesPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        containerDamageTypesPostRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {ContainerDamageTypesPutRequest} [containerDamageTypesPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesPut: async (
      id: number,
      containerDamageTypesPutRequest?: ContainerDamageTypesPutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('containerDamageTypesPut', 'id', id)
      const localVarPath = `/admin/api/ContainerDamageTypes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        containerDamageTypesPutRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainerDamageTypesApi - functional programming interface
 * @export
 */
export const ContainerDamageTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainerDamageTypesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypesDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypesDelete(
        id,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerDamageTypeDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypesGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ContainerDamageTypesPostRequest} [containerDamageTypesPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypesPost(
      containerDamageTypesPostRequest?: ContainerDamageTypesPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypesPost(
        containerDamageTypesPostRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {ContainerDamageTypesPutRequest} [containerDamageTypesPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypesPut(
      id: number,
      containerDamageTypesPutRequest?: ContainerDamageTypesPutRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypesPut(
        id,
        containerDamageTypesPutRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainerDamageTypesApi - factory interface
 * @export
 */
export const ContainerDamageTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainerDamageTypesApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypesDelete(id, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesGet(options?: any): AxiosPromise<Array<ContainerDamageTypeDto>> {
      return localVarFp.containerDamageTypesGet(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {ContainerDamageTypesPostRequest} [containerDamageTypesPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesPost(
      containerDamageTypesPostRequest?: ContainerDamageTypesPostRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .containerDamageTypesPost(containerDamageTypesPostRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {ContainerDamageTypesPutRequest} [containerDamageTypesPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypesPut(
      id: number,
      containerDamageTypesPutRequest?: ContainerDamageTypesPutRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypesPut(id, containerDamageTypesPutRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainerDamageTypesApi - object-oriented interface
 * @export
 * @class ContainerDamageTypesApi
 * @extends {BaseAPI}
 */
export class ContainerDamageTypesApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageTypesApi
   */
  public containerDamageTypesDelete(id: number, options?: AxiosRequestConfig) {
    return ContainerDamageTypesApiFp(this.configuration)
      .containerDamageTypesDelete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageTypesApi
   */
  public containerDamageTypesGet(options?: AxiosRequestConfig) {
    return ContainerDamageTypesApiFp(this.configuration)
      .containerDamageTypesGet(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ContainerDamageTypesPostRequest} [containerDamageTypesPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageTypesApi
   */
  public containerDamageTypesPost(
    containerDamageTypesPostRequest?: ContainerDamageTypesPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ContainerDamageTypesApiFp(this.configuration)
      .containerDamageTypesPost(containerDamageTypesPostRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {ContainerDamageTypesPutRequest} [containerDamageTypesPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageTypesApi
   */
  public containerDamageTypesPut(
    id: number,
    containerDamageTypesPutRequest?: ContainerDamageTypesPutRequest,
    options?: AxiosRequestConfig,
  ) {
    return ContainerDamageTypesApiFp(this.configuration)
      .containerDamageTypesPut(id, containerDamageTypesPutRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * IsoCodeMappingsApi - axios parameter creator
 * @export
 */
export const IsoCodeMappingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsDelete: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('isoCodeMappingsDelete', 'id', id)
      const localVarPath = `/admin/api/IsoCodeMappings/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/IsoCodeMappings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {IsoCodeMappingsPostRequest} [isoCodeMappingsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsPost: async (
      isoCodeMappingsPostRequest?: IsoCodeMappingsPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/IsoCodeMappings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        isoCodeMappingsPostRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {IsoCodeMappingsPutRequest} [isoCodeMappingsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsPut: async (
      isoCodeMappingsPutRequest?: IsoCodeMappingsPutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/IsoCodeMappings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        isoCodeMappingsPutRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * IsoCodeMappingsApi - functional programming interface
 * @export
 */
export const IsoCodeMappingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IsoCodeMappingsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isoCodeMappingsDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isoCodeMappingsDelete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isoCodeMappingsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IsoCodeMappingResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isoCodeMappingsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {IsoCodeMappingsPostRequest} [isoCodeMappingsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isoCodeMappingsPost(
      isoCodeMappingsPostRequest?: IsoCodeMappingsPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isoCodeMappingsPost(
        isoCodeMappingsPostRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {IsoCodeMappingsPutRequest} [isoCodeMappingsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isoCodeMappingsPut(
      isoCodeMappingsPutRequest?: IsoCodeMappingsPutRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isoCodeMappingsPut(
        isoCodeMappingsPutRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * IsoCodeMappingsApi - factory interface
 * @export
 */
export const IsoCodeMappingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IsoCodeMappingsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.isoCodeMappingsDelete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsGet(options?: any): AxiosPromise<Array<IsoCodeMappingResponseDto>> {
      return localVarFp.isoCodeMappingsGet(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {IsoCodeMappingsPostRequest} [isoCodeMappingsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsPost(
      isoCodeMappingsPostRequest?: IsoCodeMappingsPostRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .isoCodeMappingsPost(isoCodeMappingsPostRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {IsoCodeMappingsPutRequest} [isoCodeMappingsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isoCodeMappingsPut(
      isoCodeMappingsPutRequest?: IsoCodeMappingsPutRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .isoCodeMappingsPut(isoCodeMappingsPutRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * IsoCodeMappingsApi - object-oriented interface
 * @export
 * @class IsoCodeMappingsApi
 * @extends {BaseAPI}
 */
export class IsoCodeMappingsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IsoCodeMappingsApi
   */
  public isoCodeMappingsDelete(id: number, options?: AxiosRequestConfig) {
    return IsoCodeMappingsApiFp(this.configuration)
      .isoCodeMappingsDelete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IsoCodeMappingsApi
   */
  public isoCodeMappingsGet(options?: AxiosRequestConfig) {
    return IsoCodeMappingsApiFp(this.configuration)
      .isoCodeMappingsGet(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {IsoCodeMappingsPostRequest} [isoCodeMappingsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IsoCodeMappingsApi
   */
  public isoCodeMappingsPost(
    isoCodeMappingsPostRequest?: IsoCodeMappingsPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return IsoCodeMappingsApiFp(this.configuration)
      .isoCodeMappingsPost(isoCodeMappingsPostRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {IsoCodeMappingsPutRequest} [isoCodeMappingsPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IsoCodeMappingsApi
   */
  public isoCodeMappingsPut(
    isoCodeMappingsPutRequest?: IsoCodeMappingsPutRequest,
    options?: AxiosRequestConfig,
  ) {
    return IsoCodeMappingsApiFp(this.configuration)
      .isoCodeMappingsPut(isoCodeMappingsPutRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Roles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rolesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RolesApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rolesGet(options?: any): AxiosPromise<Array<RoleDto>> {
      return localVarFp.rolesGet(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RolesApi
   */
  public rolesGet(options?: AxiosRequestConfig) {
    return RolesApiFp(this.configuration)
      .rolesGet(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsCreateDefaultDamageLocationsForAllExistingTenants: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants/CreateDefaultDamageLocationsForAllExistingTenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsCreateDefaultDamageTypesForAllExistingTenants: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants/CreateDefaultDamageTypesForAllExistingTenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tenantsDelete', 'id', id)
      const localVarPath = `/admin/api/Tenants/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsGetCurrentConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants/getCurrentConfigs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsGetTimezonesAndCulture: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants/GetTimeZonesAndCulture`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsIsUnique: async (
      name?: string,
      id?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants/IsUnique`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TenantsPostRequest} [tenantsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsPost: async (
      tenantsPostRequest?: TenantsPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPostRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TenantsPutRequest} [tenantsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsPut: async (
      tenantsPutRequest?: TenantsPutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPutRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsCreateDefaultDamageLocationsForAllExistingTenants(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantsCreateDefaultDamageLocationsForAllExistingTenants(
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsCreateDefaultDamageTypesForAllExistingTenants(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantsCreateDefaultDamageTypesForAllExistingTenants(
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsDelete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantsDelete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsGetCurrentConfigs(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConfigDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantsGetCurrentConfigs(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsGetTimezonesAndCulture(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimezonesAndCulturesDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantsGetTimezonesAndCulture(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsIsUnique(
      name?: string,
      id?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantsIsUnique(name, id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {TenantsPostRequest} [tenantsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsPost(
      tenantsPostRequest?: TenantsPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantsPost(
        tenantsPostRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {TenantsPutRequest} [tenantsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantsPut(
      tenantsPutRequest?: TenantsPutRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenantsPut(
        tenantsPutRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsCreateDefaultDamageLocationsForAllExistingTenants(options?: any): AxiosPromise<void> {
      return localVarFp
        .tenantsCreateDefaultDamageLocationsForAllExistingTenants(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsCreateDefaultDamageTypesForAllExistingTenants(options?: any): AxiosPromise<void> {
      return localVarFp
        .tenantsCreateDefaultDamageTypesForAllExistingTenants(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.tenantsDelete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsGet(options?: any): AxiosPromise<Array<TenantDto>> {
      return localVarFp.tenantsGet(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsGetCurrentConfigs(options?: any): AxiosPromise<TenantConfigDto> {
      return localVarFp.tenantsGetCurrentConfigs(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsGetTimezonesAndCulture(options?: any): AxiosPromise<TimezonesAndCulturesDto> {
      return localVarFp
        .tenantsGetTimezonesAndCulture(options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsIsUnique(name?: string, id?: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.tenantsIsUnique(name, id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {TenantsPostRequest} [tenantsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsPost(tenantsPostRequest?: TenantsPostRequest, options?: any): AxiosPromise<TenantDto> {
      return localVarFp
        .tenantsPost(tenantsPostRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {TenantsPutRequest} [tenantsPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantsPut(tenantsPutRequest?: TenantsPutRequest, options?: any): AxiosPromise<TenantDto> {
      return localVarFp
        .tenantsPut(tenantsPutRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsCreateDefaultDamageLocationsForAllExistingTenants(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsCreateDefaultDamageLocationsForAllExistingTenants(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsCreateDefaultDamageTypesForAllExistingTenants(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsCreateDefaultDamageTypesForAllExistingTenants(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsDelete(id: number, options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsDelete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsGet(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsGet(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsGetCurrentConfigs(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsGetCurrentConfigs(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsGetTimezonesAndCulture(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsGetTimezonesAndCulture(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [name]
   * @param {number} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsIsUnique(name?: string, id?: number, options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsIsUnique(name, id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {TenantsPostRequest} [tenantsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsPost(tenantsPostRequest?: TenantsPostRequest, options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsPost(tenantsPostRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {TenantsPutRequest} [tenantsPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public tenantsPut(tenantsPutRequest?: TenantsPutRequest, options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .tenantsPut(tenantsPutRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersDelete', 'id', id)
      const localVarPath = `/admin/api/Users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetUserRoles: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersGetUserRoles', 'id', id)
      const localVarPath = `/admin/api/Users/{id}/Roles`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UsersPostRequest} [usersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPost: async (
      usersPostRequest?: UsersPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/Users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        usersPostRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UsersPutRequest} [usersPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPut: async (
      id: string,
      usersPutRequest?: UsersPutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPut', 'id', id)
      const localVarPath = `/admin/api/Users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        usersPutRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UsersUpdateUserRolesRequest} [usersUpdateUserRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdateUserRoles: async (
      id: string,
      usersUpdateUserRolesRequest?: UsersUpdateUserRolesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUpdateUserRoles', 'id', id)
      const localVarPath = `/admin/api/Users/{id}/Roles`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        usersUpdateUserRolesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDelete(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDelete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGetUserRoles(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUserRoles(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UsersPostRequest} [usersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPost(
      usersPostRequest?: UsersPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(usersPostRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id
     * @param {UsersPutRequest} [usersPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPut(
      id: string,
      usersPutRequest?: UsersPutRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersPut(
        id,
        usersPutRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id
     * @param {UsersUpdateUserRolesRequest} [usersUpdateUserRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUpdateUserRoles(
      id: string,
      usersUpdateUserRolesRequest?: UsersUpdateUserRolesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserRoles(
        id,
        usersUpdateUserRolesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration)
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDelete(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.usersDelete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet(options?: any): AxiosPromise<Array<UserDto>> {
      return localVarFp.usersGet(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGetUserRoles(id: string, options?: any): AxiosPromise<Array<RoleDto>> {
      return localVarFp.usersGetUserRoles(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UsersPostRequest} [usersPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPost(usersPostRequest?: UsersPostRequest, options?: any): AxiosPromise<UserDto> {
      return localVarFp
        .usersPost(usersPostRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UsersPutRequest} [usersPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPut(id: string, usersPutRequest?: UsersPutRequest, options?: any): AxiosPromise<UserDto> {
      return localVarFp
        .usersPut(id, usersPutRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UsersUpdateUserRolesRequest} [usersUpdateUserRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUpdateUserRoles(
      id: string,
      usersUpdateUserRolesRequest?: UsersUpdateUserRolesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usersUpdateUserRoles(id, usersUpdateUserRolesRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDelete(id: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersDelete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGet(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersGet(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGetUserRoles(id: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersGetUserRoles(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UsersPostRequest} [usersPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPost(usersPostRequest?: UsersPostRequest, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersPost(usersPostRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UsersPutRequest} [usersPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPut(id: string, usersPutRequest?: UsersPutRequest, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersPut(id, usersPutRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UsersUpdateUserRolesRequest} [usersUpdateUserRolesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUpdateUserRoles(
    id: string,
    usersUpdateUserRolesRequest?: UsersUpdateUserRolesRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUpdateUserRoles(id, usersUpdateUserRolesRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * WebAppConfigurationApi - axios parameter creator
 * @export
 */
export const WebAppConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webAppConfigurationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/api/WebAppConfiguration`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebAppConfigurationApi - functional programming interface
 * @export
 */
export const WebAppConfigurationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebAppConfigurationApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webAppConfigurationGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebAppConfiguration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webAppConfigurationGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WebAppConfigurationApi - factory interface
 * @export
 */
export const WebAppConfigurationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WebAppConfigurationApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webAppConfigurationGet(options?: any): AxiosPromise<WebAppConfiguration> {
      return localVarFp.webAppConfigurationGet(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * WebAppConfigurationApi - object-oriented interface
 * @export
 * @class WebAppConfigurationApi
 * @extends {BaseAPI}
 */
export class WebAppConfigurationApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAppConfigurationApi
   */
  public webAppConfigurationGet(options?: AxiosRequestConfig) {
    return WebAppConfigurationApiFp(this.configuration)
      .webAppConfigurationGet(options)
      .then(request => request(this.axios, this.basePath))
  }
}
