import { Badge, debounce, Grid, TextField, Typography } from '@mui/material'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { FilterIcon, IconButton } from '@tom-ui/ui'
import { DatePicker, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { FilterChip } from './FilterChip'

interface Props {
  store: IPaginatedStoreWithItems<any>
  actions?: ReactNode
  title?: string
  searchPlaceholder?: string
  dateRange?: boolean
  onShowFilter?: () => void
}

export const GridToolbar = observer(
  ({ store, actions, title, searchPlaceholder, dateRange, onShowFilter }: Props) => {
    const { t } = useTranslate()
    const { RangePicker } = DatePicker
    type RangeValue = [from: Dayjs | null, to: Dayjs | null] | null
    const value: RangeValue = [dayjs(store.from), dayjs(store.to)]

    const OnSearchFilterChange = debounce(async (filter: string) => {
      await store.setFilter(filter)
    }, 500)

    const OnClickFilterButton = () => {
      if (onShowFilter) onShowFilter()
    }

    return (
      <Grid container sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
        {title && (
          <Grid item xs={3} display='flex' alignItems='center'>
            <Typography variant='h3'>{title}</Typography>
          </Grid>
        )}
        <Grid item xs display='content'>
          <Grid container spacing={1}>
            {dateRange && (
              <Grid item xs display='flex' alignItems='center'>
                <Space direction='vertical' size={12}>
                  <RangePicker
                    style={{ height: '3.5rem' }}
                    value={value}
                    onChange={(val: any) => {
                      store.setDateRange(val[0].toDate(), val[1].toDate())
                    }}
                    changeOnBlur
                    clearIcon={false}
                  />
                </Space>
              </Grid>
            )}
            <Grid item xs display='flex' alignItems='center'>
              <TextField
                label={searchPlaceholder ? searchPlaceholder : t('search', 'Search')}
                defaultValue={store.filter}
                sx={{ width: '100%' }}
                onChange={event => OnSearchFilterChange(event.currentTarget.value)}
              />
            </Grid>
            {!!onShowFilter && (
              <Grid item xs={0.5} display='flex' sx={{ justifyContent: 'center', m: 1 }}>
                <IconButton size='large' color='inherit' onClick={() => OnClickFilterButton()}>
                  <Badge variant='dot' color='primary' invisible={!store.filterStatus}>
                    <FilterIcon />
                  </Badge>
                </IconButton>
              </Grid>
            )}
            {!!actions && (
              <Grid
                item
                xs={12}
                md='auto'
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
              >
                {actions}
              </Grid>
            )}
          </Grid>
        </Grid>
        {!!store.filterStatus && (
          <Grid container>
            <Grid item xs display='flex' alignItems='center'>
              <Typography variant='subtitle1'>Filter By: </Typography>
              <FilterChip
                label={store.filterStatus}
                handleFilterChipDelete={() => store.setFilterStatus()}
                sx={{ m: 1 }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  },
)
