import {
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  CreateVisitAllocationRuleDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { CarrierVisitsService } from '@storage/services/carrier-visits.service'
import { runInAction } from 'mobx'

export class CarrierVisitAllocationRulesV2Store extends EntityStore<CarrierVisitAllocationRuleDto> {
  constructor(private readonly _carrierVisitsService: CarrierVisitsService) {
    super()
  }

  async loadAll(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    const carrierVisitAllocationRules = await this._carrierVisitsService.getAllocationRules(
      carrierVisitId,
      carrierVisitDirection,
    )
    this.setAll(carrierVisitAllocationRules)
  }

  async saveChanges(
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
    carrierVisitAllocationRules: CreateVisitAllocationRuleDto[],
  ) {
    await this._carrierVisitsService.createCarrierVisitAllocationAndRules({
      carrierVisitId,
      carrierVisitDirection,
      carrierVisitAllocationRules,
    })
  }

  async delete(id: string) {
    this.remove(id)
  }

  async add(carrierVisitAllocationRule: CarrierVisitAllocationRuleDto) {
    this.addOrUpdate(carrierVisitAllocationRule)
    return carrierVisitAllocationRule
  }

  async update_Deprecated(
    req: CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  ): Promise<CarrierVisitAllocationRuleDto> {
    const allocationRule = await this._carrierVisitsService.updateCarrierVisitAllocationRules(req)
    this.addOrUpdate(allocationRule)
    return allocationRule
  }

  async delete_Deprecated(id: string) {
    await this._carrierVisitsService.deleteAllocationRules(id)
    this.remove(id)
  }

  async reorderAllocationRules(startIndex: number, endIndex: number) {
    runInAction(() => {
      const currentEntries = this.entries
      const [removed] = currentEntries.splice(startIndex, 1)
      currentEntries.splice(endIndex, 0, removed)

      this.setAll(currentEntries)
    })
  }

  resetData() {
    this.removeAll()
  }
}
