import { Box, Card, Divider, Typography } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import CarrierVisitAllocationRulesContainerHeader from './components/CarrierVisitAllocationRulesContainerHeader'
import DraggableAllocationRuleListItem from './components/DraggableAllocationRuleListItem'

interface CarrierVisitAllocationRulesContainerV2Props {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
}

const CarrierVisitAllocationRulesContainerV2 = observer(
  ({ carrierVisitId, carrierVisitDirection }: CarrierVisitAllocationRulesContainerV2Props) => {
    const { t } = useTranslate()
    const {
      carrierVisitAllocationRulesV2UIStore,
      carrierVisitUnAllocatedTurnoversV2UIStore,
      carrierVisitAllocationRulesV2Store,
    } = useStores()
    const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.containerDialogUtilStore
    const { breakpoints } = useMinimalsTheme()

    //TODO: troubleshoot mobx reactivity with dnd lib
    useEffect(() => undefined, [carrierVisitAllocationRulesV2UIStore.allocationRules])

    useEffect(() => {
      let turnovers = carrierVisitUnAllocatedTurnoversV2UIStore.containerTurnovers

      carrierVisitAllocationRulesV2Store.entries.forEach(rule => {
        turnovers = carrierVisitAllocationRulesV2UIStore.filterTurnoversByAllocationRule(
          rule,
          turnovers,
        )
      })
    }, [
      carrierVisitUnAllocatedTurnoversV2UIStore.containerTurnovers,
      carrierVisitAllocationRulesV2Store.entries,
      carrierVisitAllocationRulesV2UIStore,
    ])

    useEffect(() => {
      carrierVisitAllocationRulesV2UIStore.loadAllocationRules(
        carrierVisitId,
        carrierVisitDirection,
      )

      return () => {
        carrierVisitAllocationRulesV2UIStore.setSelectedAllocationRule()
        carrierVisitAllocationRulesV2UIStore.clearAlerts()
      }
    }, [carrierVisitAllocationRulesV2UIStore, carrierVisitDirection, carrierVisitId])

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) return

      carrierVisitAllocationRulesV2UIStore.reorderAllocationRules(
        result.source.index,
        result.destination.index,
      )
    }

    return (
      <Card
        sx={{
          [breakpoints.up('sm')]: {
            height: '42vh !important',
          },
        }}
      >
        <CarrierVisitAllocationRulesContainerHeader
          onClick={() => dialogUtilStore.toggleDialog('Add')}
        />
        <Divider />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='allocationRulesList'>
            {provided => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: '90%',
                }}
              >
                {carrierVisitAllocationRulesV2UIStore.allocationRules.length ? (
                  carrierVisitAllocationRulesV2UIStore.allocationRules.map(
                    (allocationRule, index, list) => (
                      <DraggableAllocationRuleListItem
                        key={allocationRule.id}
                        allocationRule={allocationRule}
                        index={index}
                        isLast={index === list.length - 1}
                      />
                    ),
                  )
                ) : (
                  <Typography m={2} variant='body1' textAlign='center'>
                    {t('noAllocationRulesRunning', 'No allocation rules running')}
                  </Typography>
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    )
  },
)

export default CarrierVisitAllocationRulesContainerV2
