import { CarrierVisitDirection, CustomerResponseDto, OrderListOrderDto } from '@planning/app/api'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { RailTrackItemStore } from '@planning/rt-stores/railTrack/RailTrackItemStore'
import { RailVisitItemStore } from '@planning/rt-stores/railVisit/RailVisitItemStore'
import { VesselVisitItemStore } from '@planning/rt-stores/vesselVisit/VesselVisitItemStore'
import { Dictionary } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { OrderListUploadComparisonStore } from './OrderListUploadComparisonStore'
import { OrderListUploadDataStore } from './OrderListUploadDataStore'
import { OrderListUploadGroupStore } from './OrderListUploadGroupStore'
import { OrderListUploadParsingStore } from './OrderListUploadParsingStore'

export interface IOrderDto extends OrderListOrderDto {
  railTrack?: string
}

export interface IOrderList {
  carrierVisitId: number
  direction: CarrierVisitDirection
  orders?: Array<IOrderDto> | null
}

export class OrderListUploadViewStoreV2 {
  static readonly defaultHomePort = 'DEHAM'
  static readonly defaultListName = 'Default'

  open = false
  activeStep = 0

  vesselVisitId?: number
  railVisitId?: number
  direction: CarrierVisitDirection = CarrierVisitDirection.Inbound
  customer?: CustomerResponseDto

  dataStore: OrderListUploadDataStore
  comparisonStore: OrderListUploadComparisonStore
  parsingStore: OrderListUploadParsingStore
  orderGroupStore: OrderListUploadGroupStore

  acceptUpdates: Dictionary<boolean> = {}

  constructor(
    orderStore: OrderItemStore,
    private vesselVisitStore: VesselVisitItemStore,
    private railVisitStore: RailVisitItemStore,
    private railTrackStore: RailTrackItemStore,
  ) {
    makeObservable(this, {
      open: observable,
      activeStep: observable,
      vesselVisitId: observable,
      railVisitId: observable,
      direction: observable,
      customer: observable,
      acceptUpdates: observable,

      vesselVisit: computed,
      railVisit: computed,
      orderUpdates: computed,
      totalCount: computed,
      issues: computed,
      listName: computed,

      setVesselVisitId: action,
      setDirection: action,
      setCustomer: action,
      setOrdersFromUpdate: action,
      setFile: action,
      setActiveStep: action,
      setOpen: action,
      setAcceptUpdate: action,
    })

    this.dataStore = new OrderListUploadDataStore(
      this,
      orderStore,
      vesselVisitStore,
      railVisitStore,
    )
    this.comparisonStore = new OrderListUploadComparisonStore(this, this.railTrackStore)
    this.parsingStore = new OrderListUploadParsingStore(this, this.railTrackStore)
    this.orderGroupStore = new OrderListUploadGroupStore(this)
  }

  get listName() {
    return this.orderGroupStore.selectedGroup
  }

  get vesselVisit() {
    return this.vesselVisitStore.elements[this.vesselVisitId ?? -1]?.data
  }

  get railVisit() {
    return this.railVisitStore.elements[this.railVisitId ?? -1]?.data
  }

  get orderUpdates() {
    const { orderUpdates: update } = this.comparisonStore

    if (update) {
      runInAction(() => {
        this.acceptUpdates = [...update.created, ...update.amended, ...update.deleted].reduce(
          (acc, cur) => {
            acc[cur.containerNumber ?? ''] = true
            return acc
          },
          {} as Dictionary<boolean>,
        )
      })
    }

    return update
  }

  get totalCount() {
    if (!this.orderUpdates) return 0

    return (
      this.orderUpdates.created.length +
      this.orderUpdates.deleted.length +
      this.orderUpdates.amended.length +
      this.orderUpdates.unchanged.length
    )
  }

  get issues() {
    return this.comparisonStore.issues
  }

  setOpen = async (open?: boolean) => {
    this.open = !!open
  }

  setVesselVisitId = async (id?: number) => {
    this.vesselVisitId = id

    if (id) await this.vesselVisitStore.fetchByIds([id])
  }

  setRailVisitId = async (id?: number) => {
    this.railVisitId = id

    if (id) await this.railVisitStore.fetchByIds([id])
  }

  setDirection = async (direction: CarrierVisitDirection) => {
    this.direction = direction
  }

  setOrdersFromUpdate = (orders: OrderListOrderDto[]) => {
    this.dataStore.setOrdersFromUpdate(orders)
  }

  setCustomer = (customer?: CustomerResponseDto) => {
    this.customer = customer
  }

  setUNLOCodes = (unloCodes: string[]) => {
    this.parsingStore.setUNLOCodes(unloCodes)
  }

  setFile = (file?: File) => {
    this.parsingStore.setFile(file)
  }

  setUploadFailedReason = (reason?: string) => {
    this.parsingStore.setUploadFailedReason(reason)
  }

  setActiveStep = (step: number) => {
    this.activeStep = step
  }

  parseOrderList = async () => {
    await this.parsingStore.parseOrderList()
  }

  setAcceptUpdate = (containerNumber: string, accept: boolean) => {
    this.acceptUpdates[containerNumber] = accept
  }

  reset = () => {
    this.setActiveStep(0)
    this.setVesselVisitId()
    this.setDirection(CarrierVisitDirection.Inbound)
    this.setCustomer()

    this.parsingStore.reset()
    this.dataStore.reset()
    this.orderGroupStore.reset()

    this.setOpen(false)
  }
}
