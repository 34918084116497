import { Menu, MenuItem, MenuProps, Typography } from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import { ChevronRightIcon } from '../../icons'
import { Group, GroupingState, GroupMap, Id, Item } from './types'

export interface GroupingContextAction {
  children?: (group: Group, items: Item[], selectedItemIds: Id[]) => GroupingContextAction[]
  label: (selectedItemIds: Id[]) => string
  isActionDisabled?: (selectedItemIds: Id[]) => boolean
  isActionHidden?: (selectedItems: Item[]) => boolean
  onClick?: (selectedItemIds: Id[]) => void
}

interface IProps {
  state: GroupingState
  groups: GroupMap
  group: Group
  selectedItemIds: Id[]
  menuProps: MenuProps
  actions?: GroupingContextAction[]
}

const getMenu = (
  action: GroupingContextAction,
  group: Group,
  items: Item[],
  selectedItemIds: Id[],
  divider: boolean,
) => {
  if (!!action.children && action.children(group, items, selectedItemIds).length > 0) {
    return getMultipleMenu(action, group, items, selectedItemIds, divider)
  } else {
    return getSingleMenu(action, selectedItemIds, divider, items)
  }
}

const getSingleMenu = (
  action: GroupingContextAction,
  selectedItemIds: Id[],
  divider: boolean,
  items: Item[],
) => {
  if (
    action.isActionHidden &&
    action.isActionHidden(items.filter(item => selectedItemIds.includes(item.id)))
  ) {
    return undefined
  }

  return (
    <MenuItem
      divider={divider}
      key={action.label(selectedItemIds)}
      disabled={action.isActionDisabled?.(selectedItemIds)}
      onClick={() =>
        !action.isActionDisabled?.(selectedItemIds) && action.onClick?.(selectedItemIds)
      }
    >
      <Typography sx={{ marginLeft: '-4px' }}>{action.label(selectedItemIds)}</Typography>
    </MenuItem>
  )
}

const getMultipleMenu = (
  action: GroupingContextAction,
  group: Group,
  items: Item[],
  selectedItemIds: Id[],
  divider: boolean,
) => {
  if (!action.children) return <></>

  const children = action.children(group, items, selectedItemIds)
  if (!children?.length) return <></>

  return (
    <NestedMenuItem
      label={action.label(selectedItemIds)}
      parentMenuOpen={true}
      divider={divider}
      rightIcon={<ChevronRightIcon />}
      leftIcon={null}
      MenuProps={{
        disableScrollLock: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {children.map((childAction, index) => {
        const actionChildren = action.children ? action.children(group, items, selectedItemIds) : []
        const childDivider = index < actionChildren.length - 1
        return getMenu(childAction, group, items, selectedItemIds, childDivider)
      })}
    </NestedMenuItem>
  )
}

export const ContextMenu = ({ state, group, selectedItemIds, menuProps, actions }: IProps) => {
  const items = Object.entries(state.entities.items)
    .filter(([id]) => selectedItemIds.includes(id))
    .map(([id, item]) => item)

  return (
    <Menu {...menuProps} onContextMenu={e => e.preventDefault()}>
      {actions?.map((action, index) => {
        const divider = index < actions.length - 1
        return getMenu(action, group, items, selectedItemIds, divider)
      })}
    </Menu>
  )
}
