import { Box, Radio, Stack, Typography } from '@mui/material'
import { OrderIssueResolutionType } from '@planning/app/api'
import { useMinimalsTheme } from '@tom-ui/ui'
import React from 'react'

interface IProps {
  id: OrderIssueResolutionType
  selectedId: OrderIssueResolutionType
  label: string
  children?: React.ReactNode
  onSelect: (id: OrderIssueResolutionType) => void
}

const FieldBoxWithRadio: React.FC<IProps> = ({ id, selectedId, label, children, onSelect }) => {
  const theme = useMinimalsTheme()
  const isSelected = selectedId === id

  const handleSelect = () => {
    onSelect(id)
  }

  return (
    <Stack
      mb={2}
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: '1rem',
        borderRadius: theme.customRadius.medium,
        cursor: 'pointer',
      }}
      onClick={handleSelect}
    >
      <Stack direction='row' alignItems='center'>
        <Radio
          checked={isSelected}
          onChange={handleSelect}
          value={id}
          onClick={e => e.stopPropagation()}
        />
        <Typography variant='body1'>{label}</Typography>
      </Stack>

      {isSelected && children && <Box mt={2}>{children}</Box>}
    </Stack>
  )
}

export default FieldBoxWithRadio
