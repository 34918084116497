import { Room } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { CarrierVisitAllocationRuleDto } from '@storage/app/api'
import { LabelBox } from '@storage/components/LabelBox'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { mapYardPositionDtoToYardPositionDescriptor } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import AllocationRuleSummary from './AllocationRuleSummaryContainer'
import AllocationRuleTemplateFacetList from './AllocationRuleTemplateFacetList'
import IconActionMenu from './IconActionMenu'
import ListItemSelectionIndicator from './ListItemSelectionIndicator'

interface CarrierVisitAllocationRulesListItemProps {
  allocationRule: CarrierVisitAllocationRuleDto
  dragHandleProps?: any
}

const CarrierVisitAllocationRulesListItem = observer(
  ({ allocationRule, dragHandleProps }: CarrierVisitAllocationRulesListItemProps) => {
    const { palette } = useMinimalsTheme()

    const { carrierVisitAllocationRulesV2UIStore } = useStores()

    const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.listItemDialogUtilStore

    const destination = useMemo(
      () => mapYardPositionDtoToYardPositionDescriptor(allocationRule.destination),
      [allocationRule.destination],
    )

    const isSelected =
      allocationRule.id === carrierVisitAllocationRulesV2UIStore.selectedAllocationRule?.id

    const handleOnClick = () =>
      carrierVisitAllocationRulesV2UIStore.toggleAllocationRule(allocationRule)

    const handleOnEdit = () => {
      carrierVisitAllocationRulesV2UIStore.setOpenDialogForOnlyContainerNumber(
        !!allocationRule.facets.containerNumber,
      )
      dialogUtilStore.toggleDialog('Edit', allocationRule.id)
    }

    const handleOnDelete = () => dialogUtilStore.toggleDialog('Delete', allocationRule.id)

    const allocationSummary = carrierVisitAllocationRulesV2UIStore.getAllocationSummary(
      allocationRule.id,
    )

    return (
      <Stack direction='row' data-cy='allocation-rule'>
        <ListItemSelectionIndicator selected={isSelected} dragHandleProps={dragHandleProps} />

        <Stack
          flex={1}
          gap={1}
          px={2}
          pt={0.5}
          pb={1}
          onClick={handleOnClick}
          sx={{ userSelect: 'none', width: '70%' }}
        >
          <Typography noWrap variant='subtitle1'>
            {allocationRule.name}
          </Typography>

          <AllocationRuleTemplateFacetList facets={allocationRule.facets} />

          <Stack gap={1} direction='row' alignItems='center'>
            <Room fontSize='small' sx={{ color: palette.info.main }} />
            <LabelBox
              label={destination}
              color={palette.info.main}
              bgColor={`${palette.info.main}14`}
            />
          </Stack>

          <AllocationRuleSummary nbrOfContainers={allocationSummary?.length} />
        </Stack>

        <Box pt={1} pr={1}>
          <IconActionMenu onEdit={handleOnEdit} onDelete={handleOnDelete} />
        </Box>
      </Stack>
    )
  },
)

export default CarrierVisitAllocationRulesListItem
