/**
 * This file serves as the central point for managing and exporting
 * singleton instances of all services
 */

import {
  AllocationRulesTemplateApi,
  CarrierVisitsApi,
  ContainerTurnoversApi,
  CustomersApi,
  DangerousGoodsStackingRulesApi,
  DGSegregationRuleApi,
  GeneralCargoAreasApi,
  GeneralCargoOrdersApi,
  GeneralCargoStockOccupanciesApi,
  GeneralCargoStocksApi,
  RailTracksApi,
  WeightClassesApi,
  YardBlocksApi,
  YardBlockSlotOccupanciesApi,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'
import { AllocationRuleTemplatesService } from './allocation-rule-templates.service'
import { CarrierVisitsService } from './carrier-visits.service'
import { ContainerPlanningService } from './container-planning.service'
import { ContainerRoutingInfoService } from './container-routing-info.service'
import { ContainerShiftingsService } from './container-shiftings.service'
import { ContainerTurnoverService } from './container-turnover.service'
import { CustomerService } from './customer-service'
import { DGSegregationRuleService } from './dg-segregation-rule.service'
import { DGStackingRuleService } from './dg-stacking-rule.service'
import { GeneralCargoAreaService } from './general-cargo-area.service'
import { GeneralCargoStockService } from './general-cargo-stock.service'
import { MoveContainerService } from './move-container.service'
import { RailTracksService } from './rail-tracks.service'
import { TenantConfigService } from './tenantConfig.service'
import { WeightClassService } from './weight-class.service'
import { YardBlockSlotOccupancyService } from './yard-block-slot-occupancy.service'
import { YardBlockService } from './yard-blocks-service'

export const containerPlanningService = new ContainerPlanningService()
export const containerRoutingInfoService = new ContainerRoutingInfoService(
  createApiClient(YardBlocksApi),
)
export const yardBlockService = new YardBlockService()

export const tenantConfigService = new TenantConfigService()

export const containerTurnoverService = new ContainerTurnoverService(
  createApiClient(ContainerTurnoversApi),
)

export const moveContainerService = new MoveContainerService()
export const generalCargoAreaService = new GeneralCargoAreaService(
  createApiClient(GeneralCargoAreasApi),
)

export const generalCargoStockService = new GeneralCargoStockService(
  createApiClient(GeneralCargoStocksApi),
  createApiClient(GeneralCargoStockOccupanciesApi),
  createApiClient(GeneralCargoOrdersApi),
)
export const allocationRuleTemplatesService = new AllocationRuleTemplatesService(
  createApiClient(AllocationRulesTemplateApi),
)

export const containerShiftingsService = new ContainerShiftingsService(
  createApiClient(ContainerTurnoversApi),
)

export const railTracksService = new RailTracksService(createApiClient(RailTracksApi))

export const carrierVisitsService = new CarrierVisitsService(createApiClient(CarrierVisitsApi))

export const weightClassService = new WeightClassService(createApiClient(WeightClassesApi))

export const dgStackingRuleService = new DGStackingRuleService(
  createApiClient(DangerousGoodsStackingRulesApi),
)

export const dgSegregationRuleService = new DGSegregationRuleService(
  createApiClient(DGSegregationRuleApi),
)

export const yardBlockSlotOccupancyService = new YardBlockSlotOccupancyService(
  createApiClient(YardBlockSlotOccupanciesApi),
)

export const customerService = new CustomerService(createApiClient(CustomersApi))
