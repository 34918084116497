import { Stack } from '@mui/system'
import { CarrierVisitDirection, ContainerTurnoverDto, WeightClassDto } from '@storage/app/api'
import CarrierVisitAllocationRulesContainerV2 from '@storage/features/carrier-visit-allocation-rules/CarrierVisitAllocationRulesContainerV2'
import { observer } from 'mobx-react-lite'
import UnAllocatedContainers from './UnAllocatedContainers'

interface YardPlanningDashboardDetailsSidePanelProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
  unallocatedTurnovers: ContainerTurnoverDto[]
  weightClasses: WeightClassDto[]
}

const YardPlanningDashboardDetailsSidePanel = observer(
  ({
    carrierVisitId,
    carrierVisitDirection,
    unallocatedTurnovers,
    weightClasses,
  }: YardPlanningDashboardDetailsSidePanelProps) => {
    return (
      <Stack gap={2}>
        <CarrierVisitAllocationRulesContainerV2
          carrierVisitId={carrierVisitId}
          carrierVisitDirection={carrierVisitDirection}
        />
        <UnAllocatedContainers
          unallocatedTurnovers={unallocatedTurnovers}
          carrierVisitDirection={carrierVisitDirection}
          weightClasses={weightClasses}
        />
      </Stack>
    )
  },
)

export default YardPlanningDashboardDetailsSidePanel
