import { Stack } from '@mui/material'
import { CarrierVisitDirection, ContainerSection } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import YardBlocksSkeleton from './YardBlocksSkeleton'
import YardPlanningDashboardBlock from './YardPlanningDashboardBlock'

interface YardPlanningDashboardBlocksProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
}
const YardPlanningDashboardBlocks = observer(
  ({ carrierVisitId, carrierVisitDirection }: YardPlanningDashboardBlocksProps) => {
    const {
      yardBlockStore,
      yardBlockBayStore,
      yardBlockRowStore,
      yardBlockStackStore,
      carrierVisitAllocationRulesV2UIStore,
    } = useStores()

    const yardBlocksData = useMemo(() => {
      const hasAllData =
        yardBlockStore.entries.length &&
        yardBlockBayStore.entries.length &&
        yardBlockRowStore.entries.length &&
        yardBlockStackStore.entries.length

      if (!hasAllData) {
        return []
      }

      const yardBlocksOrdered = yardBlockStore.entries
        .map(yardBlock => {
          const stacks = yardBlockStackStore.getForBlock(yardBlock.id)
          const availableSpace = stacks
            .filter(stack => stack.section === ContainerSection.Head)
            .reduce((sum, stack) => sum + (stack.numberOfSlots - stack.numberOfOccupiedSlots), 0)

          return {
            yardBlock,
            availableSpace,
            stacks,
            bays: yardBlockBayStore.getForBlock(yardBlock.id),
            rows: yardBlockRowStore.getForBlock(yardBlock.id),
          }
        })
        .sort((a, b) => b.availableSpace - a.availableSpace)

      const allocatedBlocks = carrierVisitAllocationRulesV2UIStore.allocationRules
        .filter(x => x.destination.block)
        .map(x => x.destination.block!.toUpperCase())

      const blocks = [
        ...yardBlocksOrdered.filter(x => allocatedBlocks.includes(x.yardBlock.name.toUpperCase())),
        ...yardBlocksOrdered.filter(x => !allocatedBlocks.includes(x.yardBlock.name.toUpperCase())),
      ]

      return blocks
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      yardBlockStore.entries,
      yardBlockBayStore.entries,
      yardBlockRowStore.entries,
      yardBlockStackStore.entries,
      carrierVisitAllocationRulesV2UIStore.allocationRules,
    ])

    if (!yardBlocksData.length) {
      return <YardBlocksSkeleton />
    }

    return (
      <Stack gap={4} sx={{ height: '100%', overflow: 'auto' }}>
        {yardBlocksData.map(({ yardBlock, availableSpace, bays, rows, stacks }) => (
          <YardPlanningDashboardBlock
            key={`yardBlock_${yardBlock.id}`}
            yardBlock={yardBlock}
            availableSpace={availableSpace}
            bays={bays}
            rows={rows}
            stacks={stacks}
            carrierVisitId={carrierVisitId}
            carrierVisitDirection={carrierVisitDirection}
          />
        ))}
      </Stack>
    )
  },
)

export default YardPlanningDashboardBlocks
