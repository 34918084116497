import { FormType } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import {
  mapFormValuesToAllocationRuleTemplateCreateRequest,
  mapFormValuesToAllocationRuleTemplateUpdateRequest,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import AllocationRuleTemplatesForm from '../forms/allocation-rule-templates-form/AllocationRuleTemplatesForm'

const AllocationRuleTemplatesTableDialog = observer(() => {
  const { t } = useTranslate()
  const {
    allocationRuleTemplatesV2UIStore,
    allocationRuleTemplatesV2Store,
    snackbarStore,
    manualInputValidationStore,
    weightClassContainerUIStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    customerStore,
  } = useStores()

  const dialogUtilStore = allocationRuleTemplatesV2UIStore.dialogUtilStore

  const formId = 'allocation-rules-form'
  const saveLabel = t('save', 'Save')
  const cancelLabel = t('cancel', 'Cancel')
  const deletionConfirmationMessage = t(
    'allocationRuleTemplateWillBeDeleted',
    'This allocation rule template will be deleted and you cannot undo this action!',
  )

  useEffect(() => {
    const loadBlocks = async () => {
      await yardBlockStore.loadList()
    }

    const loadBays = async () => {
      await yardBlockBayStore.loadList()
    }
    const loadRows = async () => {
      await yardBlockRowStore.loadAll()
    }

    loadBlocks()
    loadBays()
    loadRows()
  }, [yardBlockBayStore, yardBlockRowStore, yardBlockStore])

  const getDialogLabels = (action: FormType) => {
    switch (action) {
      case 'Add':
        return {
          title: t('addNewAllocationRuleTemplate', 'Add New Allocation Rule Template'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Edit':
        return {
          title: t('updateAllocationRuleTemplate', 'Edit Allocation Rule Template'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Delete':
        return {
          title: t('deleteAllocationRuleTemplate', 'Delete Allocation Rule Template?'),
          buttons: { primary: t('delete', 'Delete'), secondary: cancelLabel },
        }
    }
  }

  const dialogLabels = getDialogLabels(dialogUtilStore.dialogAction)

  const handleOnClose = () => dialogUtilStore.toggleDialog()

  const handleOnSubmit = (formValues: any) => {
    if (dialogUtilStore.dialogAction === 'Add') {
      allocationRuleTemplatesV2Store
        .add(mapFormValuesToAllocationRuleTemplateCreateRequest(formValues))
        .then(() =>
          snackbarStore.showMessage(
            t(
              'allocationRuleTemplateCreationSuccess',
              'The allocation rule template is successfully created',
            ),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'allocationRuleTemplateCreationFailure',
              'An unexpected error occurred while creating the allocation rule template',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    } else if (dialogUtilStore.dialogAction === 'Edit') {
      allocationRuleTemplatesV2Store
        .update(mapFormValuesToAllocationRuleTemplateUpdateRequest(formValues))
        .then(() =>
          snackbarStore.showMessage(
            t(
              'allocationRuleTemplateUpdateSuccess',
              'The allocation rule template is successfully updated',
            ),
            'success',
          ),
        )
        .catch(() =>
          snackbarStore.showMessage(
            t(
              'allocationRuleTemplateUpdateFailure',
              'An unexpected error occurred while updating the allocation rule template',
            ),
            'error',
          ),
        )
        .finally(() => handleOnClose())
    }
  }

  const handleOnDelete = () => {
    allocationRuleTemplatesV2Store
      .delete(dialogUtilStore.dialogEntityId!)
      .then(() =>
        snackbarStore.showMessage(
          t(
            'allocationRuleTemplateDeletionSuccess',
            'The allocation rule template is successfully deleted',
          ),
          'success',
        ),
      )
      .catch(() =>
        snackbarStore.showMessage(
          t(
            'allocationRuleTemplateDeletionFailure',
            'An unexpected error occurred while deleting the allocation rule template',
          ),
          'error',
        ),
      )
      .finally(() => handleOnClose())
  }

  return (
    <ConfirmationDialog
      open={dialogUtilStore.isDialogOpen}
      title={dialogLabels.title}
      primaryActionText={dialogLabels.buttons.primary}
      closeLabel={dialogLabels.buttons.secondary}
      formId={formId}
      maxWidth='sm'
      onClose={handleOnClose}
      hasBorderTop={dialogUtilStore.dialogAction !== 'Delete'}
      blockClickOutside={dialogUtilStore.dialogAction === 'Delete'}
      message={dialogUtilStore.dialogAction === 'Delete' ? deletionConfirmationMessage : undefined}
      onConfirm={dialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
    >
      {dialogUtilStore.dialogAction !== 'Delete' && (
        <AllocationRuleTemplatesForm
          id={formId}
          manualInputValidationStore={manualInputValidationStore}
          onSubmit={handleOnSubmit}
          isATemplate
          allocationRuleTemplate={
            dialogUtilStore.dialogEntityId
              ? allocationRuleTemplatesV2Store.getById(dialogUtilStore.dialogEntityId)
              : undefined
          }
          weightClassUIStore={weightClassContainerUIStore}
          customerStore={customerStore}
        />
      )}
    </ConfirmationDialog>
  )
})

export default AllocationRuleTemplatesTableDialog
