import { Alert, Stack, Typography } from '@mui/material'
import { AllocationRuleTemplateDto, CarrierVisitAllocationRuleDto } from '@storage/app/api'
import { useCarrierTypeOptions } from '@storage/app/carriers/hooks'
import {
  useCargoStatusOptions,
  useContainerSizeOptions,
  useDangerousOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useContainerHeightOptions } from '@storage/app/container-turnovers/hooks/use-container-height-options.hook'
import { useContainerTypeOptions } from '@storage/app/container-turnovers/hooks/use-container-type-options.hook'
import { FormProps, UnknownNumberValue } from '@storage/app/models'
import { ControlledSelect, ControlledTextField } from '@storage/components/form-controls'
import { ControlledMultipleChipSelect } from '@storage/components/form-controls/ControlledMultipleChipSelect'
import { ControlledRadioGroup } from '@storage/components/form-controls/ControlledRadioGroup'
import { WeightClassContainerUIStore } from '@storage/features/weight-classes/stores/weight-class-container-ui-store'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import {
  Hint,
  ManualInputValidationStore,
} from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { CustomerStore } from '@storage/stores/customer.store'
import { UtilityMappers } from '@storage/utils/mappers'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { mapAllocationRuleTemplateDtoToFormValues } from './allocation-rule-templates-form.mapper'
import {
  AllocationRuleTemplateFormProfile,
  defaultValues,
} from './allocation-rule-templates-form.profile'
import { schema } from './allocation-rule-templates-form.scheme'

interface Props extends FormProps {
  allocationRuleTemplate?: AllocationRuleTemplateDto | CarrierVisitAllocationRuleDto
  manualInputValidationStore: ManualInputValidationStore
  isATemplate?: boolean
  externalDefaultValues?: AllocationRuleTemplateFormProfile
  onSubmit: (formValues: AllocationRuleTemplateFormProfile) => Promise<void> | void
  weightClassUIStore: WeightClassContainerUIStore
  customerStore: CustomerStore
  openWithOnlyContainerNumber?: boolean
}

const AllocationRuleTemplatesForm = ({
  id,
  onSubmit,
  manualInputValidationStore,
  allocationRuleTemplate,
  isATemplate,
  weightClassUIStore,
  externalDefaultValues,
  customerStore,
  openWithOnlyContainerNumber,
}: Props) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  const { handleSubmit, control, formState, reset } =
    useFormWithSchema<AllocationRuleTemplateFormProfile>(
      schema(manualInputValidationStore.isInvalid, isATemplate),
      externalDefaultValues ? externalDefaultValues : defaultValues,
    )

  const destinationInputValue = useWatch({ control, name: 'destination' })

  const [isValidDangerousGoods, setIsValidDangerousGoods] = useState(true)
  const [isDangerousAlertMessage, setIsDangerousAlertMessage] = useState('')

  const dangerousSelected = useWatch({ control, name: 'facets.isDangerous' })

  const containerSizeOptions = useContainerSizeOptions()
  const containerTypeOptions = useContainerTypeOptions()
  const containerHeightOptions = useContainerHeightOptions()
  const cargoStatusOptions = useCargoStatusOptions()
  const reeferOptions = useReeferOptions()
  const dangerousOptions = useDangerousOptions()
  const carrierOptions = useCarrierTypeOptions(true)

  useEffect(() => {
    if (allocationRuleTemplate)
      reset(mapAllocationRuleTemplateDtoToFormValues(allocationRuleTemplate))
  }, [reset, allocationRuleTemplate])

  useEffect(() => {
    if (destinationInputValue) {
      manualInputValidationStore.validate(destinationInputValue.trim().toUpperCase())
      const validateDangerousGoodsSelection = () => {
        const { isValidDangerousGoods, isDangerousAlertMessage } =
          manualInputValidationStore.validateDGLocation(
            UtilityMappers.mapOptionalFieldValueToBoolean(dangerousSelected),
            true,
          )
        setIsValidDangerousGoods(isValidDangerousGoods)
        setIsDangerousAlertMessage(isDangerousAlertMessage)
      }
      validateDangerousGoodsSelection()
    }
  }, [dangerousSelected, destinationInputValue, manualInputValidationStore])

  useEffect(() => {
    weightClassUIStore.loadWeightClasses()
  }, [weightClassUIStore])

  const showHint = (hint: Hint) => {
    const label = hint.type.split('.')
    return `${t(label[0], label[1] ?? '')}${hint.text.length > 0 ? ':' : ''} ${hint.text}`
  }

  const locationFieldHelperText =
    !manualInputValidationStore.isAccepted || manualInputValidationStore.optionCount > 1
      ? showHint(manualInputValidationStore.hint)
      : ''
  return (
    <Stack
      gap={1}
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledTextField
        formState={formState}
        control={control}
        name='name'
        label={t('name', 'Name')}
        required
        dataCy='allocation-name-field'
      />

      {openWithOnlyContainerNumber !== undefined && openWithOnlyContainerNumber ? (
        <ControlledTextField
          name='facets.containerNumber'
          formState={formState}
          control={control}
          fullWidth
          label={t('containerNumber', 'Container number')}
        />
      ) : (
        <>
          <Typography variant='subtitle1'>{t('facets', 'Facets')}</Typography>
          <Stack direction='row' gap={1}>
            <ControlledSelect
              formState={formState}
              control={control}
              fullWidth
              options={containerSizeOptions}
              label={t('size', 'Size')}
              name='facets.size'
            />
            <ControlledTextField
              name='facets.containerOperator'
              formState={formState}
              control={control}
              fullWidth
              label={t('containerOperator', 'Container Operator')}
            />
          </Stack>
          <Stack direction='row' gap={1}>
            <ControlledSelect
              allOption
              formState={formState}
              control={control}
              fullWidth
              options={containerTypeOptions}
              label={t('type', 'Type')}
              name='facets.containerType'
            />
            <ControlledSelect
              allOption
              formState={formState}
              control={control}
              fullWidth
              options={containerHeightOptions}
              label={t('height', 'Height')}
              name='facets.containerHeight'
            />
          </Stack>
          <Stack direction='row' gap={1}>
            <ControlledTextField
              name='facets.consignee'
              formState={formState}
              control={control}
              fullWidth
              label={t('consignee', 'Consignee')}
            />
            <ControlledTextField
              name='facets.portOfDischarge'
              formState={formState}
              control={control}
              fullWidth
              label={t('portOfDischarge', 'Port of discharge')}
            />
          </Stack>
          <ControlledSelect
            formState={formState}
            control={control}
            options={[
              ...customerStore.customers.map(x => ({ label: x.name, value: x.id.toString() })),
              { label: t('unknown', 'Unknown'), value: UnknownNumberValue.toString() },
            ]}
            label={t('customer', 'Customer')}
            name='facets.customer'
          />
          {weightClassUIStore.weightClassesOptions.length > 0 && (
            <>
              <Typography variant='captionBold'>{t('weightClasses', 'Weight Classes')}</Typography>
              <Stack direction='row'>
                <ControlledMultipleChipSelect
                  formState={formState}
                  control={control}
                  options={weightClassUIStore.weightClassesOptions}
                  name='facets.weightClasses'
                  hasTooltip
                />
              </Stack>
            </>
          )}
          <Stack direction='row' gap={1} justifyContent='space-between'>
            <ControlledRadioGroup
              formControlSx={{ mt: 1 }}
              allOption
              formState={formState}
              control={control}
              label={t('cargoStatus', 'Cargo Status')}
              name='facets.isEmpty'
              options={cargoStatusOptions}
              optionsOnColumnOrientation
            />
            <ControlledRadioGroup
              formControlSx={{ mt: 1 }}
              formState={formState}
              control={control}
              label={t('reefer', 'Reefer')}
              name='facets.isReefer'
              options={reeferOptions}
              optionsOnColumnOrientation
            />
            <ControlledRadioGroup
              formControlSx={{ mt: 1 }}
              formState={formState}
              control={control}
              label={t('dangerous', 'Dangerous')}
              name='facets.isDangerous'
              options={dangerousOptions}
              optionsOnColumnOrientation
            />

            <ControlledRadioGroup
              formControlSx={{ mt: 1 }}
              formState={formState}
              control={control}
              label={t('outboundCarrier', 'Outbound carrier')}
              name='facets.outboundCarrierType'
              options={carrierOptions}
              allOption
              optionsOnColumnOrientation
            />
          </Stack>
        </>
      )}

      <Typography variant='subtitle1'>
        {isATemplate
          ? t('defaultDestination', 'Default Destination')
          : t('destination', 'Destination')}
      </Typography>

      <ControlledTextField
        formState={formState}
        control={control}
        placeholder='(e.g. A, A.01, A.01.A, A.01-03.A-D)'
        name='destination'
        uppercase
        fontColor={manualInputValidationStore.isInvalid ? palette.error.main : ''}
        helperText={locationFieldHelperText}
        dataCy='allocation-destination-field'
      />
      {!isValidDangerousGoods && <Alert severity='warning'>{isDangerousAlertMessage}</Alert>}
    </Stack>
  )
}

export default AllocationRuleTemplatesForm
