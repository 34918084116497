import { UnLocationCodeDto } from '@admin/app/api'

export interface TenantConfigFormProfile {
  id?: number
  name: string
  timezone?: string
  culture?: string
  address?: string | null
  unloCodes?: UnLocationCodeDto[]
  hasDamageCatalogue?: boolean
}

export const defaultValues: TenantConfigFormProfile = {
  name: '',
  timezone: '',
  culture: '',
  address: '',
  unloCodes: [],
  hasDamageCatalogue: false,
}
