import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection } from '@planning/app/api'
import { PATH_PLANNING } from '@planning/page-url-paths'
import {
  RailcarSequencingStore,
  RailcarTrackPosition,
} from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { useTranslate } from '@tolgee/react'
import { Group, Grouping, Item, Loader } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { GroupingContextAction } from 'modules/ui/src/theme/components/grouping/GroupingContextMenu'
import { MultiDragAwareReorderProps } from 'modules/ui/src/theme/components/grouping/utils/utils'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router'
import { RailcarGroupingItem } from './RailcarGroupingItem'

interface Props {
  store: RailcarSequencingStore
  direction: CarrierVisitDirection
}

export const ObserverGrouping: FC<Props> = observer(({ store, direction }) => {
  const { appViewStore, issuesViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const navigate = useNavigate()

  const entities = direction === 'Inbound' ? store.entities.discharge : store.entities.load

  const filterStatus = store.filterStatus ? [{ key: 'status', value: store.filterStatus }] : []

  const railcarRowHeight = 60
  const ordersRowHeight = 60
  const calcRailcarGroupHeight = (items: Item[]) => items.length * railcarRowHeight
  const calcOrdersGroupHeight = (item: Item) =>
    ordersRowHeight * (item?.content?.orders?.length || 0)
  const calcTotalOrdersGroupHeight = (items: Item[]) =>
    items.reduce((acc, item) => acc + calcOrdersGroupHeight(item), 0)

  const onDelete = async (selectedItemIds: Id[]) => {
    const count = selectedItemIds.length
    const confirmationMessageKey =
      count === 1 ? 'railcarWillBeDeletedContinue' : 'railcarsWillBeDeletedContinue'
    const confirmationMessage =
      count === 1
        ? 'Railcar will be deleted. Continue?'
        : `The selected ${count} railcars will be deleted. Continue?`

    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t(confirmationMessageKey, confirmationMessage),
      t('confirmRailcarDelete', 'Confirm railcar delete'),
    )

    if (!isConfirmed) return

    store.setIsLoading(true)

    const isDeleted = await store.deleteRailcar(selectedItemIds)
    if (isDeleted) {
      appViewStore.setShowAlert('success', t('railcarDeleted', 'Railcar deleted'))
    } else {
      appViewStore.setShowAlert(
        'error',
        t('railcarNotDeleted', 'An error occurred while trying to delete the Railcar'),
      )
    }

    store.setIsLoading(false)
  }

  const onResolve = async (selectedItemIds: Id[]) => {
    issuesViewStore.setFilterByRailCarTrackPositionId(Number(selectedItemIds[0]))
    navigate(`${PATH_PLANNING.issues}`)
  }

  const getActions = (): GroupingContextAction[] => {
    return [
      {
        label: () => t('copy', 'Copy'),
        isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length === 0,
        children: (_, items) => getRailcarOptions(items),
      },
      {
        label: () => t('delete', 'Delete'),
        onClick: onDelete,
      },
      {
        label: () => t('resolve', 'Resolve'),
        isActionHidden: (selectedItems: Item[]) => {
          return !(selectedItems.length === 1 && selectedItems[0].content.hasIssue)
        },
        onClick: onResolve,
      },
    ]
  }

  const getRailcarOptions = (items: Item[]) =>
    items.map(item => ({
      label: () => item.content['railCar'],
      children: () => getOrderOptions(item.content['orders']),
    }))

  const getOrderOptions = (orders: any) =>
    orders.map((order: any) => ({
      label: () => order['containerNumber'],
      children: () => getCopyOptions(order),
    }))

  const getCopyOptions = (order: any) => {
    const props = ['containerNumber', 'referenceNumber']

    return props.map(prop => ({
      label: () => t(prop, prop),
      onClick: () => {
        const data = order[prop]
        if (data) navigator.clipboard.writeText(data)
      },
    }))
  }

  const groupChangeIsValidFn = async (args: MultiDragAwareReorderProps) => {
    let isConfirmed = true
    const willUpdateCheckedInRailcar = store.willUpdateCheckedInRailcar(args)

    if (willUpdateCheckedInRailcar) {
      isConfirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'changeSequenceOfAlreadyCheckedInRailcarContinue',
          'You are about to change the sequence of an already checked-in railcar. Continue?',
        ),
        t('confirm', 'Confirm'),
      )
    }

    return isConfirmed
  }

  const notifyMovedRailcars = (sourceGroup: Group, destinationGroup: Group, railcars: Item[]) => {
    const sourceGroupName = sourceGroup.name
    const destinationGroupName = destinationGroup.name

    if (sourceGroupName == destinationGroupName) return

    const itemName = railcars
      .slice(0, 3)
      .map(r => r.content.railCar)
      .join(', ')

    appViewStore.setShowAlert(
      'success',
      `Railcars ${itemName} moved from ${sourceGroupName} to ${destinationGroupName}`,
    )
  }

  if (store.isLoading) {
    return <Loader show />
  }

  return (
    <Grouping
      ignoreGroupChanges={true}
      entities={entities}
      groupChangeFn={async (
        _,
        sourceGroup,
        destinationGroup,
        railcars,
        destinationItemSequence,
      ) => {
        const groupChanged = await store.onGroupChange(
          direction,
          sourceGroup,
          destinationGroup,
          railcars,
          destinationItemSequence,
        )

        if (groupChanged) {
          notifyMovedRailcars(sourceGroup, destinationGroup, railcars)
        } else {
          appViewStore.setShowAlert(
            'error',
            t(
              'errorWhileTryingToMoveTheRailcars',
              'An error occurred while trying to move the railcars.',
            ),
          )
        }
      }}
      sequenceChangeFn={(_entities, item, _previousSequence, newSequence, destinationGroupId) => {
        const railcarIds = [parseInt(item.id)]
        store.saveChanges(direction, destinationGroupId, railcarIds, newSequence)
      }}
      groupChangeIsValidFn={groupChangeIsValidFn}
      allowGroupAdd={false}
      allowGroupOptions={false}
      allowGroupNotes={false}
      hasDefaultGroup={false}
      actions={getActions()}
      heightFn={items => calcRailcarGroupHeight(items) + calcTotalOrdersGroupHeight(items)}
      itemSizeFn={item => railcarRowHeight + calcOrdersGroupHeight(item)}
      genericFilter={{
        keys: ['railCar', 'referenceNumber', 'consignee', 'containerNumber'],
        value: store.filter ?? '',
      }}
      filters={filterStatus}
      renderData={(item: { id: Id; content: RailcarTrackPosition }, group) => (
        <RailcarGroupingItem store={store} item={item} group={group} direction={direction} />
      )}
    />
  )
})
