import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { CarrierVisitAllocationRuleDto, CarrierVisitDirection } from '@storage/app/api'
import AllocationRuleTemplatesTableDialog from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleTemplatesTableDialog'
import CarrierVisitAllocationRuleListItemDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRuleListItemDialog'
import CarrierVisitAllocationRulesDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRulesDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import CarrierVisitDetails from './components/CarrierVisitDetails'
import YardPlanningDashboardBlocks from './components/YardPlanningDashboardBlocks'
import YardPlanningDashboardDetailsSidePanel from './components/YardPlanningDashboardDetailsSidePanel'
import YardPlanningDashboardLegend from './components/YardPlanningDashboardLegendComponent'

type Params = {
  vesselVisitId: string
  handlingDirection: CarrierVisitDirection
  discriminator: string
}

const YardPlanningDashboardDetailsV2 = observer(() => {
  const { vesselVisitId, handlingDirection } = useParams<Params>()
  const { breakpoints, palette } = useMinimalsTheme()

  const {
    carrierVisitAllocationRulesV2UIStore,
    carrierVisitUnAllocatedTurnoversV2UIStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    yardBlockStackStore,
    carrierVisitStore,
    weightClassContainerUIStore,
    yardBlockSlotStore,
    weightClassStore,
    carrierVisitAllocationRulesV2Store,
    customerStore,
  } = useStores()

  useEffect(() => {
    Promise.all([
      carrierVisitStore.load(Number(vesselVisitId)),
      yardBlockStore.loadList(),
      yardBlockBayStore.loadList(),
      yardBlockRowStore.loadAll(),
      yardBlockStackStore.loadAll({}),
      carrierVisitUnAllocatedTurnoversV2UIStore.loadTurnovers(
        Number(vesselVisitId),
        handlingDirection!,
      ),
      customerStore.loadCustomers(),
      yardBlockSlotStore.loadOccupiedSlots(),
      weightClassStore.loadAll(),
    ])
    return () => {
      carrierVisitStore.resetData()
      carrierVisitAllocationRulesV2Store.resetData()
      yardBlockStore.resetData()
      yardBlockBayStore.resetData()
      yardBlockRowStore.resetData()
      yardBlockStackStore.resetData()
      yardBlockSlotStore.resetData()
      weightClassStore.resetData()
      carrierVisitUnAllocatedTurnoversV2UIStore.resetTurnovers()
    }
  }, [
    carrierVisitAllocationRulesV2Store,
    carrierVisitStore,
    carrierVisitUnAllocatedTurnoversV2UIStore,
    handlingDirection,
    vesselVisitId,
    weightClassStore,
    yardBlockBayStore,
    yardBlockRowStore,
    yardBlockSlotStore,
    yardBlockStackStore,
    yardBlockStore,
    customerStore,
  ])

  const setAllocationRuleState = (allocationRule?: CarrierVisitAllocationRuleDto) =>
    carrierVisitAllocationRulesV2UIStore.setSelectedAllocationRule(allocationRule)

  if (!vesselVisitId || !handlingDirection) return <></>

  return (
    <Box sx={{ height: '100%', backgroundColor: palette.grey[200] }}>
      <CarrierVisitDetails
        carrierVisit={carrierVisitStore.getById(Number(vesselVisitId))!}
        direction={handlingDirection}
      />
      <Grid
        container
        spacing={4}
        sx={{
          padding: 2,
          [breakpoints.up('sm')]: {
            height: 'calc(100% - 40px)',
          },
        }}
      >
        <Grid item xs={12} sm={5} lg={4} xl={3}>
          <YardPlanningDashboardDetailsSidePanel
            carrierVisitId={parseInt(vesselVisitId, 10)}
            carrierVisitDirection={handlingDirection}
            unallocatedTurnovers={carrierVisitUnAllocatedTurnoversV2UIStore.unAllocatedTurnovers}
            weightClasses={weightClassContainerUIStore.weightClasses}
          />
        </Grid>

        <Grid item xs={12} sm={7} lg={8} xl={9} sx={{ height: '100%', position: 'relative' }}>
          <YardPlanningDashboardLegend
            carrierVisitDirection={handlingDirection}
            carrierVisitId={parseInt(vesselVisitId, 10)}
          />
          <YardPlanningDashboardBlocks
            carrierVisitDirection={handlingDirection}
            carrierVisitId={parseInt(vesselVisitId, 10)}
          />
        </Grid>
      </Grid>
      <CarrierVisitAllocationRulesDialog
        carrierVisitDirection={handlingDirection}
        carrierVisitId={parseInt(vesselVisitId, 10)}
        setAllocationRuleState={setAllocationRuleState}
        deprecatedVersion={false}
      />

      <CarrierVisitAllocationRuleListItemDialog
        setAllocationRuleState={setAllocationRuleState}
        carrierVisitDirection={handlingDirection}
        carrierVisitId={parseInt(vesselVisitId, 10)}
        deprecatedVersion={false}
      />

      <AllocationRuleTemplatesTableDialog />
    </Box>
  )
})

export default YardPlanningDashboardDetailsV2
