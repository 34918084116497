import { CarrierType, ContainerHeight, ContainerType } from '@storage/app/api'
import { AllSelectOption, AllStringValue, BooleanSelectOption } from '@storage/app/models'

export interface FacetsFormProfile {
  size: string
  containerOperator: string
  isEmpty: BooleanSelectOption | AllSelectOption
  isReefer: BooleanSelectOption
  isDangerous: BooleanSelectOption
  containerHeight: ContainerHeight | AllSelectOption
  containerType: ContainerType | AllSelectOption
  weightClasses: string[]
  consignee: string
  portOfDischarge: string
  outboundCarrierType: CarrierType | AllSelectOption
  customer: string
  containerNumber: string
}

export interface AllocationRuleTemplateFormProfile {
  id?: string
  name: string
  facets: FacetsFormProfile
  destination?: string
}

export const defaultValues: AllocationRuleTemplateFormProfile = {
  name: '',
  destination: '',
  facets: {
    size: '20',
    containerOperator: '',
    isEmpty: 'All',
    isReefer: BooleanSelectOption.False,
    isDangerous: BooleanSelectOption.False,
    containerHeight: 'All',
    containerType: 'All',
    weightClasses: [],
    consignee: '',
    outboundCarrierType: AllStringValue,
    portOfDischarge: '',
    customer: '',
    containerNumber: '',
  },
}
