import {
  CreateYardBlockDto,
  UpdateYardBlockDto,
  YardBlockDto,
  YardBlockGeometriesApi,
  YardBlocksApi,
  YardElementGeometryDto,
} from '@storage/app/api'
import { Point, SelectOption } from '@storage/app/models'
import { EntityStore } from '@storage/app/store/entity.store'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { FLEXIBLE_ZONE_WIDTH } from '@storage/pages/yard-management/constants'
import { BoundingBox } from '@storage/types'
import { calculateBoundingBox } from '@storage/utils/geometry/geometry.util'
import { computed, makeObservable } from 'mobx'
import { YardBlockBayStore } from './yard-block-bay.store'
import { YardBlockRowStore } from './yard-block-row.store'
import { YardBlockSlotStore } from './yard-block-slot.store'
import { YardBlockStackStore } from './yard-block-stack.store'

export class YardBlockStore extends EntityStore<YardBlockDto> {
  constructor(
    private yardBlockApi: YardBlocksApi,
    private yardBlockGeometryApi: YardBlockGeometriesApi,
    yardManagementHubConnection: YardManagementHubConnection,
    private yardBlockBayStore: YardBlockBayStore,
    private yardBlockRowStore: YardBlockRowStore,
    private yardBlockSlotStore: YardBlockSlotStore,
    yardBlockStackStore: YardBlockStackStore,
  ) {
    super()

    makeObservable(this, {
      entriesAsOptions: computed,
      yardBlocksBoundingBox: computed,
    })

    yardManagementHubConnection.onYardBlockCreated(yardBlock => {
      this.addOrUpdate(yardBlock)
      yardBlockBayStore.loadForYardBlock(yardBlock.id)
      yardBlockRowStore.loadForYardBlock(yardBlock.id)
      yardBlockStackStore.loadForBlock(yardBlock.id)
      yardBlockStackStore.yardBlockSummaryStore.update(yardBlock.id)
    })

    yardManagementHubConnection.onYardBlockUpdated(yardBlockId => {
      this.load(yardBlockId)
      yardBlockBayStore.loadForYardBlock(yardBlockId)
      yardBlockRowStore.loadForYardBlock(yardBlockId)
      yardBlockStackStore.loadForBlock(yardBlockId)
      yardBlockStackStore.yardBlockSummaryStore.delete(yardBlockId)
    })

    yardManagementHubConnection.onYardBlockDeleted(yardBlockId => {
      this.remove(yardBlockId)
      yardBlockBayStore.loadForYardBlock(yardBlockId)
      yardBlockRowStore.loadForYardBlock(yardBlockId)
      yardBlockStackStore.loadForBlock(yardBlockId)
      yardBlockStackStore.yardBlockSummaryStore.delete(yardBlockId)
    })

    yardManagementHubConnection.onReloadYardBlock(yardBlockId => {
      this.load(yardBlockId)
      yardBlockBayStore.loadForYardBlock(yardBlockId)
      yardBlockRowStore.loadForYardBlock(yardBlockId)
      yardBlockStackStore.loadForBlock(yardBlockId)
      yardBlockStackStore.yardBlockSummaryStore.update(yardBlockId)
    })
  }

  public get entriesAsOptions(): SelectOption[] {
    return this.entries.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
  }

  public find(yardBlockId: string): YardBlockDto | undefined {
    return this.entries.find(yardBlock => yardBlock.id === yardBlockId)
  }

  public async load(id: string): Promise<void> {
    const { data: yardBlock } = await this.yardBlockApi.get({ id })

    this.addOrUpdate(yardBlock)
  }

  public async loadList(): Promise<void> {
    const { data: yardBlocks } = await this.yardBlockApi.getAll()

    this.setAll(yardBlocks)
  }

  public async add(dto: CreateYardBlockDto): Promise<YardBlockDto> {
    const { data: yardBlock } = await this.yardBlockApi.create({ yardBlocksCreateRequest: dto })

    this.addOrUpdate(yardBlock)

    return yardBlock
  }

  public async update(dto: UpdateYardBlockDto): Promise<void> {
    const { data: yardBlock } = await this.yardBlockApi.update({ yardBlocksUpdateRequest: dto })

    this.addOrUpdate(yardBlock)
  }

  public async delete(yardBlockId: string): Promise<void> {
    await this.yardBlockApi._delete({ yardBlockId })

    this.remove(yardBlockId)
  }

  public get(id: string) {
    return this.data.get(id)
  }

  public set(blockDto: YardBlockDto) {
    this.addOrUpdate(blockDto)
  }

  get yardBlocksBoundingBox(): BoundingBox {
    return calculateBoundingBox(
      this.entries.filter(e => e.geometry).map(e => ({ x: e.geometry!.left, y: e.geometry!.top })),
    )
  }

  public isFlexibleZone(blockName: string): boolean {
    return this.entries.find(e => e.name === blockName)?.isFlexibleZone ?? false
  }

  public async createYardBlock(block: CreateYardBlockDto, initPosition: Point) {
    const createdBlock = await this.add(block)
    await this.saveYardBlockGeometry(createdBlock.id, {
      top: initPosition.y,
      left: initPosition.x,
      rotation: 0,
      width: createdBlock.isFlexibleZone ? FLEXIBLE_ZONE_WIDTH : undefined,
    })

    await this.load(createdBlock.id)
    await this.yardBlockBayStore.loadForYardBlock(createdBlock.id)
    await this.yardBlockRowStore.loadForYardBlock(createdBlock.id)
    await this.yardBlockSlotStore.loadForYardBlock(createdBlock.id)

    return createdBlock
  }

  public async updateYardBlock(block: UpdateYardBlockDto) {
    this.update(block)
  }

  public async saveYardBlockGeometry(id: string, geometry: YardElementGeometryDto) {
    await this.yardBlockGeometryApi.set({
      yardBlockGeometriesSetRequest: { yardBlockId: id, ...geometry },
    })
  }

  resetData() {
    this.removeAll()
  }
}
