import {
  AllocationsApiDangerousGoodsValidationRequest,
  CarrierType,
  CarrierVisitAllocationRuleDto,
  CarrierVisitAllocationRuleDtoDestination,
  CarrierVisitDirection,
  ContainerTurnoverDto,
  ContainerTurnoversFilterDto,
  CreateVisitAllocationRuleDto,
  ErrorCodes,
} from '@storage/app/api'
import {
  getApplicationDomainExceptionPayload,
  isApplicationDomainException,
} from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { UnknownNumberValue, UnknownStringValue } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { WeightClassContainerUIStore } from '@storage/features/weight-classes/stores/weight-class-container-ui-store'
import { ContainerTurnoversFilterFormProfile } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { mapFormValuesToFilterDto } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/container-turnovers-filter-form.mapper'
import { ManualPlanningDialogStore } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { filterContainerTurnoversByWeightClass } from '@storage/pages/yard-planning-dashboard-details-v2/utils/yard-planning-dashboard-stack.util'
import { ContainerPlanningService } from '@storage/services/container-planning.service'
import { AlertStore } from '@storage/stores/alert.store'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import {
  getInsufficientPlanningSpaceMsg,
  getPlanningHasReservedMsg,
} from '@storage/utils/translation'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import {
  mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto,
  mapSettingsToAllocationRuleTemplateFormProfile as mapBreakdownToAllocationRuleTemplateFormProfile,
  mapFormValuesToCarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  mapYardPositionDtoToYardPositionDescriptor,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { CarrierVisitAllocationRulesV2Store } from './carrier-visit-allocation-rules-v2.store'
import { CarrierVisitUnAllocatedTurnoversV2UIStore } from './carrier-visit-unallocated-turnovers-v2.ui-store'

export class CarrierVisitAllocationRulesV2UIStore {
  selectedAllocationRule?: CarrierVisitAllocationRuleDto
  searchQuery = ''
  isAllocationRequestLoading = false
  isDangerousGoodsDialogOpen = false
  dialogText = ''
  formExternalDefaultValues?: AllocationRuleTemplateFormProfile
  persistAllChangesIsLoading = false
  allocationRuleSummaries: Map<string, ContainerTurnoverDto[]> = new Map()
  openDialogForOnlyContainerNumber = false

  constructor(
    private readonly _carrierVisitAllocationRulesStore: CarrierVisitAllocationRulesV2Store,
    private readonly _carrierVisitAllocationTurnoversStore: CarrierVisitUnAllocatedTurnoversV2UIStore,
    private readonly _alertStore: AlertStore,
    private readonly _snackbarStore: SnackbarStore,
    private readonly _containerPlanningService: ContainerPlanningService,
    private readonly _weightClassContainerUIStore: WeightClassContainerUIStore,
    public readonly containerDialogUtilStore: DialogUtilStore,
    public readonly listItemDialogUtilStore: DialogUtilStore,
  ) {
    makeObservable(this, {
      selectedAllocationRule: observable,
      toggleAllocationRule: action,
      setSelectedAllocationRule: action,

      isAllocationRequestLoading: observable,
      toggleAllocationRequestState: action,

      isDangerousGoodsDialogOpen: observable,
      dialogText: observable,
      toggleDangerousGoodsDialog: action,
      setDialogText: action,

      formExternalDefaultValues: observable,
      setFormExternalDefaultValuesFromSettings: action,

      persistAllChangesIsLoading: observable,
      setPersistAllChangesLoadingState: action,

      allocationRuleSummaries: observable,
      setAllocationRuleSummary: action,

      openDialogForOnlyContainerNumber: observable,
      setOpenDialogForOnlyContainerNumber: action,
    })
  }

  async loadAllocationRules(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    await this._carrierVisitAllocationRulesStore.loadAll(carrierVisitId, carrierVisitDirection)
  }

  get allocationRules(): CarrierVisitAllocationRuleDto[] {
    return this._carrierVisitAllocationRulesStore.entries
  }

  getAllocationSummary(allocationRuleId: string) {
    return this.allocationRuleSummaries.get(allocationRuleId)
  }

  get showAllocationPanel(): boolean {
    return !!this.selectedAllocationRule
  }

  get selectedAllocationRulePosition(): string {
    if (!this.selectedAllocationRule) {
      return ''
    }
    return mapYardPositionDtoToYardPositionDescriptor(this.selectedAllocationRule.destination)
  }

  async reorderAllocationRules(startIndex: number, endIndex: number) {
    this._carrierVisitAllocationRulesStore.reorderAllocationRules(startIndex, endIndex)
    let turnovers = this._carrierVisitAllocationTurnoversStore.containerTurnovers

    this._carrierVisitAllocationRulesStore.entries.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })
  }

  setPersistAllChangesLoadingState(isLoading: boolean) {
    this.persistAllChangesIsLoading = isLoading
  }

  persistAllChanges(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    this.setPersistAllChangesLoadingState(true)

    const createVisitAllocationRuleDtos: CreateVisitAllocationRuleDto[] =
      this._carrierVisitAllocationRulesStore.entries.map((rule, index) => {
        const turnovers = this.allocationRuleSummaries.get(rule.id)
        return this.mapCarrierVisitAllocationRuleDtoToCreateVisitAllocationRuleDto(
          rule,
          index + 1,
          turnovers ?? [],
        )
      })

    return this._carrierVisitAllocationRulesStore.saveChanges(
      carrierVisitId,
      carrierVisitDirection,
      createVisitAllocationRuleDtos,
    )
  }

  toggleAllocationRequestState() {
    this.isAllocationRequestLoading = !this.isAllocationRequestLoading
  }

  toggleDangerousGoodsDialog() {
    this.isDangerousGoodsDialogOpen = !this.isDangerousGoodsDialogOpen
  }

  setDialogText(text: string) {
    this.dialogText = text
  }

  toggleAllocationRule(allocationRule: CarrierVisitAllocationRuleDto) {
    if (this.selectedAllocationRule?.id === allocationRule.id) {
      this.selectedAllocationRule = undefined
    } else {
      this.selectedAllocationRule = allocationRule
    }
  }

  setFormExternalDefaultValuesFromSettings(breakdownItem: UnallocatedTurnoversBreakDown) {
    this.formExternalDefaultValues = mapBreakdownToAllocationRuleTemplateFormProfile(breakdownItem)
  }

  setOpenDialogForOnlyContainerNumber(open: boolean) {
    this.openDialogForOnlyContainerNumber = open
  }

  openAddDialogWithBreakdown(breakdownItem: UnallocatedTurnoversBreakDown) {
    this.setFormExternalDefaultValuesFromSettings(breakdownItem)
    if (breakdownItem.containerNumber) {
      this.setOpenDialogForOnlyContainerNumber(true)
    }
    this.listItemDialogUtilStore.toggleDialog('Add')
  }

  setSelectedAllocationRule(allocationRule?: CarrierVisitAllocationRuleDto) {
    this.selectedAllocationRule = allocationRule
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }

  get alerts() {
    return this._alertStore.getAlerts()
  }

  get panelActionButtonLabel() {
    const hasAllocationSpaceAlert = this._alertStore.doesAlertExist(
      ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
    )
    return hasAllocationSpaceAlert
      ? tolgee.t('allocateAnyway', 'Allocate Anyway')
      : tolgee.t('allocate', 'Allocate')
  }

  public preAllocationValidation(filter: ContainerTurnoversFilterDto) {
    // Clear alerts
    this._alertStore.clearAlerts()

    this._containerPlanningService
      .prePlanningValidation({
        filter,
      })
      .catch((error: AxiosError) => this.handleDomainException(error))
  }

  clearAlerts() {
    this._alertStore.clearAlerts()
  }

  async createAllocation(filter: ContainerTurnoversFilterDto) {
    if (!this.selectedAllocationRule) {
      return
    }

    const forceCreation = this._alertStore.doesAlertExist(
      ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
    )

    await this._containerPlanningService.createAllocation({
      filter,
      yardPosition: this.selectedAllocationRule.destination,
      forceCreation,
    })
  }

  async validateDangerousGoodsPlanning(
    filter: ContainerTurnoversFilterFormProfile,
    numberOfCTsMatchingFilter: number,
    onSuccess: () => void,
  ) {
    try {
      await this.validateAllocationRequest(
        mapFormValuesToFilterDto(filter),
        this.selectedAllocationRule?.destination,
      )
      this.allocationRequest(mapFormValuesToFilterDto(filter), numberOfCTsMatchingFilter, () =>
        onSuccess(),
      )
    } catch (error: any) {
      if (
        isApplicationDomainException(
          error,
          ErrorCodes.PreplanningMixedDangerousareaNonDangerouscontainers,
        )
      ) {
        this.toggleDangerousGoodsDialog()
        this.setDialogText(
          tolgee.t(
            'dangerousLocationNonDangerousSelection',
            'You are trying to allocate non-dangerous goods to a dangerous goods location',
          ),
        )
      }
      if (
        isApplicationDomainException(
          error,
          ErrorCodes.PreplanningMixedNonDangerousareaDangerouscontainers,
        )
      ) {
        this.toggleDangerousGoodsDialog()
        this.setDialogText(
          tolgee.t(
            'nonDangerousLocationDangerousSelection',
            'You are trying to allocate dangerous goods to a non-dangerous goods location',
          ),
        )
      }
    }
  }

  async validateAllocationRequest(
    filter: ContainerTurnoversFilterDto,
    yardPosition?: CarrierVisitAllocationRuleDtoDestination,
  ) {
    if (!yardPosition) {
      return
    }
    const request: AllocationsApiDangerousGoodsValidationRequest = {
      allocationsDangerousGoodsValidationRequest: {
        filter,
        yardPosition,
      },
    }
    await this._containerPlanningService.dangerousGoodsPrePlanningValidation(request)
  }

  async allocationRequest(
    filter: ContainerTurnoversFilterDto,
    numberOfCTsMatchingFilter: number,
    onSuccess: () => void,
  ) {
    this.toggleAllocationRequestState()
    const req = this.createAllocation(filter)
    //TODO: refactor repetitive requests with manualPlanningRequest
    req
      .then(() => {
        this._snackbarStore.showMessage(
          tolgee.t(
            'containersPlanned',
            '{n} containers has been successfully planned to {destination}',
            {
              n: numberOfCTsMatchingFilter,
              destination: this.selectedAllocationRulePosition,
            },
          ),
          'success',
        )
        onSuccess()
      })
      .catch(error => {
        // Check for allocation space conflict (not enough allocation space)
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          const payload = getApplicationDomainExceptionPayload(error)

          this._alertStore.showAlert(
            ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
            getInsufficientPlanningSpaceMsg(payload.locationsAvailable),
          )
        } else {
          // Generic error
          this._snackbarStore.showMessage(
            tolgee.t(
              'cantPlanAtThisPosition',
              `Something went wrong. We can't plan at this position`,
            ),
            'error',
          )
        }
      })
      .finally(() => this.toggleAllocationRequestState())
  }

  async addAllocationRule(
    formValues: AllocationRuleTemplateFormProfile,
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    const carrierVisitAllocationRule =
      mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto(formValues)

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    this.filterTurnoversByAllocationRule(
      carrierVisitAllocationRule,
      this._carrierVisitAllocationTurnoversStore.tempContainerTurnovers,
    )
    onSuccess(carrierVisitAllocationRule)
    onFinally()
  }

  async updateAllocationRule(
    formValues: AllocationRuleTemplateFormProfile,
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    const carrierVisitAllocationRule =
      mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto(formValues)

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    const allocatedTurnovers = this._carrierVisitAllocationTurnoversStore.containerTurnovers.filter(
      x => x.isAllocated,
    )
    this._carrierVisitAllocationTurnoversStore.resetTempTurnovers(allocatedTurnovers)

    let turnovers = this._carrierVisitAllocationTurnoversStore.tempContainerTurnovers
    this._carrierVisitAllocationRulesStore.entries.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    onSuccess(carrierVisitAllocationRule)

    onFinally()
  }

  async deleteAllocationRule(
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    this._carrierVisitAllocationRulesStore.delete(this.listItemDialogUtilStore.dialogEntityId!)
    const allocationRuleTurnovers = this.getAllocationSummary(
      this.listItemDialogUtilStore.dialogEntityId!,
    )

    if (allocationRuleTurnovers) {
      this._carrierVisitAllocationTurnoversStore.resetTempTurnovers(allocationRuleTurnovers)
    }
    let turnovers = this._carrierVisitAllocationTurnoversStore.tempContainerTurnovers

    this._carrierVisitAllocationRulesStore.entries.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    onSuccess()

    onFinally()
  }

  public filterTurnoversByAllocationRule(
    allocationRule: CarrierVisitAllocationRuleDto,
    containerTurnovers: ContainerTurnoverDto[],
  ) {
    let filteredTurnovers: ContainerTurnoverDto[] = []
    if (allocationRule.facets.containerNumber && allocationRule.facets.containerNumber !== '') {
      filteredTurnovers = containerTurnovers.filter(
        x => x.containerNumber === allocationRule.facets.containerNumber,
      )
    } else {
      filteredTurnovers = containerTurnovers.filter(
        turnover =>
          turnover.size === allocationRule.facets.size &&
          turnover.isReefer === allocationRule.facets.isReefer &&
          turnover.isDangerous === allocationRule.facets.isDangerous,
      )

      if (allocationRule.facets.isEmpty !== undefined && allocationRule.facets.isEmpty !== null) {
        filteredTurnovers = filteredTurnovers.filter(
          turnover => turnover.isEmpty === allocationRule.facets.isEmpty,
        )
      }

      if (allocationRule.facets.containerHeight) {
        filteredTurnovers = filteredTurnovers.filter(
          turnover => turnover.containerHeight === allocationRule.facets.containerHeight,
        )
      }
      if (allocationRule.facets.containerType) {
        filteredTurnovers = filteredTurnovers.filter(
          turnover => turnover.containerType === allocationRule.facets.containerType,
        )
      }

      if (allocationRule.facets.containerOperator !== '') {
        filteredTurnovers = filteredTurnovers.filter(
          turnover => turnover.containerOperator === allocationRule.facets.containerOperator,
        )
      }

      if (allocationRule.facets.consignee !== '') {
        filteredTurnovers = filteredTurnovers.filter(
          turnover =>
            turnover.consignee === allocationRule.facets.consignee ||
            (allocationRule.facets.consignee === UnknownStringValue && !turnover.consignee),
        )
      }

      if (allocationRule.facets.customerId) {
        filteredTurnovers = filteredTurnovers.filter(
          turnover =>
            turnover.customerId === allocationRule.facets.customerId ||
            (allocationRule.facets.customerId === UnknownNumberValue && !turnover.customerId),
        )
      }

      if (allocationRule.facets.outboundCarrierType) {
        filteredTurnovers = filteredTurnovers.filter(
          turnover =>
            turnover.outboundCarrierType === allocationRule.facets.outboundCarrierType ||
            (allocationRule.facets.outboundCarrierType === CarrierType.Unknown &&
              !turnover.outboundCarrierType),
        )
      }

      if (allocationRule.facets.portOfDischarge) {
        filteredTurnovers = filteredTurnovers.filter(
          turnover =>
            turnover.portOfDischarge === allocationRule.facets.portOfDischarge ||
            (allocationRule.facets.portOfDischarge === UnknownStringValue &&
              !turnover.portOfDischarge),
        )
      }

      if (allocationRule.facets.weightClasses.length > 0) {
        if (allocationRule.facets.weightClasses.includes(UnknownStringValue)) {
          const orderedWeightClasses = _(this._weightClassContainerUIStore.weightClasses)
            .orderBy(x => x.minWeight)
            .value()
          const minWeight = orderedWeightClasses[0]?.minWeight
          const maxWeight = orderedWeightClasses[orderedWeightClasses.length - 1]?.maxWeight

          filteredTurnovers = filteredTurnovers.filter(
            x =>
              !x.grossWeight ||
              x.grossWeight < minWeight ||
              (!!maxWeight && x.grossWeight > maxWeight),
          )
        } else {
          const weightClasses = this._weightClassContainerUIStore.weightClasses.filter(wc =>
            allocationRule.facets.weightClasses
              .map(s => s.toUpperCase())
              .includes(wc.name.toUpperCase()),
          )

          filteredTurnovers = filterContainerTurnoversByWeightClass(
            filteredTurnovers,
            weightClasses,
          )
        }
      }
    }

    const remainingTurnovers = containerTurnovers.filter(
      turnover => !filteredTurnovers.includes(turnover),
    )
    this.setAllocationRuleSummary(allocationRule.id, filteredTurnovers)
    this._carrierVisitAllocationTurnoversStore.setTempTurnovers(remainingTurnovers)
    return remainingTurnovers
  }

  setAllocationRuleSummary(allocationRuleId: string, turnovers: ContainerTurnoverDto[]) {
    this.allocationRuleSummaries.set(allocationRuleId, turnovers)
  }

  async updateAllocationRule_Deprecated(
    formValues: AllocationRuleTemplateFormProfile,
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    this._carrierVisitAllocationRulesStore
      .update_Deprecated(
        mapFormValuesToCarrierVisitsUpdateCarrierVisitAllocationRulesRequest(formValues),
      )
      .then((carrierVisitAllocationRule: CarrierVisitAllocationRuleDto) => {
        onSuccess(carrierVisitAllocationRule)
        this._snackbarStore.showMessage(
          tolgee.t('allocationRuleUpdateSuccess', 'The allocation rule is successfully updated'),
          'success',
        )
      })
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'allocationRuleUpdateFailure',
            'An unexpected error occurred while updating the allocation rule',
          ),
          'error',
        ),
      )
      .finally(onFinally)
  }

  async deleteAllocationRule_Deprecated(
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    this._carrierVisitAllocationRulesStore
      .delete_Deprecated(this.listItemDialogUtilStore.dialogEntityId!)
      .then(() => {
        onSuccess()
        this._snackbarStore.showMessage(
          tolgee.t('allocationRuleDeletionSuccess', 'The allocation rule is successfully removed'),
          'success',
        )
      })
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'allocationRuleDeletionFailure',
            'An unexpected error occurred while removing the allocation rule',
          ),
          'error',
        ),
      )
      .finally(onFinally)
  }

  private handleDomainException(error: AxiosError) {
    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedContainerTurnoverSizes)) {
      return
    }

    if (isApplicationDomainException(error, ErrorCodes.PreplanningHasReserved)) {
      const payload = getApplicationDomainExceptionPayload(error, ErrorCodes.PreplanningHasReserved)
      this._alertStore.showAlert(
        ErrorCodes.PreplanningHasReserved,
        getPlanningHasReservedMsg(payload.reservedCount),
      )
    }
    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedDangerousAndNonDangerous)) {
      this._alertStore.showAlert(
        ErrorCodes.PreplanningMixedDangerousAndNonDangerous,
        tolgee.t(
          'planningContainerOfDifferentHandling',
          `Pay attention! you're planning dangerous & non dangerous containers`,
        ),
      )
    }

    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedFullAndEmpties)) {
      this._alertStore.showAlert(
        ErrorCodes.PreplanningMixedFullAndEmpties,
        tolgee.t(
          'preplanningMixedFullAndEmpties',
          `Pay attention! you're planning full & empty containers`,
        ),
      )
    }
  }

  private mapCarrierVisitAllocationRuleDtoToCreateVisitAllocationRuleDto(
    { name, facets, destination }: CarrierVisitAllocationRuleDto,
    rulePosition: number,
    containerTurnovers: ContainerTurnoverDto[],
  ): CreateVisitAllocationRuleDto {
    if (facets.containerNumber && facets.containerNumber !== '') {
      return {
        name,
        facets: { containerNumber: facets.containerNumber, weightClasses: [] },
        destination,
        rulePosition,
        containerTurnovers,
      }
    }
    return { name, facets, destination, rulePosition, containerTurnovers }
  }
}
