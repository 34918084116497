import {
  AllocationRulesTemplateCreateRequest,
  AllocationRulesTemplateUpdateRequest,
  AllocationRuleTemplateDto,
  AllocationRuleTemplateDtoFacets,
  CarrierVisitAllocationRuleDto,
  CarrierVisitAllocationRulePropertiesDto,
  CarrierVisitDirection,
  CarrierVisitsCreateCarrierVisitAllocationRuleRequest,
  CarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  YardPositionDto,
} from '@storage/app/api'
import { v4 as uuidv4 } from 'uuid'

import { AllSelectOption, BooleanSelectOption } from '@storage/app/models'
import { AllValue } from '@storage/components/form-controls/constants'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { UtilityMappers } from '@storage/utils/mappers'
import { mapBooleanToBooleanSelectOption } from '@storage/utils/mappers/map-boolean-to-boolean-select-option.mapper'
import { mapBooleanToBooleanOption } from '@storage/utils/mappers/map-boolean-to-boolean.mapper'
import {
  AllocationRuleTemplateFormProfile,
  defaultValues,
  FacetsFormProfile,
} from './allocation-rule-templates-form.profile'

export const mapYardPositionDescriptorToYardPositionDto = (descriptor: string): YardPositionDto => {
  const [block, bay, row, tierStr] = descriptor.split('.')

  let tier: number | null = null
  if (tierStr && !isNaN(parseInt(tierStr, 10))) {
    tier = parseInt(tierStr, 10)
  }

  return {
    block: block || null,
    bay: bay || null,
    row: row || null,
    tier: tier,
  }
}

const mapFacetsFormProfileToAllocationRuleTemplateDtoFacets = (
  facetsFormProfile: FacetsFormProfile,
): AllocationRuleTemplateDtoFacets => ({
  size: UtilityMappers.mapOptionalFieldValueToNumber(facetsFormProfile.size) ?? 20,
  containerOperator: facetsFormProfile.containerOperator.trim(),
  isEmpty: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isEmpty),
  isReefer: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isReefer),
  isDangerous: UtilityMappers.mapOptionalFieldValueToBoolean(facetsFormProfile.isDangerous),
  containerHeight: UtilityMappers.mapOptionalFieldValueToString(facetsFormProfile.containerHeight),
  containerType: UtilityMappers.mapOptionalFieldValueToString(facetsFormProfile.containerType),
  weightClasses: facetsFormProfile.weightClasses,
  consignee: facetsFormProfile.consignee.trim(),
  portOfDischarge: facetsFormProfile.portOfDischarge.trim(),
  outboundCarrierType: UtilityMappers.mapOptionalFieldValueToString(
    facetsFormProfile.outboundCarrierType,
  ),
  customerId: facetsFormProfile.customer !== '' ? parseInt(facetsFormProfile.customer, 10) : null,
  containerNumber: facetsFormProfile.containerNumber,
})

export const mapAllocationRuleTemplateDtoFacetsToFacetsFormProfile = (
  AllocationRuleTemplateDtoFacets: AllocationRuleTemplateDtoFacets,
): FacetsFormProfile => ({
  size: AllocationRuleTemplateDtoFacets.size?.toString() ?? defaultValues.facets.size,
  containerOperator: AllocationRuleTemplateDtoFacets.containerOperator ?? '',
  isEmpty: mapBooleanToBooleanSelectOption(AllocationRuleTemplateDtoFacets.isEmpty),
  isReefer: mapBooleanToBooleanOption(AllocationRuleTemplateDtoFacets.isReefer),
  isDangerous: mapBooleanToBooleanOption(AllocationRuleTemplateDtoFacets.isDangerous),
  containerHeight:
    AllocationRuleTemplateDtoFacets.containerHeight ?? defaultValues.facets.containerHeight,
  containerType:
    AllocationRuleTemplateDtoFacets.containerType ?? defaultValues.facets.containerType,
  weightClasses: AllocationRuleTemplateDtoFacets.weightClasses,
  consignee: AllocationRuleTemplateDtoFacets.consignee ?? '',
  portOfDischarge: AllocationRuleTemplateDtoFacets.portOfDischarge ?? '',
  outboundCarrierType:
    AllocationRuleTemplateDtoFacets.outboundCarrierType ?? defaultValues.facets.outboundCarrierType,
  customer: AllocationRuleTemplateDtoFacets.customerId
    ? AllocationRuleTemplateDtoFacets.customerId.toString()
    : defaultValues.facets.customer,
  containerNumber:
    AllocationRuleTemplateDtoFacets.containerNumber ?? defaultValues.facets.containerNumber,
})

export const mapAllocationRuleTemplateYardPositionToYardPositionDto = (
  block: string,
  bayMin?: string | null,
  bayMax?: string | null,
  rowMin?: string | null,
  rowMax?: string | null,
): YardPositionDto => {
  const bayPart = bayMin && bayMax ? `${bayMin}-${bayMax}` : bayMin
  const rowPart = rowMin && rowMax ? `${rowMin}-${rowMax}` : rowMin
  return {
    block,
    bay: bayPart,
    row: rowPart,
  }
}

export const mapAllocationRuleTemplateYardPositionToYardPositionDescriptor = (
  block: string,
  bayMin?: string | null,
  bayMax?: string | null,
  rowMin?: string | null,
  rowMax?: string | null,
): string => {
  const bayPart = bayMin && bayMax ? `${bayMin}-${bayMax}` : bayMin ?? ''
  const rowPart = rowMin && rowMax ? `${rowMin}-${rowMax}` : rowMin ?? ''

  const parts = [block, bayPart, rowPart].filter(Boolean)

  return parts.join('.')
}

export const mapYardPositionDtoToYardPositionDescriptor = (
  yardPositionDto: YardPositionDto,
): string => {
  const { block, bay, row, tier } = yardPositionDto
  const parts = []

  if (block) parts.push(block)
  if (bay) parts.push(bay)
  if (row) parts.push(row)
  if (tier !== null && tier !== undefined) parts.push(tier.toString())

  return parts.join('.')
}

export const mapAllocationRuleTemplateDtoToFormValues = ({
  id,
  name,
  facets,
  destination,
}:
  | AllocationRuleTemplateDto
  | CarrierVisitAllocationRuleDto): AllocationRuleTemplateFormProfile => ({
  id,
  name,
  facets: mapAllocationRuleTemplateDtoFacetsToFacetsFormProfile(facets),
  destination: destination ? mapYardPositionDtoToYardPositionDescriptor(destination) : '',
})

export const mapFormValuesToAllocationRuleTemplateCreateRequest = ({
  name,
  facets,
  destination,
}: AllocationRuleTemplateFormProfile): AllocationRulesTemplateCreateRequest => ({
  name,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets),
  destination: destination ? mapYardPositionDescriptorToYardPositionDto(destination) : undefined,
})

export const mapFormValuesToAllocationRuleTemplateUpdateRequest = ({
  id,
  name,
  facets,
  destination,
}: AllocationRuleTemplateFormProfile): AllocationRulesTemplateUpdateRequest => ({
  id: id!,
  name,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets),
  destination: destination ? mapYardPositionDescriptorToYardPositionDto(destination) : undefined,
})

export const mapFormValuesToCarrierVisitsUpdateCarrierVisitAllocationRulesRequest = ({
  id,
  name,
  facets,
  destination,
}: AllocationRuleTemplateFormProfile): CarrierVisitsUpdateCarrierVisitAllocationRulesRequest => ({
  carrierVisitAllocationRuleId: id!,
  destination: mapYardPositionDescriptorToYardPositionDto(destination!),
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets),
  name,
})

export const mapFormValuesToCarrierVisitsCreateCarrierVisitAllocationRuleRequest = (
  { name, facets, destination }: AllocationRuleTemplateFormProfile,
  carrierVisitId: number,
  carrierVisitDirection: CarrierVisitDirection,
): CarrierVisitsCreateCarrierVisitAllocationRuleRequest => ({
  carrierVisitId,
  carrierVisitDirection,
  name,
  destination: mapYardPositionDescriptorToYardPositionDto(destination!),
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets),
})

export const mapSettingsToAllocationRuleTemplateFormProfile = (
  breakdownItem: UnallocatedTurnoversBreakDown,
): AllocationRuleTemplateFormProfile => {
  let mappedIsDangerous: BooleanSelectOption | AllSelectOption = AllValue
  let mappedIsReefer: BooleanSelectOption | AllSelectOption = AllValue
  let mappedIsEmpty: BooleanSelectOption | AllSelectOption = AllValue

  if (breakdownItem.isDangerous !== undefined) {
    if (breakdownItem.isDangerous) {
      mappedIsDangerous = BooleanSelectOption.True
    } else {
      mappedIsDangerous = BooleanSelectOption.False
    }
  }

  if (breakdownItem.isReefer !== undefined) {
    if (breakdownItem.isReefer) {
      mappedIsReefer = BooleanSelectOption.True
    } else {
      mappedIsReefer = BooleanSelectOption.False
    }
  }

  if (breakdownItem.isEmpty !== undefined) {
    if (breakdownItem.isEmpty) {
      mappedIsEmpty = BooleanSelectOption.True
    } else {
      mappedIsEmpty = BooleanSelectOption.False
    }
  }

  return {
    name: '',
    facets: {
      ...defaultValues.facets,
      size: breakdownItem.size ? String(breakdownItem.size) : defaultValues.facets.size,
      isDangerous: mappedIsDangerous === AllValue ? BooleanSelectOption.False : mappedIsDangerous,
      isReefer: mappedIsReefer === AllValue ? BooleanSelectOption.False : mappedIsReefer,
      isEmpty: mappedIsEmpty === AllValue ? 'All' : mappedIsEmpty,
      consignee: breakdownItem.consignee ?? '',
      outboundCarrierType: breakdownItem.outboundCarrierType ?? 'All',
      portOfDischarge: breakdownItem.portOfDischarge ?? '',
      weightClasses: breakdownItem.weightClass ? [breakdownItem.weightClass] : [],
      customer: breakdownItem.customer ?? defaultValues.facets.customer,
      containerNumber: breakdownItem.containerNumber ?? defaultValues.facets.containerNumber,
    },
  }
}

export const mapCVAllocationRuleToCarrierVisitAllocationRulePropertiesDto = (
  { id, facets }: CarrierVisitAllocationRuleDto,
  carrierVisitId: number,
  carrierVisitDirection: CarrierVisitDirection,
): CarrierVisitAllocationRulePropertiesDto => ({
  allocationRuleId: id,
  carrierVisitId,
  carrierVisitDirection,
  facets,
})

export const mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto = (
  formProfile: AllocationRuleTemplateFormProfile,
): CarrierVisitAllocationRuleDto => ({
  id: formProfile.id || uuidv4(),
  name: formProfile.name,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(formProfile.facets),
  destination: mapYardPositionDescriptorToYardPositionDto(formProfile.destination!),
})

export const mapAllocationRuleTemplateToCarrierVisitAllocationRuleDto = (
  allocationRuleTemplateDto: AllocationRuleTemplateDto,
  destination: YardPositionDto,
) => ({
  id: uuidv4(),
  name: allocationRuleTemplateDto.name,
  facets: allocationRuleTemplateDto.facets,
  destination,
})
