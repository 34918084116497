import { usePlanningStore } from '@planning/AppProvider'
import { CarrierType, CarrierVisitDirection, UpdateRailVisitCommand } from '@planning/app/api'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { EditVesselVisitEstimateForm } from '@planning/pages/VesselVisit/Components/Forms/EditVesselVisitEstimateForm'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { vesselVisitService } from '@planning/services'
import railVisitService from '@planning/services/railVisitService'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { OrderMovementCard } from '../../../components/OrderMovementCard'
import { OrderListUploadDialogV2 } from './Upload/OrderListUploadDialog'

interface Props {
  visitType: CarrierType
  vesselVisitItem?: IVesselVisitItem
  railVisitItem?: IRailVisitItem
  direction: CarrierVisitDirection
  onViewClick: (e: any) => void
  onClickExportOrders?: () => void
}

export const OrderMovementEditableCard = observer(
  ({
    vesselVisitItem,
    railVisitItem,
    visitType,
    direction,
    onViewClick,
    onClickExportOrders,
  }: Props) => {
    const { appViewStore, nnrOrderItemStore, orderListUploadViewStoreV2 } = usePlanningStore()
    const navigate = useNavigate()
    const { t } = useTranslate()

    const [isEditing, setIsEditing] = useState(false)

    if (!vesselVisitItem && !railVisitItem) return <></>

    const visit = vesselVisitItem ?? railVisitItem

    const onSubmitClicked = async (newEstimate: number) => {
      if (!visit) return

      try {
        const updatedVisit = {
          ...visit.data,
          vesselIds: visit.data.carrierIds,
        }

        if (direction === CarrierVisitDirection.Inbound)
          updatedVisit.dischargeEstimate = newEstimate
        else if (direction === CarrierVisitDirection.Outbound)
          updatedVisit.loadEstimate = newEstimate

        if (visitType === CarrierType.Train) {
          await railVisitService.put(updatedVisit as UpdateRailVisitCommand)
        } else {
          await vesselVisitService.put(updatedVisit)
          await vesselVisitService.updateVesselVisitStatus(updatedVisit.id, updatedVisit.status)
        }

        appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved successfully'))

        setIsEditing(false)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToUpdate', 'Failed to update'))
      }
    }

    const onFileUpload = async (direction: CarrierVisitDirection) => {
      const visitId = visit?.id

      orderListUploadViewStoreV2.reset()

      if (visitType === CarrierType.Train) {
        await orderListUploadViewStoreV2.setRailVisitId(visitId)
      } else {
        await orderListUploadViewStoreV2.setVesselVisitId(visitId)
      }

      orderListUploadViewStoreV2.setDirection(direction)
      orderListUploadViewStoreV2.setOpen(true)
    }

    const nnrOrderSummary = _.get(nnrOrderItemStore.orderSummaryForVesselVisitId, visit!.id)
    const handleAssignNnrOrder = () => {
      if (!!nnrOrderSummary && nnrOrderSummary.nnrOrders.length === 1) {
        navigate(`${PATH_PLANNING.nnrOrders}/${nnrOrderSummary.nnrOrders[0].id}`)
      } else {
        navigate(`${PATH_PLANNING.nnrOrdersPageForVisit}/${visit?.id}`)
      }
    }

    const estimate =
      direction === CarrierVisitDirection.Inbound
        ? visit?.data.dischargeEstimate
        : visit?.data.loadEstimate

    const actual = visit?.getCount('total', direction, visit.data.cargoType)

    const confirmed = visit?.getCount('confirmed', direction, visit.data.cargoType)

    return (
      (!isEditing && (
        <>
          <OrderMovementCard
            visitType={visitType}
            cargoType={visit?.data.cargoType}
            direction={direction}
            estimate={estimate}
            actual={actual}
            confirmed={confirmed}
            onViewClick={onViewClick}
            onClickEdit={() => setIsEditing(true)}
            onFileUpload={onFileUpload}
            onClickExportOrders={onClickExportOrders}
            handleAssignNnrOrder={handleAssignNnrOrder}
            nnrOrderSummary={nnrOrderSummary}
          />

          <OrderListUploadDialogV2
            enableFieldSelectionFromOrdersView={false}
            open={orderListUploadViewStoreV2.open}
            handleDialogClose={() => orderListUploadViewStoreV2.reset()}
            visitType={visitType}
          />
        </>
      )) || (
        <EditVesselVisitEstimateForm
          direction={direction}
          estimate={estimate}
          onSubmit={onSubmitClicked}
          onCancelEdit={() => setIsEditing(false)}
        />
      )
    )
  },
)
