import { Box, Container, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { IssueCard } from './Components/IssueCard'
import { IssuesFilter } from './Components/IssuesFilter'
import { IssuesPageHeader } from './Components/IssuesPageHeader'
import { IssuesTypeFilter } from './Components/IssuesTypeFilter'
import { ResolveIssueDialog } from './Components/ResolveIssueDialog'

export const IssuesPage = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const { issuesViewStore, railcarItemStore } = usePlanningStore()

  useEffect(() => {
    issuesViewStore.setLoadContainerJourneyDataReaction(true)
    issuesViewStore.fetchIssues()
    return () => {
      issuesViewStore.setLoadContainerJourneyDataReaction(false)
      issuesViewStore.setFilterByVisitId()
      issuesViewStore.setFilterByRailCarTrackPositionId()
    }
  }, [issuesViewStore])

  useEffect(() => {
    railcarItemStore.fetch()
  }, [railcarItemStore])

  useEffect(() => {
    //Workaround: since containerJourneyDataStore is not an ItemStore, the items could be overwrite by other pages that calls different fetch. Here, we need to reload the Container Journey we required when entering the page, (there is a logic build in fetchByOrderIds to avoid fetch if other fetch function not get called).
    //TOLATER: we need a containerJourneyItemStore.
    issuesViewStore.containerJourneyDataStore.fetchByOrderIds(issuesViewStore.GetIssueOrderIds())
  }, [issuesViewStore])

  return (
    <InitializationWrapper isInitializing={!issuesViewStore.filteredData}>
      <Box
        sx={{
          height: '100%',
        }}
      >
        <IssuesPageHeader store={issuesViewStore} />
        <IssuesFilter store={issuesViewStore} />
        <IssuesTypeFilter store={issuesViewStore} />

        <Container
          sx={{
            paddingY: theme.customSpacing.m,
          }}
        >
          {issuesViewStore.filteredData.length === 0 ? (
            <Typography variant='h4'>{t('noIssuesFound', 'No issues found')}</Typography>
          ) : (
            issuesViewStore.filteredData.map(i => (
              <IssueCard
                key={i.id}
                issue={i.data}
                containerVisit={i.containerJourney}
                onClickResolve={() => {
                  issuesViewStore.toggleResolveDialog(true)
                  issuesViewStore.setIssueToBeResolved(i)
                }}
              />
            ))
          )}
        </Container>

        <ResolveIssueDialog store={issuesViewStore} />
      </Box>
    </InitializationWrapper>
  )
})
