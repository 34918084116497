import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon, CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { RailcarsViewStore } from '../stores/RailcarsViewStore'

interface Props {
  store: RailcarsViewStore
}

export interface RailcarFormData {
  railcarNumber: string
  railcarLength: number | string
  id?: number
}

export const RailcarsFormDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const title = store.dataToBeEdited
    ? t('editRailcar', 'Edit railcar')
    : t('addNewRailcar', 'Add new railcar')

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<RailcarFormData>({
    defaultValues: {
      railcarNumber: '',
      railcarLength: '',
    },
  })

  useEffect(() => {
    if (store.dataToBeEdited) {
      reset({
        railcarNumber: store.dataToBeEdited?.name,
        railcarLength: store.dataToBeEdited?.length ?? '',
        id: store.dataToBeEdited.id,
      })
    } else {
      reset({
        railcarNumber: store.defaultRailcarNumber ?? '',
        railcarLength: '',
      })
    }
  }, [store.dataToBeEdited, store.defaultRailcarNumber, reset])

  const onSubmit: SubmitHandler<RailcarFormData> = data => {
    store.onSubmit(data)
  }

  const handleDialogClose = () => {
    store.toggleDialog(false)
  }

  const handleDelete = () => {
    if (store.dataToBeEdited) {
      store.onDelete(store.dataToBeEdited)
    }
  }

  /**
   * We use a custom submit handler instead of a default submit button
   * to control form submission explicitly. This change avoids automatic
   * form submission for better reuse of the component.
   */
  const handleFormSubmit = async () => {
    const isValid = await trigger()
    if (isValid) {
      const formData = getValues()
      onSubmit(formData)
    }
  }

  return (
    <Dialog fullWidth maxWidth='md' open={store.isDialogOpen} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <form>
        <DialogContent>
          <Grid container spacing={1} paddingTop={0.2}>
            <Grid item xs={12}>
              <Controller
                name='railcarNumber'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Railcar Number'
                    variant='outlined'
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    onChange={e => {
                      const value = e.target.value.replace(/[^A-Za-z0-9]/g, '')
                      const formatted = value.replace(
                        /^([A-Za-z0-9]{4})([A-Za-z0-9]{3})([A-Za-z0-9]{4})([A-Za-z0-9])$/,
                        '$1 $2 $3-$4',
                      )
                      field.onChange(formatted)
                    }}
                    error={!!errors.railcarNumber}
                    helperText={
                      errors.railcarNumber ? t('fieldIsRequired', 'Field is required.') : ''
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='railcarLength'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label='Railcar Length'
                    variant='outlined'
                    fullWidth
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          m
                        </CustomInputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: '0',
                      step: '0.01',
                      pattern: '[0-9]*',
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            mt: theme.customSpacing.l,
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Button onClick={handleFormSubmit} variant='contained' color='primary'>
              {t('save', 'Save')}
            </Button>
            <Button onClick={handleDialogClose} color='secondary'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
          {store.dataToBeEdited && (
            <IconButton onClick={handleDelete} color='secondary'>
              <BinIcon />
            </IconButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
})
