import { ChevronLeft } from '@mui/icons-material'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  ContainerStatus,
} from '@storage/app/api'
import { BooleanSelectOption } from '@storage/app/models'
import { ContainerInformationCard } from '@storage/components/ContainerInformationCard'
import { Popup } from '@storage/components/Popup'
import { YardBlockDetails } from '@storage/components/YardBlockDetails'
import { getPopupPosition } from '@storage/components/YardCapacityWidget'
import { ContainerTurnoversList } from '@storage/components/container-turnovers-list'
import { Pagination } from '@storage/components/pagination'
import CarrierVisitAllocationRulesContainer from '@storage/features/allocation-rules/CarrierVisitAllocationRulesContainer'
import AllocationRuleTemplatesTableDialog from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleTemplatesTableDialog'
import CarrierVisitAllocationRuleListItemDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRuleListItemDialog'
import CarrierVisitAllocationRulesDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRulesDialog'
import { mapAllocationRuleTemplateDtoFacetsToFacetsFormProfile } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { useStores } from '@storage/hooks/use-stores.hook'
import { ManualPlanningDialog } from '@storage/pages/container-turnovers/components/ManualPlanningDialog'
import {
  ContainerTurnoverFilterFormMapper,
  ContainerTurnoversFilterForm,
  ContainerTurnoversFilterFormProfile,
  ContainerTurnoversFilterFormProfileKey,
  defaultValues,
} from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { useHandlePlanManually } from '@storage/pages/container-turnovers/hooks/use-handle-plan-manually.hook'
import { useHandlePlanOnYard } from '@storage/pages/container-turnovers/hooks/use-handle-plan-on-yard.hook'
import { useTranslate } from '@tolgee/react'
import { ContainerMovementInfo, useUtilsStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { PreFilterWidget } from './components/PreFiltersWidget'
import { StackedInWidget } from './components/StackedInWidget'
import { VesselVisitContext } from './components/VesselVisitContext'
import { useGoBack } from './hooks'
import { useTodoDetailsStores } from './hooks/use-todo-details-stores.hook'

export type Params = {
  vesselVisitId: string
  handlingDirection: CarrierVisitDirection
  discriminator: string
}

const TodoDetails = observer(() => {
  const { vesselVisitId, handlingDirection, discriminator } = useParams<Params>()
  const { containerTurnoverStore, yardBlockSummaryStore, carrierVisitAllocationRulesV2UIStore } =
    useStores()
  const { t } = useTranslate()

  const isAllocationRulesFeatureEnabled = useBooleanFlagValue('allocation-rules', false)

  const filterDefaultValues: Partial<ContainerTurnoversFilterFormProfile> = useMemo(
    () =>
      handlingDirection === 'Inbound'
        ? {
            inboundCarrier: vesselVisitId,
            inboundCarrierType: discriminator === 'RailVisit' ? 'Train' : 'Vessel',
            locationStatus: 'NotReserved',
          }
        : handlingDirection === 'Outbound'
          ? {
              outboundCarrier: vesselVisitId,
              outboundCarrierType: discriminator === 'RailVisit' ? 'Train' : 'Vessel',
              locationStatus: 'NotReserved',
            }
          : {},
    [discriminator, handlingDirection, vesselVisitId],
  )

  const {
    containerTurnoversPaginationStore,
    containerTurnoversFilterStore,
    containerTurnoverListStore,
    manualPlanningDialogStore,
    prefiltersStore,
    yardBlockDetailsStore,
    vesselVisitDetailsStore,
  } = useTodoDetailsStores(filterDefaultValues)

  const { carrierVisitStore: vesselVisitStore, tenantConfigStore } = useStores()

  const { containerMovementDialogStore } = useUtilsStore()

  const [positionOfDetails, setPositionOfDetails] = useState({})

  const disabledFilters: ContainerTurnoversFilterFormProfileKey[] = useMemo(
    () =>
      handlingDirection === 'Inbound'
        ? ['inboundCarrier', 'inboundCarrierType']
        : handlingDirection === 'Outbound'
          ? ['outboundCarrier', 'outboundCarrierType']
          : [],
    [handlingDirection],
  )
  useEffect(() => {
    const loadVesselVisitDetails = async () => {
      await vesselVisitDetailsStore.load()
    }

    const loadYardBlockDetails = async () =>
      await yardBlockDetailsStore.load(
        ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
          containerTurnoversFilterStore.filter,
        ),
      )

    const loadPrefilters = async () => {
      await prefiltersStore.load()
    }

    const loadYardBlockSummaries = async () => {
      await yardBlockSummaryStore.loadList()
    }

    const loadVesselVisitSummary = async () => {
      await vesselVisitStore.loadCarrierVisitSummary(Number(vesselVisitId))
    }

    const loadYard = async () => {
      await manualPlanningDialogStore.parser.init()
    }

    loadYardBlockSummaries()
    loadVesselVisitDetails()
    loadPrefilters()
    loadYardBlockDetails()
    loadVesselVisitSummary()
    loadYard()

    const dispose = containerTurnoverListStore.loadContainersEffect()

    return () => dispose()
  }, [
    containerTurnoverListStore,
    containerTurnoversFilterStore.filter,
    prefiltersStore,
    vesselVisitDetailsStore,
    vesselVisitId,
    vesselVisitStore,
    yardBlockDetailsStore,
    yardBlockSummaryStore,
    manualPlanningDialogStore.parser,
  ])

  useEffect(() => {
    // Reset selected allocation rule state & filters on 1st render i already was selected
    if (carrierVisitAllocationRulesV2UIStore.selectedAllocationRule) {
      setAllocationRuleState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePlanOnYard = useHandlePlanOnYard({ containerTurnoversFilterStore })

  const handlePlanManually = useHandlePlanManually({
    containerTurnoversFilterStore,
    manualPlanningDialogStore,
  })

  const handleMoveManually = (containers: ContainerMovementInfo[]) => {
    containerMovementDialogStore.openDialog(containers)
  }

  const handleGoBack = useGoBack()

  const onYardBlockDetailsClick = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    yardBlockSummaryStore.openContainerDetailsPopup()
    yardBlockSummaryStore.getYardBlockSummary(id)
    const popupPosition = getPopupPosition(e)
    setPositionOfDetails(popupPosition)
  }

  const togglePrefilters = (active: boolean) =>
    prefiltersStore.setSelectedPrefilter(
      active ? { index: 0, locationStatus: 'NotReserved' } : undefined,
    )

  const preAllocationValidation = () => {
    if (carrierVisitAllocationRulesV2UIStore.selectedAllocationRule) {
      carrierVisitAllocationRulesV2UIStore.preAllocationValidation(
        ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
          containerTurnoversFilterStore.filter,
        ),
      )
    }
  }

  const toggleAllocationRule = (allocationRule: CarrierVisitAllocationRuleDto) => {
    carrierVisitAllocationRulesV2UIStore.toggleAllocationRule(allocationRule)
    togglePrefilters(!carrierVisitAllocationRulesV2UIStore.selectedAllocationRule)
    filterContainerTurnoversByAllocationRuleFacets()

    // Preallocation validation call
    preAllocationValidation()
  }

  const setAllocationRuleState = (allocationRule?: CarrierVisitAllocationRuleDto) => {
    const isActive = !!allocationRule
    togglePrefilters(!isActive)
    carrierVisitAllocationRulesV2UIStore.setSelectedAllocationRule(allocationRule)
    filterContainerTurnoversByAllocationRuleFacets()

    // Preallocation validation call
    preAllocationValidation()
  }

  const filterContainerTurnoversByAllocationRuleFacets = () => {
    if (carrierVisitAllocationRulesV2UIStore.selectedAllocationRule) {
      containerTurnoversFilterStore.setFilter({
        ...defaultValues,
        ...filterDefaultValues,
        locationStatus: 'All',
        isInTerminal: BooleanSelectOption.False,
        ...mapAllocationRuleTemplateDtoFacetsToFacetsFormProfile(
          carrierVisitAllocationRulesV2UIStore.selectedAllocationRule.facets,
        ),
      })
    } else {
      containerTurnoversFilterStore.setFilter({
        ...defaultValues,
        ...filterDefaultValues,
        locationStatus: ContainerStatus.NotReserved,
      })
    }
  }

  if (!vesselVisitId || !handlingDirection) return <></>

  return (
    <>
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 2,
            px: 3,
          }}
        >
          <IconButton onClick={handleGoBack}>
            <ChevronLeft />
          </IconButton>
          <Typography variant='subtitle1'>
            {t('containerFiltering', 'Container filtering')}
          </Typography>
        </Box>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            height: 'calc(100% - 115px)',
            width: '100%',
            gap: 2,
            position: 'relative',
            overflowY: 'auto',
            pb: 3,
            px: 3,
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              gap: 1,
              minWidth: '319px',
              position: 'sticky',
              top: 0,
              height: '100vh',
            }}
          >
            {vesselVisitDetailsStore.vesselVisitDetails && (
              <VesselVisitContext
                vesselVisit={vesselVisitDetailsStore.vesselVisitDetails}
                handlingDirection={handlingDirection}
                prefiltersStore={prefiltersStore}
                vesselVisitStore={vesselVisitStore}
              />
            )}

            {isAllocationRulesFeatureEnabled && (
              <CarrierVisitAllocationRulesContainer
                toggleAllocationRule={toggleAllocationRule}
                carrierVisitDirection={handlingDirection}
                carrierVisitId={parseInt(vesselVisitId, 10)}
              />
            )}

            <PreFilterWidget
              prefiltersStore={prefiltersStore}
              containerTurnoversFilterStore={containerTurnoversFilterStore}
              filterDefaultValues={filterDefaultValues}
            />
            {vesselVisitStore.carrierVisitSummaries &&
              handlingDirection === CarrierVisitDirection.Outbound && (
                <StackedInWidget
                  stackedInContainersCount={
                    vesselVisitStore.carrierVisitSummaries.get(Number(vesselVisitId))?.inYardLoad ??
                    0
                  }
                  prefiltersStore={prefiltersStore}
                  containerTurnoversFilterStore={containerTurnoversFilterStore}
                  filterDefaultValues={filterDefaultValues}
                />
              )}
          </Box>
          <Box sx={{ minWidth: '400px', flexGrow: 1, top: 0, height: '100vh' }}>
            <ContainerTurnoversFilterForm
              filterDefaultValues={filterDefaultValues}
              containersNumberMatchingFilter={containerTurnoverStore.pagination.totalCount}
              filter={containerTurnoversFilterStore.filter}
              advancedFiltersShown={containerTurnoversFilterStore.showAdvancedFilters}
              onToggleAdvancedFilters={() =>
                containerTurnoversFilterStore.toggleShowAdvancedFilters()
              }
              disabledFilters={disabledFilters}
              onPlanManually={handlePlanManually}
              onPlanOnYard={handlePlanOnYard}
              onMoveManually={handleMoveManually}
              onSubmit={formValues => {
                containerTurnoversFilterStore.setFilter(formValues)
                prefiltersStore.setSelectedPrefilter(undefined)
              }}
              manualPlanningDialogStore={manualPlanningDialogStore}
            />

            <ContainerTurnoversList
              items={containerTurnoverStore.entries}
              onPlanItemManually={handlePlanManually}
              onPlanItemOnYard={handlePlanOnYard}
              onMoveItemManually={handleMoveManually}
            />
          </Box>
          {!tenantConfigStore.isBayRowUniqueIdentificationModeActivated && (
            <Box sx={{ width: '288px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pb: '5rem' }}>
                {yardBlockDetailsStore.yardBlockDetails.map(yardBlock => (
                  <YardBlockDetails
                    key={yardBlock.blockId}
                    blockName={yardBlock.blockName}
                    totalSpace={yardBlock.totalSpace}
                    occupiedSpace={yardBlock.occupiedSpace}
                    filterMatchedContainers={yardBlock.filterMatchedContainers}
                    nonFilterMatchedContainers={yardBlock.nonFilterMatchedContainer}
                    onDetailsClick={e => onYardBlockDetailsClick(e, yardBlock.blockId)}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {yardBlockSummaryStore.isBlockDetailsButtonClicked && (
          <Popup
            onClose={yardBlockSummaryStore.closeContainerDetailsPopup}
            position={positionOfDetails}
            title={t('details', 'Details')}
          >
            {yardBlockSummaryStore.blockSummary && (
              <ContainerInformationCard
                groupedBySize={yardBlockSummaryStore.blockSummary.groupedBySize}
                groupedByOperator={yardBlockSummaryStore.blockSummary.groupedByOperator}
                fullCount={yardBlockSummaryStore.blockSummary.fullCount}
                emptyCount={yardBlockSummaryStore.blockSummary.emptyCount}
              />
            )}
          </Popup>
        )}
        <ManualPlanningDialog manualPlanningDialogStore={manualPlanningDialogStore} />
      </Box>

      <Pagination
        paginationStore={containerTurnoversPaginationStore}
        paginatedEntityStore={containerTurnoverStore}
      />

      <CarrierVisitAllocationRulesDialog
        carrierVisitDirection={handlingDirection}
        carrierVisitId={parseInt(vesselVisitId, 10)}
        setAllocationRuleState={setAllocationRuleState}
        deprecatedVersion={true}
      />

      <AllocationRuleTemplatesTableDialog />

      <CarrierVisitAllocationRuleListItemDialog
        setAllocationRuleState={setAllocationRuleState}
        carrierVisitDirection={handlingDirection}
        carrierVisitId={parseInt(vesselVisitId, 10)}
        deprecatedVersion={true}
      />
    </>
  )
})
export default TodoDetails
