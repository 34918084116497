import { Box } from '@mui/material'
import { IssueStatus, OrderIssueResolutionType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { IssuesViewStore } from '../Stores/IssuesViewStore'
import ChangeTransportForm from './ChangeTransportForm'
import FieldBoxWithRadio from './FieldboxWithRadio'

interface IProps {
  store: IssuesViewStore
}

const ResolveIssuesFormList = observer(({ store }: IProps) => {
  const { t } = useTranslate()
  const isFollowUp = store.issueToBeResolved?.data.issueStatus === IssueStatus.FollowUp
  const [selectedId, setSelectedId] = useState<OrderIssueResolutionType | null>(
    isFollowUp ? IssueStatus.FollowUp : null,
  )

  const handleSelect = (id: OrderIssueResolutionType) => {
    setSelectedId(id)
    store.setSelectedIssueResolution(id)
  }

  return (
    <Box>
      <FieldBoxWithRadio
        id={OrderIssueResolutionType.ChangeOutboundTransport}
        selectedId={selectedId!}
        label={t('changeOutbountTranport', 'Change outbound transport')}
        onSelect={handleSelect}
      >
        <ChangeTransportForm store={store} />
      </FieldBoxWithRadio>

      <FieldBoxWithRadio
        id={OrderIssueResolutionType.DeleteOrder}
        selectedId={selectedId!}
        label={t('removeOrder', 'Remove order')}
        onSelect={handleSelect}
      />

      <FieldBoxWithRadio
        id={OrderIssueResolutionType.RailcarFound}
        selectedId={selectedId!}
        label={t('railcarWasFound', 'Railcar was found')}
        onSelect={handleSelect}
      />

      <FieldBoxWithRadio
        id={OrderIssueResolutionType.FollowUp}
        selectedId={selectedId!}
        label={t('markForFollowUp', 'Mark for follow up')}
        onSelect={handleSelect}
      />
    </Box>
  )
})

export default ResolveIssuesFormList
