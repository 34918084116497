import { Box, Stack, Typography } from '@mui/material'
import { AllocationRuleTemplateDtoFacets } from '@storage/app/api'
import { UnknownStringValue } from '@storage/app/models'
import CustomTabsContainer from '@storage/components/CustomTabsContainer'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface AllocationRuleTemplateFacetListProps {
  facets: AllocationRuleTemplateDtoFacets
}

const AllocationRuleTemplateFacetList = ({ facets }: AllocationRuleTemplateFacetListProps) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  const facetElements = [
    facets.size && `${facets.size}′`,
    facets.isEmpty != null && (facets.isEmpty ? t('empty', 'Empty') : t('full', 'Full')),
    facets.isReefer != null &&
      (facets.isReefer ? t('reefer', 'Reefer') : t('nonReefer', 'Non Reefer')),
    facets.isDangerous != null &&
      (facets.isDangerous ? t('dangerous', 'Dangerous') : t('nonDangerous', 'Non Dangerous')),
    facets.containerOperator,
    facets.containerHeight,
    facets.containerType,
    facets.weightClasses?.length && facets.weightClasses.join(', '),
    facets.consignee &&
      `${facets.consignee === UnknownStringValue ? t('consignee', 'Consignee') + ': ' : ''}${facets.consignee}`,
    facets.portOfDischarge &&
      `${facets.portOfDischarge === UnknownStringValue ? t('portOfDischarge', 'Port of discharge') + ': ' : ''}${facets.portOfDischarge}`,
    facets.outboundCarrierType &&
      `${t('outboundCarrier', 'Outbound carrier')}: ${t(facets.outboundCarrierType)}`,
  ].filter(Boolean)

  return (
    <Box onClick={event => event.stopPropagation()}>
      <CustomTabsContainer maxWidth='100%'>
        <Stack direction='row' spacing={1} p={0.5}>
          {facets.containerNumber ? (
            <Typography color={palette.text.secondary} whiteSpace='nowrap' variant='body1'>
              {facets.containerNumber}
            </Typography>
          ) : (
            facetElements.map((element, index) => (
              <Typography
                key={index}
                color={palette.text.secondary}
                whiteSpace='nowrap'
                variant='body1'
              >
                {element}
              </Typography>
            ))
          )}
        </Stack>
      </CustomTabsContainer>
    </Box>
  )
}

export default AllocationRuleTemplateFacetList
