import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { usePlanningStore } from '@planning/AppProvider'
import { FilterChip } from '@planning/components/FilterChip'
import { useTranslate } from '@tolgee/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { IssuesViewStore } from '../Stores/IssuesViewStore'

interface Props {
  store: IssuesViewStore
}

export const IssuesFilter = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const { railVisitItemStore, railcarTrackPositionItemStore } = usePlanningStore()

  return (
    (store.filterByVisitId || store.filterByRailcarTrackPositionId) && (
      <Stack direction='row' alignItems='center' gap={1} padding={2}>
        <Typography variant='subtitle1'>{t('filterBy', 'Filter By')}: </Typography>
        {store.filterByVisitId && (
          <FilterChip
            label={
              _(railVisitItemStore.elements).find(v => v.id === store.filterByVisitId)?.data.name ??
              ''
            }
            handleFilterChipDelete={() => store.setFilterByVisitId()}
            sx={{ m: 1 }}
          />
        )}
        {store.filterByRailcarTrackPositionId && (
          <FilterChip
            label={`${t('railCarNo', 'Railcar no.')} ${
              _(railcarTrackPositionItemStore.elements).find(
                r => r.id === store.filterByRailcarTrackPositionId,
              )?.data.railcarName ?? ''
            }`}
            handleFilterChipDelete={() => store.setFilterByRailCarTrackPositionId()}
            sx={{ m: 1 }}
          />
        )}
      </Stack>
    )
  )
})
