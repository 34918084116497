import {
  CreateEquipmentCommand,
  EquipmentDto,
  EquipmentsApi,
  UnassignEquipmentCommand,
  UpdateEquipmentCommand,
} from '@operations/app/api'

import { createApiClient } from '@operations/app/http-client'

import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { BaseEntityStore } from './base/BaseEntityStore'

export class EquipmentStore extends BaseEntityStore<EquipmentDto> {
  constructor(private messageBus: IMessageBus) {
    super()

    this.messageBus.subscribeEvent<EquipmentDto>(EventTypes.EquipmentUpserted, this.upsertEquipment)

    this.messageBus.subscribeEvent<number>(EventTypes.EquipmentDeleted, this.deleteEquipment)
  }

  async load() {
    const { data } = await createApiClient(EquipmentsApi).get()
    this.updateStoreItems(data)
  }

  async loadOne(id: number) {
    const { data } = await createApiClient(EquipmentsApi).getOne(id)
    this.updateStoreItem(data, data.id)
  }

  async loadBy(vesselVisitId: number, orderId?: number) {
    const { data } = await createApiClient(EquipmentsApi).getForTallymanOfDischarging(
      vesselVisitId,
      orderId,
    )
    this.updateStoreItems(data)
  }

  async loadPlanned(carrierVisitId: number) {
    const { data } = await createApiClient(EquipmentsApi).getPlanned(carrierVisitId)

    return data
  }

  async add(createEquipmentCommand: CreateEquipmentCommand) {
    await createApiClient(EquipmentsApi).create(createEquipmentCommand)
  }

  async update(updateEquipmentCommand: UpdateEquipmentCommand) {
    await createApiClient(EquipmentsApi).update(updateEquipmentCommand)
  }

  async delete(id: number) {
    await createApiClient(EquipmentsApi)._delete(id)
    this.deleteStoreItem(id)
  }

  async unassign(updateEquipmentCommand: UnassignEquipmentCommand) {
    await createApiClient(EquipmentsApi).unassign(updateEquipmentCommand)
  }

  async loadIdsAlreadyInUseByOtherVisits(vesselId: number) {
    const { data } = await createApiClient(EquipmentsApi).getIdsAlreadyInUseByOtherVisits(vesselId)

    return data
  }

  async loadEquipmentStatistics(currentDate: Date) {
    const { data } = await createApiClient(EquipmentsApi).equipmentStats(currentDate.toUTCString())

    return data
  }

  async loadCraneQueueStats() {
    const { data } = await createApiClient(EquipmentsApi).craneQueueStats()

    return data
  }

  private upsertEquipment = (res: IEvent<EquipmentDto>) => {
    const equipment = res.payload

    if (!equipment) return

    this.updateStoreItem(equipment, equipment.id)
  }

  private deleteEquipment = (res: IEvent<number>) => {
    const id = res.payload

    if (!id) return

    this.deleteStoreItem(id)
  }
}
