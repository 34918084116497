import {
  CarrierType,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  ContainerTurnoverDto,
  SlotDto,
  WeightClassDto,
} from '@storage/app/api'
import { UnknownStringValue } from '@storage/app/models'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import _ from 'lodash'
import { StackWithOverlapDto } from '../interfaces'

export const isStackHighlighted = (
  stack: StackWithOverlapDto,
  selectedAllocationRule?: CarrierVisitAllocationRuleDto,
) => {
  if (!selectedAllocationRule) return false

  const { block, bay, row } = selectedAllocationRule.destination
  const stackPosition = stack.yardPosition

  const blockLower = block?.toLowerCase()
  const stackBlockLower = stackPosition.block?.toLowerCase()

  if (blockLower !== stackBlockLower) return false

  const isBayMatch =
    !bay ||
    (bay.includes('-')
      ? comparePositionRange(bay, stackPosition.bay)
      : stackPosition.bay?.toLowerCase() === bay.toLowerCase())

  const isRowMatch =
    !row ||
    (row.includes('-')
      ? comparePositionRange(row, stackPosition.row)
      : stackPosition.row?.toLowerCase() === row.toLowerCase())

  return isBayMatch && isRowMatch
}

const comparePositionRange = (rulePos: string, stackPos?: string | null) => {
  if (!stackPos) {
    return false
  }
  const [start, end] = rulePos.split('-').map(p => p.toLowerCase())
  const pos = stackPos.toLowerCase()

  if (isNaN(Number(start)) && isNaN(Number(end))) {
    // Both are non-numeric, use string comparison
    return (pos >= start && pos <= end) || (pos <= start && pos >= end)
  } else if (!isNaN(Number(start)) && !isNaN(Number(end)) && !isNaN(Number(pos))) {
    // All are numeric, use number comparison
    const numPos = Number(pos)
    const numStart = Number(start)
    const numEnd = Number(end)
    return (numPos >= numStart && numPos <= numEnd) || (numPos <= numStart && numPos >= numEnd)
  } else {
    // Mixed case (some numeric, some alphabetic) - use string comparison
    return (pos >= start && pos <= end) || (pos <= start && pos >= end)
  }
}

export const doesSlotHaveOccupiedTurnoverForVisitAndDirection = (
  slot: SlotDto,
  carrierVisitId: number,
  carrierVisitDirection: CarrierVisitDirection,
) =>
  slot.occupiedTurnovers.some(turnover => {
    if (carrierVisitDirection === CarrierVisitDirection.Inbound)
      return turnover.inboundCarrierVisitId === carrierVisitId

    return turnover.outboundCarrierVisitId === carrierVisitId
  })

export const filterContainerTurnoversByWeightClass = (
  containerTurnovers: ContainerTurnoverDto[],
  weightClasses: WeightClassDto[],
) => {
  let result: ContainerTurnoverDto[] = []
  const turnoversWithGrossWeight = containerTurnovers.filter(x => x.grossWeight !== null)

  weightClasses.forEach(element => {
    let filteredQuery = turnoversWithGrossWeight.filter(t => t.grossWeight! >= element.minWeight)

    if (element.maxWeight) {
      filteredQuery = filteredQuery.filter(t => t.grossWeight! <= element.maxWeight!)
    }

    result = result.length === 0 ? filteredQuery : result.concat(filteredQuery)
  })

  return result
}

export const filterContainerTurnoversByBreakdown = (
  unallocatedBreakdown: UnallocatedTurnoversBreakDown,
  containerTurnovers: ContainerTurnoverDto[],
  weightClasses: WeightClassDto[],
) => {
  const filteredTurnovers = containerTurnovers.filter(turnover => {
    const isSizeValid = turnover.size === unallocatedBreakdown.size

    const isReeferValid =
      unallocatedBreakdown.isReefer === undefined ||
      turnover.isReefer === unallocatedBreakdown.isReefer

    const isDangerousValid =
      unallocatedBreakdown.isDangerous === undefined ||
      turnover.isDangerous === unallocatedBreakdown.isDangerous

    const isEmptyValid =
      unallocatedBreakdown.isEmpty === undefined ||
      turnover.isEmpty === unallocatedBreakdown.isEmpty

    const isConsigneeValid =
      !unallocatedBreakdown.consignee ||
      turnover.consignee === unallocatedBreakdown.consignee ||
      (unallocatedBreakdown.consignee === UnknownStringValue && !turnover.consignee)

    const isOutboundCarrierTypeValid =
      !unallocatedBreakdown.outboundCarrierType ||
      turnover.outboundCarrierType === unallocatedBreakdown.outboundCarrierType ||
      (unallocatedBreakdown.outboundCarrierType === CarrierType.Unknown &&
        !turnover.outboundCarrierType)

    const isCustomerValid =
      !unallocatedBreakdown.customer ||
      turnover.customerId?.toString() === unallocatedBreakdown.customer ||
      (unallocatedBreakdown.customer === UnknownStringValue && !turnover.customerId)

    let isWeightClassValid = true
    if (unallocatedBreakdown.weightClass) {
      if (unallocatedBreakdown.weightClass === UnknownStringValue) {
        const orderedWeightClasses = _(weightClasses)
          .orderBy(x => x.minWeight)
          .value()
        const minWeight = orderedWeightClasses[0]?.minWeight
        const maxWeight = orderedWeightClasses[orderedWeightClasses.length - 1]?.maxWeight

        isWeightClassValid =
          !turnover.grossWeight ||
          turnover.grossWeight < minWeight ||
          (!!maxWeight && turnover.grossWeight > maxWeight)
      } else {
        const weightClass = weightClasses.find(
          wc => unallocatedBreakdown.weightClass!.toUpperCase() === wc.name.toUpperCase(),
        )

        if (
          weightClass &&
          (!turnover.grossWeight ||
            weightClass.minWeight > turnover.grossWeight ||
            (weightClass.maxWeight && weightClass.maxWeight < turnover.grossWeight))
        )
          isWeightClassValid = false
      }
    }

    return (
      isSizeValid &&
      isReeferValid &&
      isDangerousValid &&
      isEmptyValid &&
      isConsigneeValid &&
      isCustomerValid &&
      isOutboundCarrierTypeValid &&
      isWeightClassValid
    )
  })

  return filteredTurnovers
}
