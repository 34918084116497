import { Box } from '@mui/material'
import { useHubConnection } from '@storage/hooks/use-hub-connection.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { yardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import ArrivedCarrierVisitsWidget from './components/ArrivedCarrierVisitsWidget'
import CarrierVisitsInOperationWidget from './components/CarrierVisitsInOperationWidget'
import ExpectedCarrierVisitsWidget from './components/ExpectedCarrierVisitsWidget'
import YardPlanningDashboardHeader from './components/YardPlanningDashboardHeader'
import { useCarrierVisitDetails, useHandlePlan } from './components/hooks'
import { toJS } from 'mobx'

const YardPlanningDashboard = observer(() => {
  const {
    carrierVisitStore,
    yardBlockStore,
    containerTurnoverStore,
    issueStore,
    yardInconsistenciesStore,
  } = useStores()

  useTranslate()
  useMinimalsTheme()

  useHubConnection(yardManagementHubConnection)

  useEffect(() => {
    const loadCarrierVisits = async () => {
      await carrierVisitStore.loadList()
    }

    // TODO: Refactor to get only KPIs response (yard & containers)
    const loadBlocks = async () => {
      await yardBlockStore.loadList()
    }

    const loadInconsistencies = async () => {
      await yardInconsistenciesStore.loadAll()
    }

    const loadContainerTurnovers = async () => {
      await containerTurnoverStore.loadAll()
    }

    loadContainerTurnovers()
    loadBlocks()
    loadCarrierVisits()
    loadInconsistencies()
  }, [containerTurnoverStore, carrierVisitStore, yardBlockStore, yardInconsistenciesStore])

  const handlePlan = useHandlePlan()
  const navigateToCarrierVisitDetails = useCarrierVisitDetails()

  const { tenantConfigStore } = useStores()

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', gap: 3, p: 2 }}
    >
      <YardPlanningDashboardHeader
        totalYardBlock={yardBlockStore.entries.length}
        totalContainerTurnovers={containerTurnoverStore.pagination.totalCount}
        totalIssues={issueStore.total}
        yardInconsistencies={yardInconsistenciesStore.count}
      />
      {!tenantConfigStore.skipYardPlanning && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ maxHeight: '100vh' }}>
            <ExpectedCarrierVisitsWidget
              carrierVisitStore={carrierVisitStore}
              handlePlan={handlePlan}
              navigateToCarrierVisitDetails={navigateToCarrierVisitDetails}
            />
          </Box>
          <Box sx={{ maxHeight: '100vh' }}>
            <ArrivedCarrierVisitsWidget
              carrierVisitStore={carrierVisitStore}
              handlePlan={handlePlan}
              navigateToCarrierVisitDetails={navigateToCarrierVisitDetails}
            />
          </Box>
          <Box sx={{ maxHeight: '100vh' }}>
            <CarrierVisitsInOperationWidget
              carrierVisitStore={carrierVisitStore}
              handlePlan={handlePlan}
              navigateToCarrierVisitDetails={navigateToCarrierVisitDetails}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
})

export default YardPlanningDashboard
