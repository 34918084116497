import { Box, Card, Divider, Typography } from '@mui/material'
import { CarrierVisitDirection, ContainerTurnoverDto, WeightClassDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { UnAllocatedFilter, UnAllocatedLayer } from '../interfaces'
import { filterContainerTurnoversByBreakdown } from '../utils/yard-planning-dashboard-stack.util'
import UnAllocatedContainersGroup from './UnAllocatedContainersGroup'
import UnAllocatedContainersHeader from './UnAllocatedContainersHeader'
import { UnAllocatedItems } from './UnAllocatedItems'

interface Props {
  unallocatedTurnovers: ContainerTurnoverDto[]
  carrierVisitDirection: CarrierVisitDirection
  weightClasses: WeightClassDto[]
}

const UnAllocatedContainers = observer(
  ({ unallocatedTurnovers, carrierVisitDirection, weightClasses }: Props) => {
    const { breakpoints } = useMinimalsTheme()
    const { t } = useTranslate()
    const {
      carrierVisitAllocationRulesV2UIStore,
      carrierVisitStore,
      customerStore,
      carrierVisitUnAllocatedTurnoversV2UIStore,
    } = useStores()
    const [filter, setFilter] = useState<UnAllocatedFilter>('containerNumber')
    const [layers, setLayers] = useState<UnAllocatedLayer[]>([])

    useEffect(() => {
      setLayers(layers =>
        layers
          .map(layer => {
            const containers = filterContainerTurnoversByBreakdown(
              layer.turnoversBreakdown,
              unallocatedTurnovers,
              weightClasses,
            )

            return {
              ...layer,
              containerTurnovers: containers,
            }
          })
          .filter(layer => layer.containerTurnovers.length),
      )
    }, [unallocatedTurnovers])

    const standardContainers = useMemo(() => {
      return carrierVisitStore.getStandardUnallocatedBreakdownFromTurnovers(
        unallocatedTurnovers ?? [],
      )
    }, [carrierVisitStore, unallocatedTurnovers])

    const specialContainers = useMemo(() => {
      return carrierVisitStore.getSpecialUnallocatedBreakdownFromTurnovers(
        unallocatedTurnovers ?? [],
      )
    }, [carrierVisitStore, unallocatedTurnovers])

    const startExpand = (breakdownItem: UnallocatedTurnoversBreakDown, isStandard?: boolean) => {
      setLayers([])
      handleExpand(breakdownItem, undefined, isStandard)
    }

    const handleExpand = (
      breakdownItem: UnallocatedTurnoversBreakDown,
      previousFilter?: UnAllocatedFilter,
      isStandard?: boolean,
    ) => {
      let defaultGroupBy: UnAllocatedFilter = 'containerNumber'
      const filters: SelectOption[] = []

      if (carrierVisitDirection === CarrierVisitDirection.Inbound && !layers.length) {
        filters.push(
          { label: t('weightClass', 'Weight class'), value: 'weightClass' },
          { label: t('customer', 'Customer'), value: 'customer' },
          {
            label: t('outboundCarrierType', 'Outbound carrierF type'),
            value: 'outboundCarrierType',
          },
          { label: t('consignee', 'Consignee'), value: 'consignee' },
        )
        defaultGroupBy = 'weightClass'
        setFilter(defaultGroupBy)
      } else if (carrierVisitDirection === CarrierVisitDirection.Outbound) {
        if (!layers.length) {
          filters.push(
            { label: t('portOfDischarge', 'Port of discharge'), value: 'portOfDischarge' },
            { label: t('weightClass', 'Weight class'), value: 'weightClass' },
          )
          defaultGroupBy = 'portOfDischarge'
          setFilter(defaultGroupBy)
        } else if (layers.length === 1) {
          defaultGroupBy = previousFilter === 'portOfDischarge' ? 'weightClass' : 'portOfDischarge'
        }
      }

      setLayers(layers => [
        ...layers,
        {
          isStandard: isStandard ?? layers[0]?.isStandard,
          turnoversBreakdown: breakdownItem,
          containerTurnovers: breakdownItem.value,
          defaultGroupBy: defaultGroupBy,
          filters: filters,
          isFinalLevel: defaultGroupBy === 'containerNumber',
        },
      ])
    }

    const handleReturn = () => {
      setLayers(layers => layers.filter((_, index) => index < layers.length - 1))
    }

    const handleOnContainerGroupCreateRule = (breakdownItem: UnallocatedTurnoversBreakDown) =>
      carrierVisitAllocationRulesV2UIStore.openAddDialogWithBreakdown(breakdownItem)

    if (carrierVisitUnAllocatedTurnoversV2UIStore.dataLoading)
      return (
        <Card>
          <Typography my={1} mx={2} variant='body1' textAlign='center'>
            {t('dataLoading', 'Data loading...')}
          </Typography>
        </Card>
      )

    return (
      <Card
        sx={{
          [breakpoints.up('sm')]: {
            height: '44vh !important',
          },
        }}
      >
        {unallocatedTurnovers.length === 0 ? (
          <Box height='100%'>
            <Typography my={1} mx={2} variant='body1' textAlign='center'>
              {t('noUnAllocatedContainers', 'No unallocated containers')}
            </Typography>
          </Box>
        ) : (
          <>
            <UnAllocatedContainersHeader
              numberOfUnallocatedContainers={unallocatedTurnovers.length}
            />
            <Divider />

            <Box
              sx={{
                overflowY: 'auto',
                height: '90%',
              }}
            >
              {layers.length === 0 ? (
                <>
                  {standardContainers.length > 0 && (
                    <UnAllocatedContainersGroup
                      unallocatedContainersBreakdown={standardContainers}
                      variant='standard'
                      onCreateNewRule={handleOnContainerGroupCreateRule}
                      onExpand={breakdownItem => startExpand(breakdownItem, true)}
                    />
                  )}
                  {specialContainers.length > 0 && (
                    <UnAllocatedContainersGroup
                      unallocatedContainersBreakdown={specialContainers}
                      variant='special'
                      onCreateNewRule={handleOnContainerGroupCreateRule}
                      onExpand={breakdownItem => startExpand(breakdownItem)}
                    />
                  )}
                </>
              ) : (
                <UnAllocatedItems
                  layer={layers[layers.length - 1]}
                  weightClasses={weightClasses}
                  filter={filter}
                  changeFilter={setFilter}
                  onCreateNewRule={handleOnContainerGroupCreateRule}
                  onExpand={handleExpand}
                  onReturn={handleReturn}
                  customers={customerStore.customers}
                />
              )}
            </Box>
          </>
        )}
      </Card>
    )
  },
)

export default UnAllocatedContainers
