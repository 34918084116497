import { setupInterceptors } from '@operations/app/http-client'
import { setupValidation } from '@operations/app/validation'
import { CraneOperatorLandingContainerUIStore } from '@operations/features/craneOperator/stores/crane-operator-landing-container.ui-store'
import { CraneOperatorNoGeometryUIStore } from '@operations/features/craneOperator/stores/crane-operator-no-geometry.ui-store'
import { CraneOperatorUIStore } from '@operations/features/craneOperator/stores/crane-operator.ui-store'
import { CraneSplitContainerUIStore } from '@operations/features/craneSplit/stores/crane-split-container.ui-store'
import { CraneSplitDialogUIStore } from '@operations/features/craneSplit/stores/crane-split-dialog.ui-store'
import { CraneSplitDrawerUIStore } from '@operations/features/craneSplit/stores/crane-split-drawer.ui-store'
import { CraneSplitMessageUIStore } from '@operations/features/craneSplit/stores/crane-split-message.ui-store'
import { SelectCranesDialogUIStore } from '@operations/features/craneSplit/stores/select-cranes-dialog.ui-store'
import { EquipmentOperatorLandingUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-landing.ui-store'
import { EquipmentOperatorNotificationUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-notification.ui-store'
import { EquipmentOperatorSearchUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-search.ui-store'
import { EquipmentOperatorSignalRUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-signalr.ui-store'
import { EquipmentOperatorStepperUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-stepper.ui-store'
import { EquipmentOperatorWorkInstructionStepperUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-work-instruction-stepper.ui-store'
import { EquipmentOperatorWorkInstructionsUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-work-instructions.ui-store'
import { EquipmentOperatorUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator.ui-store'
import { EquipmentPlanningEquipmentsUIStore } from '@operations/features/equipmentPlanning/stores/equipment-planning-equipments-ui-store'
import { EquipmentPlanningInfoUIStore } from '@operations/features/equipmentPlanning/stores/equipment-planning-info-ui-store'
import { EquipmentPlanningUIStore } from '@operations/features/equipmentPlanning/stores/equipment-planning-ui-store'
import { EquipmentDialogUIStore } from '@operations/features/equipments/stores/equipment-dialog.ui-store'
import { EquipmentFiltersUIStore } from '@operations/features/equipments/stores/equipment-filters.ui-store'
import { EquipmentsUIStore } from '@operations/features/equipments/stores/equipments.ui-store'
import { JobMonitoringDrawerUIStore } from '@operations/features/job/stores/job-monitoring-drawer.ui-store'
import { WorkInstructionFiltersUIStore } from '@operations/features/job/stores/work-instruction-filters.ui-store'
import { WorkInstructionUIStore } from '@operations/features/job/stores/work-instruction.ui-store'
import { ReeferMonitoringDrawerUIStore } from '@operations/features/reeferMonitoring/stores/reefer-monitoring-drawer.ui-store'
import { ReeferMonitoringFiltersUIStore } from '@operations/features/reeferMonitoring/stores/reefer-monitoring-filters.ui-store'
import { ReeferMonitoringUIStore } from '@operations/features/reeferMonitoring/stores/reefer-monitoring.ui-store'
import { TallymanOperationsUIStore } from '@operations/features/tallyman/stores/tallyman-operations.ui-store'
import { VesselVisitTableUIStore } from '@operations/features/vesselVisit/stores/vessel-visit-table.ui-store'
import { EquipmentsVesselVisitUIStore } from '@operations/features/vesselVisitDashboard/stores/equipments-vessel-visit.ui-store'
import { CargoUnitStore } from '@operations/stores/CargoUnitStore'
import { ContainerStowageLocationStore } from '@operations/stores/ContainerStowageLocationStore'
import { DataSnapshotStore } from '@operations/stores/DataSnapshotStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { JobStore } from '@operations/stores/JobStore'
import { ReeferTemperatureStore } from '@operations/stores/ReeferTemperatureStore'
import { TenantConfigStore } from '@operations/stores/TenantConfigStore'
import { VesselVisitStore } from '@operations/stores/VesselVisitStore'
import { WorkAreaStore } from '@operations/stores/WorkAreaStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import { WorkQueueStore } from '@operations/stores/WorkQueueStore'
import { YardBlockStore } from '@operations/stores/YardBlockStore'
import {
  AppStore,
  AuthStore,
  appStore as appStoreObject,
  authStore as authStoreObject,
} from '@tom-ui/utils'
import { ReactNode, createContext, useContext } from 'react'
import { CoolingOrdersUIStore } from './features/coolingOperator/stores/cooling-oders.ui-store'
import { EquipmentPlanningRailEquipmentsUIStore } from './features/equipmentPlanning/stores/equipment-planning-rail-equipmentsl-ui-store'
import { EquipmentPlanningRailUIStore } from './features/equipmentPlanning/stores/equipment-planning-rail-ui-store'
import { EventTypes } from './messages/eventsTypes'
import { HubContext } from './messages/hubConnection'
import { MessageBus } from './messages/messageBus'
import { RailVisitStore } from './stores/RailVisitStore'

export const messageBus = new MessageBus()
export const hubContext = new HubContext(messageBus)
const listenedEvents: string[] = [
  EventTypes.CarrierVisitRefresh,
  EventTypes.ReeferTemperatureUpserted,
  EventTypes.WorkInstructionDeleted,
  EventTypes.WorkInstructionFinished,
  EventTypes.JobUpserted,
  EventTypes.WorkInstructionConfirmed,
  EventTypes.EquipmentsJobRefresh,
  EventTypes.WagonWeightUpdated,
  EventTypes.WorkInstructionJobsUpserted,
  EventTypes.CoolingOrderUpserted,
  EventTypes.WorkInstructionUpserted,
  EventTypes.EquipmentLastPositionOnYard,
  EventTypes.CarrierVisitWorkInstructionUpserted,
  EventTypes.EquipmentUpserted,
  EventTypes.EquipmentDeleted,
]
listenedEvents.forEach(c => hubContext.registerPassThroughConnection(c))
hubContext.startConnection()

type IStore = {
  appStore: AppStore
  tenantConfigStore: TenantConfigStore
  cargoUnitStore: CargoUnitStore
  equipmentStore: EquipmentStore
  jobStore: JobStore
  vesselVisitStore: VesselVisitStore
  railVisitStore: RailVisitStore
  workAreaStore: WorkAreaStore
  workInstructionStore: WorkInstructionStore
  workQueueStore: WorkQueueStore
  yardBlockStore: YardBlockStore
  authStore: AuthStore
  containerStowageLocationStore: ContainerStowageLocationStore
  reeferTemperatureStore: ReeferTemperatureStore
  dataSnapshotStore: DataSnapshotStore
}
const appStore = appStoreObject
const tenantConfigStore = new TenantConfigStore()
const cargoUnitStore = new CargoUnitStore()
const equipmentStore = new EquipmentStore(messageBus)
const jobStore = new JobStore()
const vesselVisitStore = new VesselVisitStore()
const railVisitStore = new RailVisitStore()
const workAreaStore = new WorkAreaStore()
const workInstructionStore = new WorkInstructionStore()
const workQueueStore = new WorkQueueStore()
const yardBlockStore = new YardBlockStore()
const authStore = authStoreObject
const containerStowageLocationStore = new ContainerStowageLocationStore()
const reeferTemperatureStore = new ReeferTemperatureStore(messageBus, appStore)
const dataSnapshotStore = new DataSnapshotStore()

type IViewStore = {
  craneSplitContainerUIStore: CraneSplitContainerUIStore
  craneSplitDialogUIStore: CraneSplitDialogUIStore
  craneSplitDrawerUIStore: CraneSplitDrawerUIStore
  craneSplitMessageUIStore: CraneSplitMessageUIStore
  selectCranesDialogUIStore: SelectCranesDialogUIStore
  equipmentOperatorUIStore: EquipmentOperatorUIStore
  equipmentOperatorSignalRUIStore: EquipmentOperatorSignalRUIStore
  equipmentOperatorNotificationUIStore: EquipmentOperatorNotificationUIStore
  equipmentOperatorLandingUIStore: EquipmentOperatorLandingUIStore
  equipmentOperatorStepperUIStore: EquipmentOperatorStepperUIStore
  equipmentOperatorWorkInstructionStepperUIStore: EquipmentOperatorWorkInstructionStepperUIStore
  equipmentOperatorSearchUIStore: EquipmentOperatorSearchUIStore
  equipmentOperatorWorkInstructionsUIStore: EquipmentOperatorWorkInstructionsUIStore
  equipmentPlanningEquipmentsUIStore: EquipmentPlanningEquipmentsUIStore
  equipmentPlanningRailEquipmentsUIStore: EquipmentPlanningRailEquipmentsUIStore
  equipmentPlanningInfoUIStore: EquipmentPlanningInfoUIStore
  equipmentPlanningUIStore: EquipmentPlanningUIStore
  equipmentPlanningRailUIStore: EquipmentPlanningRailUIStore
  equipmentDialogUIStore: EquipmentDialogUIStore
  equipmentFiltersUIStore: EquipmentFiltersUIStore
  equipmentsUIStore: EquipmentsUIStore
  equipmentsVesselVisitUIStore: EquipmentsVesselVisitUIStore
  workInstructionUIStore: WorkInstructionUIStore
  workInstructionFiltersUIStore: WorkInstructionFiltersUIStore
  vesselVisitTableUIStore: VesselVisitTableUIStore
  tallymanOperationsUIStore: TallymanOperationsUIStore
  craneOperatorLandingContainerUIStore: CraneOperatorLandingContainerUIStore
  craneOperatorUIStore: CraneOperatorUIStore
  craneOperatorNoGeometryUIStore: CraneOperatorNoGeometryUIStore
  reeferMonitoringUIStore: ReeferMonitoringUIStore
  reeferMonitoringFiltersUIStore: ReeferMonitoringFiltersUIStore
  reeferMonitoringDrawerUIStore: ReeferMonitoringDrawerUIStore
  jobMonitoringDrawerUIStore: JobMonitoringDrawerUIStore
  coolingOrdersUIStore: CoolingOrdersUIStore
}

const workInstructionFiltersUIStore = new WorkInstructionFiltersUIStore(
  equipmentStore,
  workInstructionStore,
)
const workInstructionUIStore = new WorkInstructionUIStore(
  messageBus,
  workInstructionStore,
  workInstructionFiltersUIStore,
  appStore,
)

const craneSplitContainerUIStore = new CraneSplitContainerUIStore(
  workQueueStore,
  workInstructionStore,
  equipmentStore,
  vesselVisitStore,
  containerStowageLocationStore,
  messageBus,
)
const craneSplitDialogUIStore = new CraneSplitDialogUIStore(
  workQueueStore,
  craneSplitContainerUIStore,
  workInstructionStore,
)
const craneSplitDrawerUIStore = new CraneSplitDrawerUIStore(
  workQueueStore,
  equipmentStore,
  workInstructionStore,
  craneSplitContainerUIStore,
)
const craneSplitMessageUIStore = new CraneSplitMessageUIStore()

const selectCranesDialogUIStore = new SelectCranesDialogUIStore(
  equipmentStore,
  vesselVisitStore,
  workQueueStore,
  workInstructionStore,
  containerStowageLocationStore,
)

const equipmentOperatorLandingUIStore = new EquipmentOperatorLandingUIStore(
  messageBus,
  appStore,
  equipmentStore,
  tenantConfigStore,
)
const equipmentOperatorNotificationUIStore = new EquipmentOperatorNotificationUIStore()
const equipmentOperatorSearchUIStore = new EquipmentOperatorSearchUIStore()
const equipmentOperatorUIStore = new EquipmentOperatorUIStore(
  jobStore,
  equipmentStore,
  workInstructionStore,
  equipmentOperatorSearchUIStore,
  equipmentOperatorLandingUIStore,
)
const equipmentOperatorWorkInstructionsUIStore = new EquipmentOperatorWorkInstructionsUIStore(
  workInstructionStore,
  equipmentOperatorSearchUIStore,
  equipmentOperatorLandingUIStore,
)
const equipmentOperatorSignalRUIStore = new EquipmentOperatorSignalRUIStore(
  messageBus,
  jobStore,
  appStore,
  equipmentOperatorNotificationUIStore,
  equipmentOperatorUIStore,
  equipmentOperatorWorkInstructionsUIStore,
)
const equipmentOperatorStepperUIStore = new EquipmentOperatorStepperUIStore(
  cargoUnitStore,
  equipmentStore,
  jobStore,
  yardBlockStore,
  appStore,
  tenantConfigStore,
  equipmentOperatorUIStore,
)
const equipmentOperatorWorkInstructionStepperUIStore =
  new EquipmentOperatorWorkInstructionStepperUIStore(workInstructionStore, appStore)

const equipmentPlanningEquipmentsUIStore = new EquipmentPlanningEquipmentsUIStore(
  equipmentStore,
  workAreaStore,
  vesselVisitStore,
  workQueueStore,
)
const equipmentPlanningRailEquipmentsUIStore = new EquipmentPlanningRailEquipmentsUIStore(
  equipmentStore,
  workAreaStore,
  railVisitStore,
)
const equipmentPlanningInfoUIStore = new EquipmentPlanningInfoUIStore(
  vesselVisitStore,
  workInstructionStore,
)
const equipmentPlanningUIStore = new EquipmentPlanningUIStore(
  equipmentStore,
  workAreaStore,
  vesselVisitStore,
  workInstructionStore,
  workQueueStore,
  containerStowageLocationStore,
  equipmentPlanningInfoUIStore,
  equipmentPlanningEquipmentsUIStore,
  craneSplitContainerUIStore,
  craneSplitDialogUIStore,
  craneSplitDrawerUIStore,
  craneSplitMessageUIStore,
  selectCranesDialogUIStore,
  workInstructionUIStore,
  workInstructionFiltersUIStore,
)

const equipmentPlanningRailUIStore = new EquipmentPlanningRailUIStore(
  equipmentStore,
  workAreaStore,
  workInstructionStore,
  railVisitStore,
  equipmentPlanningRailEquipmentsUIStore,
)
const equipmentDialogUIStore = new EquipmentDialogUIStore(equipmentStore)
const equipmentFiltersUIStore = new EquipmentFiltersUIStore(equipmentStore, yardBlockStore)
const equipmentsUIStore = new EquipmentsUIStore(equipmentStore, equipmentFiltersUIStore)

const equipmentsVesselVisitUIStore = new EquipmentsVesselVisitUIStore(equipmentStore)

const vesselVisitTableUIStore = new VesselVisitTableUIStore(vesselVisitStore)

const tallymanOperationsUIStore = new TallymanOperationsUIStore(equipmentStore)

const craneOperatorLandingContainerUIStore = new CraneOperatorLandingContainerUIStore(
  messageBus,
  appStore,
)

const craneOperatorUIStore = new CraneOperatorUIStore(messageBus, appStore)
const craneOperatorNoGeometryUIStore = new CraneOperatorNoGeometryUIStore(messageBus, appStore)

const reeferMonitoringFiltersUIStore = new ReeferMonitoringFiltersUIStore(reeferTemperatureStore)
const reeferMonitoringUIStore = new ReeferMonitoringUIStore(
  reeferTemperatureStore,
  reeferMonitoringFiltersUIStore,
)
const reeferMonitoringDrawerUIStore = new ReeferMonitoringDrawerUIStore(reeferTemperatureStore)

const jobMonitoringDrawerUIStore = new JobMonitoringDrawerUIStore(
  cargoUnitStore,
  equipmentStore,
  workInstructionStore,
  yardBlockStore,
)

const coolingOrdersUIStore = new CoolingOrdersUIStore(reeferTemperatureStore)

export interface IAppStore extends IStore, IViewStore {}

const store: IAppStore = {
  appStore,
  tenantConfigStore: tenantConfigStore,
  cargoUnitStore,
  equipmentStore,
  jobStore,
  vesselVisitStore,
  railVisitStore,
  workAreaStore,
  workInstructionStore,
  workQueueStore,
  yardBlockStore,
  authStore,
  workInstructionUIStore,
  workInstructionFiltersUIStore,
  equipmentOperatorStepperUIStore,
  equipmentOperatorSearchUIStore,
  craneSplitContainerUIStore,
  craneSplitDialogUIStore,
  craneSplitDrawerUIStore,
  craneSplitMessageUIStore,
  selectCranesDialogUIStore,
  equipmentOperatorUIStore,
  equipmentOperatorSignalRUIStore,
  equipmentOperatorNotificationUIStore,
  equipmentOperatorLandingUIStore,
  equipmentOperatorWorkInstructionsUIStore,
  equipmentOperatorWorkInstructionStepperUIStore,
  equipmentPlanningEquipmentsUIStore,
  equipmentPlanningRailEquipmentsUIStore,
  equipmentPlanningInfoUIStore,
  equipmentPlanningUIStore,
  equipmentPlanningRailUIStore,
  equipmentDialogUIStore,
  equipmentFiltersUIStore,
  equipmentsUIStore,
  equipmentsVesselVisitUIStore,
  vesselVisitTableUIStore,
  tallymanOperationsUIStore,
  containerStowageLocationStore,
  craneOperatorLandingContainerUIStore,
  craneOperatorUIStore,
  craneOperatorNoGeometryUIStore,
  reeferTemperatureStore,
  reeferMonitoringFiltersUIStore,
  reeferMonitoringUIStore,
  reeferMonitoringDrawerUIStore,
  dataSnapshotStore,
  jobMonitoringDrawerUIStore,
  coolingOrdersUIStore,
}

setupValidation()
setupInterceptors(appStore)

const OperationsContext = createContext<IAppStore>({ ...store })

export const OperationsStoreProvider = ({ children }: { children: ReactNode }) => {
  return <OperationsContext.Provider value={store}>{children}</OperationsContext.Provider>
}

export const useOperationsStore = () => useContext(OperationsContext)
