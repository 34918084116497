import { Box, Stack, Typography } from '@mui/material'
import {
  CarrierVisitDirection,
  ContainerSection,
  StackDto,
  YardBlockBayDto,
  YardBlockDto,
  YardBlockRowDto,
} from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import YardPlanningDashboardStacks from './YardPlanningDashboardStacks'

interface YardPlanningDashboardBlockProps {
  yardBlock: YardBlockDto
  rows: YardBlockRowDto[]
  bays: YardBlockBayDto[]
  stacks: StackDto[]
  availableSpace: number
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
}

const YardPlanningDashboardBlock = observer(
  ({
    yardBlock,
    rows,
    bays,
    stacks,
    carrierVisitId,
    availableSpace,
    carrierVisitDirection,
  }: YardPlanningDashboardBlockProps) => {
    const { t } = useTranslate()

    const stackWidthPixels = '70px'
    const rowLabelPixels = '30px'

    const sortedRows = useMemo(() => {
      return rows.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    }, [rows])

    const sortedBays = useMemo(() => {
      return bays.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    }, [bays])

    const getStacksForRow = useCallback(
      (rowId: string) => {
        return stacks
          .filter(stack => stack.rowId === rowId && stack.section === ContainerSection.Head)
          .sort((a, b) => a.baySequenceNumber - b.baySequenceNumber)
      },
      [stacks],
    )

    return (
      <Stack gap={1}>
        <Stack flexDirection='row' marginLeft={rowLabelPixels} gap={1} alignItems='center'>
          <Typography variant='h4'>{yardBlock.name}</Typography>
          {availableSpace > 0 ? (
            <>
              <Typography variant='body1'>{t('freeSpace', 'Free space')}</Typography>|
              <Typography variant='body1'>20ft: {availableSpace}</Typography>
            </>
          ) : (
            <Typography>{t('fullyOccupied', 'Fully occupied')}</Typography>
          )}
        </Stack>

        <Stack>
          {sortedRows.map(row => (
            <Stack key={`row_${row.id}`} flexDirection='row' height={'24px'}>
              <Typography width={rowLabelPixels} variant='subtitle2' flex='none'>
                {row.name}
              </Typography>

              <YardPlanningDashboardStacks
                stackWidthPixels={stackWidthPixels}
                stacks={getStacksForRow(row.id)}
                maxTier={yardBlock.maxTier}
                bayCount={bays.length}
                carrierVisitId={carrierVisitId}
                carrierVisitDirection={carrierVisitDirection}
              />
            </Stack>
          ))}
        </Stack>

        <Stack flexDirection='row'>
          <Box width={rowLabelPixels} flex='none'></Box>
          {sortedBays.map(bay => (
            <Typography
              key={`bay_${bay.id}`}
              width={stackWidthPixels}
              textAlign='center'
              variant='subtitle2'
              flex='none'
            >
              {bay.name}
            </Typography>
          ))}
        </Stack>
      </Stack>
    )
  },
)

export default YardPlanningDashboardBlock
