import {
  CarrierType,
  IssueStatus,
  OrderIssueResolutionType,
  RailVisitResponseDto,
  VesselVisitDto,
} from '@planning/app/api'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { IIssueItem } from './IssueItem'
import { IssueItemStore } from './IssueItemStore'

export type IssuesTypeCount = {
  all: number
  unresolved: number
  followUp: number
}

export interface IssueTransportData {
  carrierType?: CarrierType
  carrierVisitId?: number
  railTrackId?: string
  railcarId?: number
  releaseExpired?: string
}

// [railcarMissingIssue] TODO: UT this store - filtering, fetching
export class IssuesViewStore {
  showErrors = false
  issueFilterType?: IssueStatus
  issueToBeResolved?: IIssueItem
  carrierNameFilter? = ''
  containerNumberFilter? = ''
  isFilterEnabled = false

  selectedIssueResolution?: OrderIssueResolutionType

  isDialogOpen = false
  selectedRailVisit?: RailVisitResponseDto
  selectedVesselVisit?: VesselVisitDto

  filterByVisitId?: number
  filterByRailcarTrackPositionId?: number

  transportData: IssueTransportData = {}

  loadContainerJourneyDataReaction = false

  constructor(
    public issueItemStore: IssueItemStore,
    public containerJourneyDataStore: ContainerJourneyDataStore,
  ) {
    makeObservable(this, {
      showErrors: observable,
      issueFilterType: observable,
      carrierNameFilter: observable,
      containerNumberFilter: observable,
      isFilterEnabled: observable,
      isDialogOpen: observable,
      issueToBeResolved: observable,
      filterByVisitId: observable,
      filterByRailcarTrackPositionId: observable,
      selectedIssueResolution: observable,
      transportData: observable,
      loadContainerJourneyDataReaction: observable,

      filteredData: computed,
      filteredCarrierData: computed,
      issuesTypeCount: computed,

      updateTransportData: action,
      setSelectedIssueResolution: action,
      setIssueToBeResolved: action,
      toggleResolveDialog: action,
      setContainerNumberFilter: action,
      setIssueFilterType: action,
      setCarrierNameFilter: action,
      enableFilters: action,
      setFilterByVisitId: action,
      setFilterByRailCarTrackPositionId: action,
      setShowErrors: action,
      setLoadContainerJourneyDataReaction: action,
    })

    reaction(
      () => {
        return {
          loadJourneyData: this.loadContainerJourneyDataReaction,
          ids: this.GetIssueOrderIds(),
        }
      },
      react => {
        if (react.loadJourneyData && react.ids) {
          this.containerJourneyDataStore.fetchByOrderIds(react.ids)
        }
      },
    )
  }

  GetIssueOrderIds(): number[] {
    return this.issueItemStore.notResolvedItemsWithObjectTypeOrder.map(i => i.data.objectId)
  }

  updateTransportData<K extends keyof typeof this.transportData>(
    field: K,
    value: (typeof this.transportData)[K],
  ) {
    this.transportData[field] = value
  }

  setSelectedIssueResolution(resolution: OrderIssueResolutionType) {
    this.selectedIssueResolution = resolution
  }

  setIssueToBeResolved(issue?: IIssueItem) {
    this.issueToBeResolved = issue
  }

  toggleResolveDialog(isOpen: boolean) {
    this.isDialogOpen = isOpen
  }

  setContainerNumberFilter(filter: string) {
    this.containerNumberFilter = filter
  }

  setCarrierNameFilter(filter?: string) {
    this.carrierNameFilter = filter
  }

  enableFilters(apply: boolean) {
    this.isFilterEnabled = apply
  }

  fetchIssues = async () => {
    await this.issueItemStore.fetch()
  }

  setIssueFilterType(issueFilterType?: IssueStatus) {
    if (this.issueFilterType !== issueFilterType) {
      this.issueFilterType = issueFilterType
    }
  }

  setFilterByVisitId(visitId?: number) {
    this.filterByVisitId = visitId
  }

  setFilterByRailCarTrackPositionId(railCarTrackPositionId?: number) {
    this.filterByRailcarTrackPositionId = railCarTrackPositionId
  }

  setShowErrors(showErrors: boolean) {
    this.showErrors = showErrors
  }

  setLoadContainerJourneyDataReaction(value: boolean) {
    this.loadContainerJourneyDataReaction = value
  }

  get issuesTypeCount(): IssuesTypeCount {
    const items = this.filteredData
    let unresolved = 0
    let followUp = 0

    items.forEach(item => {
      if (item.data.issueStatus === IssueStatus.Unresolved) {
        unresolved++
      } else if (item.data.issueStatus === IssueStatus.FollowUp) {
        followUp++
      }
    })

    return {
      all: items.length,
      unresolved: unresolved,
      followUp: followUp,
    }
  }

  get isValidated(): boolean {
    if (
      !this.selectedIssueResolution &&
      this.issueToBeResolved?.data.issueStatus !== IssueStatus.FollowUp
    ) {
      return false
    }
    if (this.selectedIssueResolution === OrderIssueResolutionType.ChangeOutboundTransport) {
      if (!this.transportData?.carrierType) return false
      if (
        this.transportData?.carrierType === CarrierType.Vessel &&
        !this.transportData.carrierVisitId
      )
        return false

      if (
        this.transportData?.carrierType === CarrierType.Train &&
        (!this.transportData.carrierVisitId ||
          !this.transportData.railTrackId ||
          !this.transportData.railcarId)
      )
        return false
    }
    return true
  }

  get filteredData(): IIssueItem[] {
    const data = this.filteredCarrierData

    let filteredData = this.issueFilterType
      ? data.filter(i => i.data.issueStatus === this.issueFilterType)
      : data

    // Filter by container number
    if (this.containerNumberFilter) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.containerNumber?.includes(
            this.containerNumberFilter!,
          ) ||
            containerJourney.outboundOrder?.order?.containerNumber?.includes(
              this.containerNumberFilter!,
            ))
        )
      })
    }

    // Filter by carrier name
    if (this.carrierNameFilter && this.isFilterEnabled) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i
        const filter = this.carrierNameFilter!.toLocaleUpperCase()

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.visit?.identifier?.toLocaleUpperCase().includes(filter) ||
            containerJourney.outboundOrder?.visit?.identifier?.toLocaleUpperCase().includes(filter))
        )
      })
    }

    return filteredData
  }

  get filteredCarrierData() {
    let data = this.issueItemStore.notResolvedItemsWithObjectTypeOrder

    // Filter by VisitId
    if (this.filterByVisitId) {
      data = data.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.visit?.id === this.filterByVisitId ||
            containerJourney.outboundOrder?.visit?.id === this.filterByVisitId)
        )
      })
    }

    // Filter by RailCarTrackPositionId
    if (this.filterByRailcarTrackPositionId) {
      data = data.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.railcarTrackPositionId ===
            this.filterByRailcarTrackPositionId ||
            containerJourney.outboundOrder?.order?.railcarTrackPositionId ===
              this.filterByRailcarTrackPositionId)
        )
      })
    }

    return data
  }
}
