import React, { useMemo } from 'react'
import { alpha, Box } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ValidStackDto } from './YardPlanningDashboardStacks'
import { isStackHighlighted } from '../utils/yard-planning-dashboard-stack.util'
import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'

interface StackHighlightLayerProps {
  stack: ValidStackDto
}

const StackHighlightLayer: React.FC<StackHighlightLayerProps> = observer(({ stack }) => {
  const { palette } = useMinimalsTheme()
  const { carrierVisitAllocationRulesV2UIStore } = useStores()

  const { isAllocated, isSelected } = useMemo(() => {
    const selected =
      carrierVisitAllocationRulesV2UIStore.selectedAllocationRule &&
      isStackHighlighted(stack, carrierVisitAllocationRulesV2UIStore.selectedAllocationRule)

    const allocated =
      selected ||
      carrierVisitAllocationRulesV2UIStore.allocationRules.some(rule =>
        isStackHighlighted(stack, rule),
      )

    return { isAllocated: allocated, isSelected: selected }
  }, [
    carrierVisitAllocationRulesV2UIStore.allocationRules,
    carrierVisitAllocationRulesV2UIStore.selectedAllocationRule,
    stack,
  ])

  if (!isAllocated) return null

  return (
    <Box
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}
      sx={{
        backgroundColor: isSelected
          ? alpha(palette.info.main, 0.6)
          : alpha(palette.info.light, 0.4),
      }}
    />
  )
})

export default React.memo(StackHighlightLayer)
