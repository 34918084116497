import { Height, Thermostat, Warning } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { AllocationRuleTemplateDtoFacets } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

interface AllocationRuleTemplateFacetChipsProps {
  facets: AllocationRuleTemplateDtoFacets
}

const AllocationRuleTemplateFacetChips = ({ facets }: AllocationRuleTemplateFacetChipsProps) => {
  const { t } = useTranslate()
  const facetChipElements = useMemo(() => {
    const facetChips = []

    if (facets.size) facetChips.push(<Chip key='size' label={`${facets.size}′`} />)

    if (facets.isEmpty !== null && facets.isEmpty !== undefined)
      facetChips.push(
        <Chip key='isEmpty' label={facets.isEmpty ? t('empty', 'Empty') : t('full', 'Full')} />,
      )

    if (facets.isReefer !== null && facets.isReefer !== undefined)
      facetChips.push(
        <Chip
          key='isReefer'
          icon={<Thermostat fontSize='small' />}
          label={facets.isReefer ? t('reefer', 'Reefer') : t('nonReefer', 'Non Reefer')}
        />,
      )

    if (facets.isDangerous !== null && facets.isDangerous !== undefined)
      facetChips.push(
        <Chip
          key='isDangerous'
          icon={<Warning fontSize='small' />}
          label={
            facets.isDangerous ? t('dangerous', 'Dangerous') : t('nonDangerous', 'Non Dangerous')
          }
        />,
      )
    if (facets.containerOperator)
      facetChips.push(<Chip key='containerOperator' label={facets.containerOperator} />)

    if (facets.containerHeight)
      facetChips.push(
        <Chip
          icon={<Height fontSize='small' />}
          key='containerHeight'
          label={facets.containerHeight}
        />,
      )

    if (facets.containerType)
      facetChips.push(<Chip key='containerType' label={facets.containerType} />)

    if (facets.weightClasses?.length)
      facetChips.push(
        <Chip key='weightClasses' label={facets.weightClasses.map(wc => wc).join(', ')} />,
      )

    if (facets.consignee) facetChips.push(<Chip key='consignee' label={facets.consignee} />)

    if (facets.portOfDischarge)
      facetChips.push(<Chip key='portOfDischarge' label={facets.portOfDischarge} />)

    if (facets.outboundCarrierType)
      facetChips.push(
        <Chip
          key='outboundCarrierType'
          label={`${t('outboundCarrier', 'Outbound carrier')}: ${t(facets.outboundCarrierType)}`}
        />,
      )

    return facetChips
  }, [facets, t])

  return facetChipElements
}

export default AllocationRuleTemplateFacetChips
