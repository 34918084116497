import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { AuthStore, authStore as authStoreObject } from '@tom-ui/utils'
import _ from 'lodash'
import { ReactNode, createContext, useContext } from 'react'
import { CarrierType, GetTruckAppointmentsQueryType } from './app/api'
import { setupInterceptors } from './app/http-client'
import {
  containerEventsFilterDelegate,
  containerEventsSortDelegate,
} from './components/ContainerHistoryLinkIconButton'
import { GetVesselVisitsQuery, MessageBus, getVesselVisitsQueryHandler } from './messages'
import { EventTypes } from './messages/eventsTypes'
import { HubContext } from './messages/hubConnection'
import {
  GetBerthsQuery,
  GetContainerByIdQuery,
  GetContainerEventsQuery,
  GetContainersByVisitIdQuery,
  GetDeliveryOrdersQuery,
  GetNnrOrderByIdQuery,
  GetNnrOrdersByVisitIdQuery,
  GetNnrOrdersQuery,
  GetOrderByIdQuery,
  GetOrdersByIdsQuery,
  GetOrdersByNnrOrderIdQuery,
  GetOrdersByVisitIdQuery,
  GetRailTracksQuery,
  GetTenantQuery,
  GetTruckVisitsByIdsQuery,
  GetTruckVisitsQuery,
  GetTrucksQuery,
  GetVesselVisitByIdQuery,
  GetVesselVisitsByIdsQuery,
  GetVesselsByIdsQuery,
  GetVesselsQuery,
  getBerthsQueryHandler,
  getContainerByIdQueryHandler,
  getContainerEventsQueryHandler,
  getContainersByVisitIdQueryHandler,
  getDeliveryOrdersQueryHandler,
  getNnrOrderByIdQueryHandler,
  getNnrOrdersByVisitIdQueryHandler,
  getNnrOrdersQueryHandler,
  getOrderByIdQueryHandler,
  getOrdersByIdsQueryHandler,
  getOrdersByNnrOrderIdQueryHandler,
  getOrdersByVisitIdQueryHandler,
  getRailTracksQueryHandler,
  getTenantQueryHandler,
  getTruckVisitsByIdsQueryHandler,
  getTruckVisitsQueryHandler,
  getTrucksQueryHandler,
  getVesselVisitByIdQueryHandler,
  getVesselVisitsByIdsQueryHandler,
  getVesselsByIdsQueryHandler,
  getVesselsQueryHandler,
} from './messages/queries'
import { GetIssuesQuery, getIssuesQueryHandler } from './messages/queries/getIssuesQueryHandler'
import {
  GetOrdersByContainerNumberQuery,
  getOrdersByContainerNumberQueryHandler,
} from './messages/queries/getOrdersByContainerNumberQueryHandler'
import {
  GetRailVisitByIdQuery,
  getRailVisitByIdQueryHandler,
} from './messages/queries/getRailVisitByIdQueryHandler'
import {
  GetRailVisitsByIdsQuery,
  getRailVisitsByIdsQueryHandler,
} from './messages/queries/getRailVisitsByIdsQueryHandler'
import {
  GetRailVisitsQuery,
  getRailVisitsQueryHandler,
} from './messages/queries/getRailVisitsQueryHandler'
import {
  GetRailcarTrackPositionsQuery,
  getRailcarTrackPositionsQueryHandler,
} from './messages/queries/getRailcarTrackPositionsQueryHandler'
import {
  GetRailcarsQuery,
  getRailcarsQueryHandler,
} from './messages/queries/getRailcarsQueryHandler'
import {
  GetReleaseOrdersQuery,
  getReleaseOrdersQueryHandler,
} from './messages/queries/getReleaseOrdersQueryHandler'
import {
  deliveryOrdersFilterDelegate,
  deliveryOrdersSortingDelegate,
} from './pages/DeliveryOrders/DeliveryOrderPage'
import { IssueItemStore } from './pages/Issues/Stores/IssueItemStore'
import { IssuesViewStore } from './pages/Issues/Stores/IssuesViewStore'
import { nnrOrdersFilterDelegate, nnrOrdersSortingDelegate } from './pages/NNROrders/NNROrdersPage'
import { ContainerAutocompleteStore } from './pages/Order/stores/ContainerAutocompleteStore'
import { IsoCodeMappingInputStore } from './pages/Order/stores/IsoCodeMappingInputStore'
import { OrderListStore } from './pages/Order/stores/OrderListStore'
import { OrderListUploadViewStoreV2 } from './pages/Order/stores/OrderListUploadViewStoreV2'
import { SelectOrderViewStore } from './pages/Order/stores/SelectOrderViewStore'
import { railVisitFilterDelegate, railVisitSortingDelegate } from './pages/RailVisit/RailVisitHome'
import {
  releaseOrdersFilterDelegate,
  releaseOrdersSortingDelegate,
} from './pages/ReleaseOrders/ReleaseOrdersPage'
import { ContainerJourneyDataStore } from './pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { ServiceOrdersListViewStore } from './pages/ServiceOrders/Stores/ServiceOrdersListViewStore'
import { ServiceOrdersViewStore } from './pages/ServiceOrders/Stores/ServiceOrdersViewStore'
import { vesselsFilterDelegate, vesselsSortingDelegate } from './pages/Vessel/VesselPage'
import {
  vesselVisitFilterDelegate,
  vesselVisitSortingDelegate,
} from './pages/VesselVisit/VesselVisitsPage'
import { DataManager } from './rt-stores/DataManager'
import { BerthItemStore } from './rt-stores/berth/BerthItemStore'
import { IContainerEventItem } from './rt-stores/container/ContainerEventItem'
import { ContainerEventItemStore } from './rt-stores/container/ContainerEventItemStore'
import { ContainerItemStore } from './rt-stores/container/ContainerItemStore'
import { GateClerkTruckVisitEditStore } from './rt-stores/gateClerk/GateClerkTruckVisitEditStore'
import { INnrOrderItem } from './rt-stores/nnrOrder/NnrOrderItem'
import { NnrOrderItemStore } from './rt-stores/nnrOrder/NnrOrderItemStore'
import { IOrderItem } from './rt-stores/order/OrderItem'
import { OrderItemStore } from './rt-stores/order/OrderItemStore'
import { RailTrackItemStore } from './rt-stores/railTrack/RailTrackItemStore'
import { RailcarTrackPositionItemStore } from './rt-stores/railTrack/RailcarTrackPositionItemStore'
import { IRailVisitItem } from './rt-stores/railVisit/RailVisitItem'
import { RailVisitItemStore } from './rt-stores/railVisit/RailVisitItemStore'
import { RailVisitPlanningStore } from './rt-stores/railVisit/RailVisitPlanningStore'
import { RailcarItemStore } from './rt-stores/railcar/RailcarItemStore'
import { IReleaseOrderItem } from './rt-stores/releaseOrder/ReleaseOrderItem'
import { ReleaseOrderItemStore } from './rt-stores/releaseOrder/ReleaseOrderItemStore'
import { TallymanV2ViewStore } from './rt-stores/tallyman/TallymanV2ViewStore'
import { TenantStore } from './rt-stores/tenant/TenantStore'
import { TruckItemStore } from './rt-stores/truck/TruckItemStore'
import { TruckVisitItemStore } from './rt-stores/truckVisit/TruckVisitItemStore'
import { IVesselItem } from './rt-stores/vessel/VesselItem'
import { VesselItemStore } from './rt-stores/vessel/VesselItemStore'
import { IVesselVisitItem } from './rt-stores/vesselVisit/VesselVisitItem'
import { VesselVisitItemStore } from './rt-stores/vesselVisit/VesselVisitItemStore'
import { VesselVisitPlanningStore } from './rt-stores/vesselVisit/VesselVisitPlanningStore'
import { VisitTableViewStore } from './rt-stores/visit/VisitTableViewStore'
import {
  VisitItem,
  VisitViewStore,
  visitFilterDelegate,
  visitSortingDelegate,
} from './rt-stores/visit/VisitViewStore'
import { EntityQueryService } from './services/EntityQueryService'
import { AppViewStore } from './stores/AppViewStore'
import { DialogStore } from './stores/DialogStore'
import { DrawerStore } from './stores/DrawerStore'
import { LocalDataStoreWrapper, PaginatedLocalStore } from './stores/PaginatedLocalStore'
import { ContainerDamageReportViewStore } from './stores/damageReport/ContainerDamageReportViewStore'
import {
  DangerousGoodsDefinitionDataStore,
  createDangerousGoodsDefinitionDataStore,
} from './stores/dangerousGoodsDefinition/DangerousGoodsDefinitionDataStore'
import { DangerousGoodsDefinitionTableViewStore } from './stores/dangerousGoodsDefinition/DangerousGoodsDefinitionTableViewStore'
import { GateClerkViewStore, IGateClerkViewStore } from './stores/gateClerk/GateClerkViewStore'
import { GateInViewStore } from './stores/gateControl/GateInViewStore'
import { GeneralCargoViewStore } from './stores/generalCargo/GeneralCargoViewStore'
import { HoldViewStore } from './stores/hold/HoldViewStore'
import { ReportIssueViewStore } from './stores/issues/ReportIssueViewStore'
import { NNROrderViewStore, createNNROrderViewStore } from './stores/order/NNROrderViewStore'
import {
  TruckAppointmentOrderStore,
  createTruckAppointmentOrderViewStore,
} from './stores/truckAppointment/TruckAppointmentOrderViewStore'
import {
  TruckAppointmentViewStore,
  createTruckAppointmentViewStore,
} from './stores/truckAppointment/TruckAppointmentViewStore'
import { FormDialogUtilStore } from './stores/utils/FormDialogUtilStore'

type IStore = {
  authStore: AuthStore
  gateClerkTruckVisitEditStore: GateClerkTruckVisitEditStore
  tallymanSelectVesselAndOperationViewStore: TallymanV2ViewStore
  truckVisitQueryService: EntityQueryService
  vesselVisitQueryService: EntityQueryService
  railVisitQueryService: EntityQueryService
  tenantStore: TenantStore
}

type IItemStore = {
  berthItemStore: BerthItemStore
  containerItemStore: ContainerItemStore
  containerEventItemStore: ContainerEventItemStore
  nnrOrderItemStore: NnrOrderItemStore
  orderItemStore: OrderItemStore
  truckItemStore: TruckItemStore
  truckVisitItemStore: TruckVisitItemStore
  vesselItemStore: VesselItemStore
  vesselVisitItemStore: VesselVisitItemStore
  railcarItemStore: RailcarItemStore
  railVisitItemStore: RailVisitItemStore
  railTrackItemStore: RailTrackItemStore
  railcarTrackPositionItemStore: RailcarTrackPositionItemStore
  issueItemStore: IssueItemStore
}

type IViewStore = {
  containerAutocompleteStore: ContainerAutocompleteStore
  containerEventViewStore: IPaginatedStoreWithItems<IContainerEventItem>
  deliveryOrderViewStore: IPaginatedStoreWithItems<IOrderItem>
  gateClerkNnrOrderViewStore: NNROrderViewStore
  holdViewStore: HoldViewStore
  nnrOrderViewStore: IPaginatedStoreWithItems<INnrOrderItem>
  orderListStore: OrderListStore
  orderListUploadViewStoreV2: OrderListUploadViewStoreV2
  releaseOrderViewStore: IPaginatedStoreWithItems<IReleaseOrderItem>
  truckAppointmentOrderStore: TruckAppointmentOrderStore
  truckAppointmentViewStore: TruckAppointmentViewStore
  vesselViewStore: IPaginatedStoreWithItems<IVesselItem>
  vesselVisitListViewStore: IPaginatedStoreWithItems<IVesselVisitItem>
  railVisitListViewStore: IPaginatedStoreWithItems<IRailVisitItem>
  vesselVisitPlanningStore: VesselVisitPlanningStore
  visitViewStore: VisitViewStore
  visitListViewStore: IPaginatedStoreWithItems<VisitItem>
  visitTableViewStore: VisitTableViewStore
  gateClerkViewStore: IGateClerkViewStore
  selectOrderViewStore: SelectOrderViewStore
  gateInViewStore: GateInViewStore
  generalCargoViewStore: GeneralCargoViewStore
  serviceOrdersViewStore: ServiceOrdersViewStore
  serviceOrdersListViewStore: ServiceOrdersListViewStore
  formDialogUtilStore: FormDialogUtilStore
  dangerousGoodsDefinitionDataStore: DangerousGoodsDefinitionDataStore
  dangerousGoodsDefinitionTableViewStore: DangerousGoodsDefinitionTableViewStore
  containerDamageReportViewStore: ContainerDamageReportViewStore
  railVisitPlanningStore: RailVisitPlanningStore
  issuesViewStore: IssuesViewStore
  isoCodeMappingInputStore: IsoCodeMappingInputStore
  reportIssueViewStore: ReportIssueViewStore
}

const authStore = authStoreObject

export const messageBus = new MessageBus()
messageBus.subscribeQuery(GetBerthsQuery.type, getBerthsQueryHandler)
messageBus.subscribeQuery(GetContainerByIdQuery.type, getContainerByIdQueryHandler)
messageBus.subscribeQuery(GetContainerEventsQuery.type, getContainerEventsQueryHandler)
messageBus.subscribeQuery(GetContainersByVisitIdQuery.type, getContainersByVisitIdQueryHandler)
messageBus.subscribeQuery(GetDeliveryOrdersQuery.type, getDeliveryOrdersQueryHandler)
messageBus.subscribeQuery(GetNnrOrderByIdQuery.type, getNnrOrderByIdQueryHandler)
messageBus.subscribeQuery(GetNnrOrdersByVisitIdQuery.type, getNnrOrdersByVisitIdQueryHandler)
messageBus.subscribeQuery(GetNnrOrdersQuery.type, getNnrOrdersQueryHandler)
messageBus.subscribeQuery(GetOrderByIdQuery.type, getOrderByIdQueryHandler)
messageBus.subscribeQuery(GetOrdersByIdsQuery.type, getOrdersByIdsQueryHandler)
messageBus.subscribeQuery(
  GetOrdersByContainerNumberQuery.type,
  getOrdersByContainerNumberQueryHandler,
)
messageBus.subscribeQuery(GetOrdersByNnrOrderIdQuery.type, getOrdersByNnrOrderIdQueryHandler)
messageBus.subscribeQuery(GetOrdersByVisitIdQuery.type, getOrdersByVisitIdQueryHandler)
messageBus.subscribeQuery(GetReleaseOrdersQuery.type, getReleaseOrdersQueryHandler)
messageBus.subscribeQuery(GetTenantQuery.type, getTenantQueryHandler)
messageBus.subscribeQuery(GetTrucksQuery.type, getTrucksQueryHandler)
messageBus.subscribeQuery(GetTruckVisitsByIdsQuery.type, getTruckVisitsByIdsQueryHandler)
messageBus.subscribeQuery(GetTruckVisitsQuery.type, getTruckVisitsQueryHandler)
messageBus.subscribeQuery(GetVesselsByIdsQuery.type, getVesselsByIdsQueryHandler)
messageBus.subscribeQuery(GetVesselsQuery.type, getVesselsQueryHandler)
messageBus.subscribeQuery(GetVesselVisitByIdQuery.type, getVesselVisitByIdQueryHandler)
messageBus.subscribeQuery(GetVesselVisitsByIdsQuery.type, getVesselVisitsByIdsQueryHandler)
messageBus.subscribeQuery(GetVesselVisitsQuery.type, getVesselVisitsQueryHandler)
messageBus.subscribeQuery(GetRailVisitsQuery.type, getRailVisitsQueryHandler)
messageBus.subscribeQuery(GetRailVisitByIdQuery.type, getRailVisitByIdQueryHandler)
messageBus.subscribeQuery(GetRailVisitsByIdsQuery.type, getRailVisitsByIdsQueryHandler)
messageBus.subscribeQuery(GetRailTracksQuery.type, getRailTracksQueryHandler)
messageBus.subscribeQuery(GetRailcarTrackPositionsQuery.type, getRailcarTrackPositionsQueryHandler)
messageBus.subscribeQuery(GetRailcarsQuery.type, getRailcarsQueryHandler)
messageBus.subscribeQuery(GetIssuesQuery.type, getIssuesQueryHandler)

export const hubContext = new HubContext(messageBus)
const listenedEvents: string[] = [
  EventTypes.BerthUpsertedEvent,
  EventTypes.ContainerEventsUpsertedEvent,
  EventTypes.ContainerUpsertedEvent,
  EventTypes.FeatureFlagDeleted,
  EventTypes.FeatureFlagUpserted,
  EventTypes.NonNumericOrderUpsertedEvent,
  EventTypes.OrdersDeletedEvent,
  EventTypes.OrdersUpsertedEvent,
  EventTypes.ReleaseOrdersUpsertedEvent,
  EventTypes.TenantUpsertedEvent,
  EventTypes.TruckDeletedEvent,
  EventTypes.TruckUpsertedEvent,
  EventTypes.TruckVisitUpsertedEvent,
  EventTypes.TruckVisitDeletedEvent,
  EventTypes.VesselDeletedEvent,
  EventTypes.VesselUpsertedEvent,
  EventTypes.VesselVisitCreated,
  EventTypes.CarrierVisitDeleted,
  EventTypes.VesselVisitUpdated,
  EventTypes.CarrierVisitConflictUpdated,
  EventTypes.RailVisitCreated,
  EventTypes.RailVisitUpdated,
  EventTypes.RailVisitDeleted,
  EventTypes.PackageUpserted,
  EventTypes.PackageDeleted,
  EventTypes.RailTrackUpsertedEvent,
  EventTypes.RailTrackDeletedEvent,
  EventTypes.RailcarTrackPositionUpsertedEvent,
  EventTypes.RailcarTrackPositionDeletedEvent,
  EventTypes.RailcarUpsertedEvent,
  EventTypes.RailcarDeletedEvent,
  EventTypes.IssueUpsertedEvent,
]
listenedEvents.forEach(c => hubContext.registerPassThroughConnection(c))
hubContext.startConnection()

const gateClerkNnrOrderViewStore = createNNROrderViewStore(CarrierType.Truck)
const truckAppointmentViewStore = createTruckAppointmentViewStore(GetTruckAppointmentsQueryType.All)
const truckAppointmentOrderStore = createTruckAppointmentOrderViewStore(truckAppointmentViewStore)

const vesselItemStore = new VesselItemStore(messageBus)

const truckItemStore = new TruckItemStore(messageBus)

const railcarItemStore = new RailcarItemStore(messageBus)

const gateClerkTruckVisitEditStore = new GateClerkTruckVisitEditStore(truckAppointmentOrderStore)

const vesselVisitItemStore = new VesselVisitItemStore(messageBus)
const railTrackItemStore = new RailTrackItemStore(messageBus)
const railcarTrackPositionItemStore = new RailcarTrackPositionItemStore(
  messageBus,
  railTrackItemStore,
  railcarItemStore,
)
const railVisitItemStore = new RailVisitItemStore(
  messageBus,
  railTrackItemStore,
  railcarTrackPositionItemStore,
)
const visitViewStore = new VisitViewStore(vesselVisitItemStore, railVisitItemStore)

const containerJourneyDataStore = new ContainerJourneyDataStore(messageBus)
const issueItemStore = new IssueItemStore(messageBus, containerJourneyDataStore)

const truckVisitItemStore = new TruckVisitItemStore(messageBus)
const orderItemStore = new OrderItemStore(messageBus, railcarTrackPositionItemStore, issueItemStore)
const containerItemStore = new ContainerItemStore(messageBus)
const containerEventItemStore = new ContainerEventItemStore(messageBus)
const berthItemStore = new BerthItemStore(messageBus)

const holdViewStore = new HoldViewStore(orderItemStore)

const orderListUploadViewStoreV2 = new OrderListUploadViewStoreV2(
  orderItemStore,
  vesselVisitItemStore,
  railVisitItemStore,
  railTrackItemStore,
)

const formDialogUtilStore = new FormDialogUtilStore()

const dangerousGoodsDefinitionDataStore = createDangerousGoodsDefinitionDataStore()

const dangerousGoodsDefinitionTableViewStore = new DangerousGoodsDefinitionTableViewStore(
  dangerousGoodsDefinitionDataStore,
  formDialogUtilStore,
)

railVisitItemStore.connect(orderItemStore)

vesselVisitItemStore.connect(vesselItemStore, berthItemStore, orderItemStore)

truckVisitItemStore.connect(orderItemStore, truckItemStore)

orderItemStore.connect(
  vesselVisitItemStore,
  railVisitItemStore,
  truckVisitItemStore,
  containerItemStore,
  railTrackItemStore,
)

const tallymanSelectVesselAndOperationViewStore = new TallymanV2ViewStore(
  railVisitItemStore,
  vesselVisitItemStore,
)

const nnrOrderItemStore = new NnrOrderItemStore(messageBus)
nnrOrderItemStore.connect(orderItemStore)

const tenantStore = new TenantStore(messageBus)

new DataManager(
  vesselVisitItemStore,
  railVisitItemStore,
  vesselItemStore,
  berthItemStore,
  railTrackItemStore,
  railcarTrackPositionItemStore,
  orderItemStore,
  truckVisitItemStore,
  truckItemStore,
  containerItemStore,
  nnrOrderItemStore,
  issueItemStore,
)

const vesselVisitQueryService = new EntityQueryService(
  messageBus,
  range => new GetVesselVisitsQuery(range.from, range.to),
  id => new GetVesselVisitByIdQuery(id),
)

const truckVisitQueryService = new EntityQueryService(
  messageBus,
  range => new GetTruckVisitsQuery(range.from, range.to),
)

const railVisitQueryService = new EntityQueryService(
  messageBus,
  range => new GetRailVisitsQuery(range.from, range.to, true),
  id => new GetRailVisitByIdQuery(id),
)

const vesselVisitListViewStore = new PaginatedLocalStore<IVesselVisitItem>(
  new LocalDataStoreWrapper(
    () => _.values(vesselVisitItemStore.elements),
    query => vesselVisitQueryService.fetch(query),
  ),
  vesselVisitSortingDelegate,
  vesselVisitFilterDelegate,
)

const railVisitListViewStore = new PaginatedLocalStore<IRailVisitItem>(
  new LocalDataStoreWrapper(
    () => _.values(railVisitItemStore.elements),
    query => railVisitQueryService.fetch(query),
  ),
  railVisitSortingDelegate,
  railVisitFilterDelegate,
)

const visitListViewStore = new PaginatedLocalStore<VisitItem>(
  new LocalDataStoreWrapper(
    () => _.values(visitViewStore.elements),
    async query => {
      await vesselVisitQueryService.fetch(query)
      await railVisitQueryService.fetch(query)
      return Promise.resolve()
    },
  ),
  visitSortingDelegate,
  visitFilterDelegate,
)

const vesselVisitPlanningStore = new VesselVisitPlanningStore()

const railVisitPlanningStore = new RailVisitPlanningStore()

const visitTableViewStore = new VisitTableViewStore(visitListViewStore)

const gateClerkViewStore = new GateClerkViewStore(tenantStore)

const gateInViewStore = new GateInViewStore()

const generalCargoViewStore = new GeneralCargoViewStore(messageBus)

const selectOrderViewStore = new SelectOrderViewStore(orderItemStore, containerJourneyDataStore)
const serviceOrdersViewStore = new ServiceOrdersViewStore(messageBus, containerJourneyDataStore)
const serviceOrdersListViewStore = new ServiceOrdersListViewStore(containerJourneyDataStore)
const issuesViewStore = new IssuesViewStore(issueItemStore, containerJourneyDataStore)
const containerDamageReportViewStore = new ContainerDamageReportViewStore()
const isoCodeMappingInputStore = new IsoCodeMappingInputStore()
const reportIssueViewStore = new ReportIssueViewStore()

let loadedNnrOrders = false
const nnrOrderViewStore = new PaginatedLocalStore<INnrOrderItem>(
  new LocalDataStoreWrapper(
    () => _.values(nnrOrderItemStore.elements),
    async () => {
      if (loadedNnrOrders) {
        return
      }
      messageBus.dispatchQuery(new GetNnrOrdersQuery(1, 10000))
      loadedNnrOrders = true
    },
  ),
  nnrOrdersSortingDelegate,
  nnrOrdersFilterDelegate,
)

let loadedDeliveryOrders = false
const deliveryOrderViewStore = new PaginatedLocalStore<IOrderItem>(
  new LocalDataStoreWrapper(
    () => _.values(orderItemStore.deliveryOrders),
    async () => {
      if (loadedDeliveryOrders) {
        return
      }
      messageBus.dispatchQuery(new GetDeliveryOrdersQuery(undefined, undefined, true))
      loadedDeliveryOrders = true
    },
  ),
  deliveryOrdersSortingDelegate,
  deliveryOrdersFilterDelegate,
)

const releaseOrderItemStore = new ReleaseOrderItemStore(messageBus)
let loadedReleaseOrders = false
const releaseOrderViewStore = new PaginatedLocalStore<IReleaseOrderItem>(
  new LocalDataStoreWrapper(
    () => _.values(releaseOrderItemStore.elements),
    async () => {
      if (loadedReleaseOrders) {
        return
      }
      messageBus.dispatchQuery(new GetReleaseOrdersQuery(1, 1000, undefined, undefined, true))
      loadedReleaseOrders = true
    },
  ),
  releaseOrdersSortingDelegate,
  releaseOrdersFilterDelegate,
)

const containerAutocompleteStore = new ContainerAutocompleteStore()

const orderListStore = new OrderListStore(orderItemStore, vesselItemStore, truckItemStore)

let loadedVessels = false
const vesselViewStore = new PaginatedLocalStore<IVesselItem>(
  new LocalDataStoreWrapper(
    () => _.values(vesselItemStore.elements),
    async () => {
      if (loadedVessels) {
        return
      }
      messageBus.dispatchQuery(new GetVesselsQuery(1, 10000))
      loadedVessels = true
    },
  ),
  vesselsSortingDelegate,
  vesselsFilterDelegate,
)

const containerEventViewStore = new PaginatedLocalStore<IContainerEventItem>(
  new LocalDataStoreWrapper(
    () => _.values(containerEventItemStore.containerEventsByContainerNumber),
    undefined,
  ),
  containerEventsSortDelegate,
  containerEventsFilterDelegate,
)

export interface IAppStore extends IStore, IItemStore, IViewStore {
  appViewStore: AppViewStore
  drawerStore: DrawerStore
  dialogStore: DialogStore
}
const appViewStore = new AppViewStore()
const drawerStore = new DrawerStore()
const dialogStore = new DialogStore()

const store: IAppStore = {
  appViewStore,
  authStore,
  berthItemStore,
  containerAutocompleteStore,
  containerEventItemStore,
  containerEventViewStore,
  containerItemStore,
  deliveryOrderViewStore,
  drawerStore,
  dialogStore,
  gateClerkNnrOrderViewStore,
  gateClerkTruckVisitEditStore,
  holdViewStore,
  nnrOrderItemStore,
  nnrOrderViewStore,
  orderItemStore,
  orderListStore,
  orderListUploadViewStoreV2,
  releaseOrderViewStore,
  tallymanSelectVesselAndOperationViewStore,
  truckAppointmentOrderStore,
  truckAppointmentViewStore,
  truckItemStore,
  truckVisitItemStore,
  truckVisitQueryService,
  vesselItemStore,
  vesselViewStore,
  vesselVisitItemStore,
  vesselVisitListViewStore,
  vesselVisitPlanningStore,
  railVisitQueryService,
  railVisitItemStore,
  railVisitListViewStore,
  railTrackItemStore,
  railcarItemStore,
  visitViewStore,
  visitListViewStore,
  visitTableViewStore,
  gateClerkViewStore,
  selectOrderViewStore,
  vesselVisitQueryService,
  tenantStore,
  gateInViewStore,
  generalCargoViewStore,
  serviceOrdersViewStore,
  serviceOrdersListViewStore,
  formDialogUtilStore,
  dangerousGoodsDefinitionDataStore,
  dangerousGoodsDefinitionTableViewStore,
  containerDamageReportViewStore,
  railVisitPlanningStore,
  railcarTrackPositionItemStore,
  issuesViewStore,
  issueItemStore,
  isoCodeMappingInputStore,
  reportIssueViewStore,
}
setupInterceptors(appViewStore)

const PlanningContext = createContext<IAppStore>({ ...store })

export const PlanningStoreProvider = ({ children }: { children: ReactNode }) => {
  return <PlanningContext.Provider value={store}>{children}</PlanningContext.Provider>
}

export const usePlanningStore = () => useContext(PlanningContext)
