export type AllSelectOption = 'All'
export type UnknownSelectOption = 'Unknown'
export const AllStringValue = 'All'
export const UnknownStringValue = 'Unknown'
export const UnknownNumberValue = -1

export enum BooleanSelectOption {
  True = 'True',
  False = 'False',
}

export type SelectValue = string | BooleanSelectOption

export interface SelectOption {
  label: string
  value: SelectValue
}

export interface WeightClassSelectOption extends SelectOption {
  minWeight: number
  maxWeight?: number | null
}
