import {
  CarrierVisitDirection,
  ContainerDamageResponseDto,
  CreateContainerOrderDto,
  CreateTruckAppointmentCommand,
  UpdateOrderCommand,
} from '@planning/app/api'
import { IInspectContainerFormData } from '@planning/pages/GateClerk/Components/InspectContainer'
import { IGateInFormData } from '@planning/pages/GateClerk/GateInForm'
import { IGateOutFormData } from '@planning/pages/GateClerk/GateOutForm'
import {
  containerService,
  orderService,
  truckAppointmentService,
  truckVisitService,
} from '@planning/services'
import { action, makeObservable, observable } from 'mobx'
import { CancellableTimeoutStore } from './CancellableTimeoutStore'

export class GatePassageNotificationStore extends CancellableTimeoutStore {
  licensePlate?: string
  direction?: CarrierVisitDirection
  isTruckAppointment?: boolean = false

  constructor(protected delay = 5000) {
    super(delay)
    makeObservable(this, {
      licensePlate: observable,
      direction: observable,
      isTruckAppointment: observable,
      setLicensePlate: action,
      setDirection: action,
      setIsTruckAppointment: action,
    })
  }

  setLicensePlate = (licensePlate?: string) => {
    this.licensePlate = licensePlate
  }

  setDirection = (direction?: CarrierVisitDirection) => {
    this.direction = direction
  }

  setIsTruckAppointment = (allowGateIn?: boolean) => {
    this.isTruckAppointment = allowGateIn
  }

  createGateInRequest = async (data: IGateInFormData, allowGateIn = true) => {
    this.setLicensePlate(data.truckPlate)
    this.setDirection(CarrierVisitDirection.Inbound)
    this.setIsTruckAppointment(!allowGateIn)

    this.createRequest(async () => {
      try {
        await this.gateInTruck(data, allowGateIn)
      } catch (error: any) {
        console.log('Error while gating in truck', error)

        this.setRequestErrorMessage('Failed to allow passage')
      }
    })
  }

  deleteTruckAppointment = async (truckVisitId: number) => {
    await truckAppointmentService.delete(truckVisitId)
  }

  mapDropOffOrderToCreateContainerOrderDto = (
    dropOff: IInspectContainerFormData,
  ): CreateContainerOrderDto => ({
    direction: CarrierVisitDirection.Inbound,
    containerIsoCode: dropOff.containerIsoCode,
    containerNumber: dropOff.containerNumber,
    containerAttributes: {
      length: dropOff.containerLength,
      height: dropOff.containerHeight,
      type: dropOff.containerType,
    },
    carrierVisitId: null,
    linkedOrderId: dropOff.id,
    containerMaxGross: dropOff.containerMaxGross,
    containerTare: dropOff.containerTare,
    grossWeight: dropOff.grossWeight,
    imoClasses: dropOff.imoClasses,
    isEmpty: dropOff.isEmpty,
    operator: dropOff.operator,
    referenceNumber: dropOff.referenceNumber,
    temperature: dropOff.temperature,
    isAccidentalDischarge: false,
    isToBeStoredInYard: true,
    isDeliveryByTruck: true,
    damages: dropOff.damages,
    hasSeals: dropOff.hasSeals,
    seals: dropOff.seals,
  })

  mapPickUpOrderToCreateContainerOrderDto = (
    pickUp: IInspectContainerFormData,
  ): CreateContainerOrderDto => ({
    direction: CarrierVisitDirection.Outbound,
    containerIsoCode: pickUp.containerIsoCode,
    containerNumber: pickUp.containerNumber,
    containerAttributes: {
      length: pickUp.containerLength,
      height: pickUp.containerHeight,
      type: pickUp.containerType,
    },
    carrierVisitId: null,
    linkedOrderId: pickUp.id,
    grossWeight: pickUp.grossWeight,
    containerMaxGross: pickUp.containerMaxGross,
    containerTare: pickUp.containerTare,
    imoClasses: pickUp.imoClasses,
    isEmpty: pickUp.isEmpty,
    operator: pickUp.operator,
    referenceNumber: pickUp.referenceNumber,
    temperature: pickUp.temperature,
    isAccidentalDischarge: false,
    isToBeStoredInYard: true,
    damages: pickUp.damages,
    hasSeals: pickUp.hasSeals,
    seals: pickUp.seals,
  })

  createGateOutRequest = async (data: IGateOutFormData) => {
    this.setLicensePlate(data.truckPlate)
    this.setDirection(CarrierVisitDirection.Outbound)

    this.createRequest(async () => {
      try {
        const orders = await this.getOrdersWithDamage(data.checkOrders.filter(i => i.isEdited))

        const updateRequests = orders.map(this.updateOrder)
        await Promise.all(updateRequests)

        await truckVisitService.allowExit({
          id: data.visitId,
          orderIds: data.checkOrders.filter(o => o.id).map(o => o.id!),
        })

        this.reset()
      } catch (error: any) {
        this.setRequestErrorMessage('Failed to allow passage')
      }
    })
  }

  private updateOrder = async (order: IInspectContainerFormData) => {
    if (order.id && order.containerNumber && order.containerIsoCode) {
      const request: UpdateOrderCommand = {
        id: order.id,
        direction: order.direction,
        carrierType: order.carrierType,
        containerNumber: order.containerNumber,
        containerIsoCode: order.containerIsoCode,
        containerDamages: order.damages,
        isAccidentalDischarge: false,
        isToBeStoredInYard: true,
        imoClasses: order.imoClasses,
        isEmpty: order.isEmpty,
        operator: order.operator,
        referenceNumber: order.referenceNumber,
        temperature: order.temperature,
        grossWeight: order.grossWeight,
        containerMaxGross: order.containerMaxGross,
        hasSeals: order.hasSeals ?? false,
        seals: order.seals,
        containerTare: order.containerTare,
        isDamaged: !!order.damagesReported?.length ?? false,
        portOfDischarge: order.portOfDischarge,
        portOfLoading: order.portOfLoading,
      }

      await orderService.update(request)
    }
  }

  cancelRequest = () => {
    super.cancelRequest()

    setTimeout(async () => {
      this.reset()
    }, this.delay)
  }

  public async gateInTruck(data: IGateInFormData, allowGateIn: boolean) {
    const { dropOffOrders, pickUpOrders } = await this.getDropOffAndPickUpOrdersWithDamage(data)

    const existingDropOffOrders = dropOffOrders.filter(
      o => o.direction === CarrierVisitDirection.Inbound,
    )
    const existingPickUpOrders = pickUpOrders.filter(
      o => o.direction === CarrierVisitDirection.Outbound,
    )
    const existingDropOffGcOrders =
      (data.generalCargoOrders &&
        data.generalCargoOrders.filter(o => o.direction === CarrierVisitDirection.Inbound)) ||
      []
    const existingPickUpGcOrders =
      (data.generalCargoOrders &&
        data.generalCargoOrders.filter(o => o.direction === CarrierVisitDirection.Outbound)) ||
      []

    const dropOffOrdersToBeCreated = dropOffOrders
      .filter(o => o.direction === CarrierVisitDirection.Outbound)
      .map(this.mapDropOffOrderToCreateContainerOrderDto)
    const pickUpOrdersToBeCreated = pickUpOrders
      .filter(o => o.direction === CarrierVisitDirection.Inbound)
      .map(this.mapPickUpOrderToCreateContainerOrderDto)

    const existingOrders = [
      ...existingDropOffOrders,
      ...existingPickUpOrders,
      ...existingDropOffGcOrders,
      ...existingPickUpGcOrders,
    ]

    const updateRequests = existingOrders.filter(i => i.isEdited).map(this.updateOrder)
    await Promise.all(updateRequests)

    const cmd: CreateTruckAppointmentCommand = {
      licensePlate: data.truckPlate,
      driverName: data.driverName,
      eta: data.eta,
      etd: data.etd,
      ordersWithDirection: existingOrders
        .filter(o => o.id)
        .map(o => ({
          id: o.id!,
          direction: o.direction!,
        })),
      ordersToBeCreated: [...dropOffOrdersToBeCreated, ...pickUpOrdersToBeCreated],
      nnrOrders: data.nnrOrders.map(nnr => {
        return { nnrOrderId: nnr.id, amountToPickUp: nnr.pickUpAmount }
      }),
    }

    const appointment = await this.upsertAppointment(data, cmd)

    if (allowGateIn) {
      await truckVisitService.allowEntry({
        id: appointment.truckVisitId,
        inboundOrderIds: appointment.orders
          .filter(o => o.carrierVisitDirection === CarrierVisitDirection.Inbound)
          .map(o => o.orderId),
        outboundOrderIds: appointment.orders
          .filter(o => o.carrierVisitDirection === CarrierVisitDirection.Outbound)
          .map(o => o.orderId),
      })
    }

    this.reset()
  }

  private async upsertAppointment(data: IGateInFormData, cmd: CreateTruckAppointmentCommand) {
    if (data.truckVisitId)
      return await truckAppointmentService.put({
        ...cmd,
        truckVisitId: data.truckVisitId,
      })

    return await truckAppointmentService.post(cmd)
  }

  reset() {
    super.reset()
    this.setLicensePlate()
    this.setDirection()
  }

  private async getOrdersWithDamage(orders: IInspectContainerFormData[]) {
    const ordersWithDamage = [...orders]

    const imgUploadRequests = ordersWithDamage
      ?.map((x, index) => ({
        index: index,
        request:
          x.containerNumber && x.damagesReported?.length
            ? containerService.getDamageDtoForUploadedDamages(x.containerNumber, x.damagesReported)
            : undefined,
      }))
      .filter(x => x.request)

    const imgUploadResults = await Promise.all(imgUploadRequests.map(x => x.request))

    ordersWithDamage.forEach((order, index) => {
      const imagRequestIndex = imgUploadRequests.findIndex(x => x.index === index)
      if (index >= 0) {
        order.damages =
          imgUploadResults[imagRequestIndex]?.map(x => x as ContainerDamageResponseDto) ?? []
      }
    })

    return ordersWithDamage
  }

  private async getDropOffAndPickUpOrdersWithDamage(data: IGateInFormData) {
    const dropOffOrders = [...data.dropOffOrders]
    const pickUpOrders = [...data.pickUpOrders]

    const imgUploadRequests = dropOffOrders
      ?.map((x, index) => ({
        index: index,
        isForDropOff: true,
        request:
          x.containerNumber && x.damagesReported?.length
            ? containerService.getDamageDtoForUploadedDamages(x.containerNumber, x.damagesReported)
            : undefined,
      }))
      .filter(x => x.request)

    pickUpOrders.forEach((x, index) => {
      if (x.containerNumber && x.damagesReported?.length) {
        imgUploadRequests.push({
          index: index,
          isForDropOff: false,
          request:
            x.containerNumber && x.damagesReported?.length
              ? containerService.getDamageDtoForUploadedDamages(
                  x.containerNumber,
                  x.damagesReported,
                )
              : undefined,
        })
      }
    })

    const imgUploadResults = await Promise.all(imgUploadRequests.map(x => x.request))

    dropOffOrders.forEach((order, index) => {
      const imagRequestIndex = imgUploadRequests.findIndex(x => x.index === index && x.isForDropOff)
      if (index >= 0) {
        order.damages =
          imgUploadResults[imagRequestIndex]?.map(x => x as ContainerDamageResponseDto) ?? []
      }
    })

    pickUpOrders.forEach((order, index) => {
      const imagRequestIndex = imgUploadRequests.findIndex(
        x => x.index === index && !x.isForDropOff,
      )
      if (index >= 0) {
        order.damages =
          imgUploadResults[imagRequestIndex]?.map(x => x as ContainerDamageResponseDto) ?? []
      }
    })

    return { dropOffOrders, pickUpOrders }
  }
}
