import {
  AllocationRuleTemplateDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  YardPositionDto,
} from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { action, makeObservable, observable } from 'mobx'
import { mapAllocationRuleTemplateToCarrierVisitAllocationRuleDto } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleTemplatesV2Store } from './allocation-rule-templates-v2.store'
import { CarrierVisitAllocationRulesV2Store } from './carrier-visit-allocation-rules-v2.store'
import { CarrierVisitAllocationRulesV2UIStore } from './carrier-visit-allocation-rules-v2.ui-store'
import { CarrierVisitUnAllocatedTurnoversV2UIStore } from './carrier-visit-unallocated-turnovers-v2.ui-store'

export class AllocationRuleTemplatesV2UIStore {
  searchQuery = ''

  constructor(
    private readonly _allocationRuleTemplatesStore: AllocationRuleTemplatesV2Store,
    private readonly _carrierVisitAllocationRulesStore: CarrierVisitAllocationRulesV2Store,
    private readonly _carrierVisitAllocationRulesV2UIStore: CarrierVisitAllocationRulesV2UIStore,
    private readonly _carrierVisitAllocationTurnoversStore: CarrierVisitUnAllocatedTurnoversV2UIStore,
    public readonly _snackbarStore: SnackbarStore,
    public readonly dialogUtilStore: DialogUtilStore,
  ) {
    makeObservable(this, {
      searchQuery: observable,
      setSearchQuery: action,
      resetSearchFilter: action,
    })
  }

  async loadAllocationRuleTemplates() {
    this.resetSearchFilter()
    await this._allocationRuleTemplatesStore.loadAll()
  }

  resetSearchFilter() {
    this.searchQuery = ''
  }

  get allocationRuleTemplates(): AllocationRuleTemplateDto[] {
    if (!this.searchQuery) return this._allocationRuleTemplatesStore.entries

    return this._allocationRuleTemplatesStore.entries.filter(entry =>
      entry.name.toLowerCase().includes(this.searchQuery),
    )
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }

  get searchInfoMessage() {
    if (
      this._allocationRuleTemplatesStore.entries.length &&
      this.searchQuery &&
      !this.allocationRuleTemplates.length
    ) {
      return tolgee.t(
        'allocationRuleTemplatesSearchInfoMessage',
        'No allocation rule templates match the name you searched for. Please try a different name.',
      )
    } else if (!this._allocationRuleTemplatesStore.entries.length) {
      return tolgee.t(
        'noAllocationRuleTemplates',
        'There are no allocation rule templates available. Please create a new one.',
      )
    }
  }

  //TODO: to be refactored after removing deprecated version
  async copyRuleTemplateToCarrierVisit(
    carrierVisitId: number,
    allocationRuleId: string,
    destination: YardPositionDto,
    onSuccess: (allocationRule: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    const allocationRuleTemplate = this._allocationRuleTemplatesStore.getById(allocationRuleId)

    if (!allocationRuleTemplate) return

    const carrierVisitAllocationRule = mapAllocationRuleTemplateToCarrierVisitAllocationRuleDto(
      allocationRuleTemplate,
      destination,
    )

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    let turnovers = this._carrierVisitAllocationTurnoversStore.containerTurnovers

    this._carrierVisitAllocationRulesStore.entries.forEach(rule => {
      turnovers = this._carrierVisitAllocationRulesV2UIStore.filterTurnoversByAllocationRule(
        rule,
        turnovers,
      )
    })

    onSuccess(carrierVisitAllocationRule)

    onFinally()
  }

  //TODO: to be refactored after removing deprecated version
  async copyRuleTemplateToCarrierVisit_Deprecated(
    carrierVisitId: number,
    allocationRuleId: string,
    carrierVisitDirection: CarrierVisitDirection,
    destination: YardPositionDto,
    onSuccess: (allocationRule: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    await this._allocationRuleTemplatesStore
      .copyRuleTemplateToCarrierVisit(
        carrierVisitId,
        allocationRuleId,
        carrierVisitDirection,
        destination,
      )
      .then((carrierVisitAllocationRule: CarrierVisitAllocationRuleDto) => {
        // Add copied rule to carrier visit allocation rules store' data
        this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)
        // Activate allocation rule
        onSuccess(carrierVisitAllocationRule)
        this._snackbarStore.showMessage(
          tolgee.t(
            'allocationRuleTemplateCopySuccess',
            'The allocation rule template is successfully copied',
          ),
          'success',
        )
      })
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'allocationRuleTemplateCopyFailure',
            'An unexpected error occurred while copying the allocation rule template',
          ),
          'error',
        ),
      )
      .finally(onFinally)
  }
}
