import { CarrierVisitAllocationRuleDto, CarrierVisitDirection } from '@storage/app/api'
import { FormType } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import AllocationRuleTemplatesForm from '../forms/allocation-rule-templates-form/AllocationRuleTemplatesForm'

interface CarrierVisitAllocationRuleListItemDialogProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
  setAllocationRuleState: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void
  //TODO: prop to be removed after removing yard-planning-dashboard-details-v-2 feature flag
  deprecatedVersion: boolean
}

const CarrierVisitAllocationRuleListItemDialog = observer(
  ({
    carrierVisitId,
    carrierVisitDirection,
    setAllocationRuleState,
    deprecatedVersion,
  }: CarrierVisitAllocationRuleListItemDialogProps) => {
    const { t } = useTranslate()
    const {
      carrierVisitAllocationRulesV2Store,
      carrierVisitAllocationRulesV2UIStore,
      manualInputValidationStore,
      weightClassContainerUIStore,
      customerStore,
    } = useStores()

    const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.listItemDialogUtilStore

    useEffect(() => {
      weightClassContainerUIStore.loadWeightClasses()
    }, [weightClassContainerUIStore])

    const formId = 'allocation-rules-form'
    const saveLabel = t('save', 'Save')
    const cancelLabel = t('cancel', 'Cancel')
    const deletionConfirmationMessage = t(
      'allocationRuleWillBeRemoved',
      'This allocation rule will be removed and you cannot undo this action!',
    )

    const getDialogLabels = (action: FormType) => {
      switch (action) {
        case 'Add':
          return {
            title: t('addAllocationRule', 'Add Allocation Rule'),
            buttons: { primary: saveLabel, secondary: cancelLabel },
          }
        case 'Edit':
          return {
            title: t('updateAllocationRule', 'Edit Allocation Rule'),
            buttons: { primary: saveLabel, secondary: cancelLabel },
          }
        case 'Delete':
          return {
            title: t('removeAllocationRule', 'Remove Allocation Rule?'),
            buttons: { primary: t('remove', 'Remove'), secondary: cancelLabel },
          }
        default:
          return { title: '', buttons: { primary: '', secondary: '' } }
      }
    }

    const dialogLabels = getDialogLabels(dialogUtilStore.dialogAction)

    const handleOnClose = () => dialogUtilStore.toggleDialog()

    const handleOnSubmit = (formValues: AllocationRuleTemplateFormProfile) => {
      if (dialogUtilStore.dialogAction === 'Add') {
        carrierVisitAllocationRulesV2UIStore.addAllocationRule(
          formValues,
          setAllocationRuleState,
          handleOnClose,
        )
      } else if (dialogUtilStore.dialogAction === 'Edit') {
        deprecatedVersion === true
          ? carrierVisitAllocationRulesV2UIStore.updateAllocationRule_Deprecated(
              formValues,
              setAllocationRuleState,
              handleOnClose,
            )
          : carrierVisitAllocationRulesV2UIStore.updateAllocationRule(
              formValues,
              setAllocationRuleState,
              handleOnClose,
            )
      }
    }

    const handleOnDelete = async () =>
      deprecatedVersion === true
        ? await carrierVisitAllocationRulesV2UIStore.deleteAllocationRule_Deprecated(
            setAllocationRuleState,
            handleOnClose,
          )
        : await carrierVisitAllocationRulesV2UIStore.deleteAllocationRule(
            setAllocationRuleState,
            handleOnClose,
          )

    return (
      <ConfirmationDialog
        open={dialogUtilStore.isDialogOpen}
        title={dialogLabels.title}
        primaryActionText={dialogLabels.buttons.primary}
        closeLabel={dialogLabels.buttons.secondary}
        formId={formId}
        maxWidth='sm'
        primaryActionDisabled={
          dialogUtilStore.dialogAction !== 'Delete' && !manualInputValidationStore.isAccepted
        }
        onClose={handleOnClose}
        hasBorderTop={dialogUtilStore.dialogAction !== 'Delete'}
        blockClickOutside={dialogUtilStore.dialogAction === 'Delete'}
        message={
          dialogUtilStore.dialogAction === 'Delete' ? deletionConfirmationMessage : undefined
        }
        onConfirm={dialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
        primaryActionDataCy='allocation-confirm-btn'
      >
        {dialogUtilStore.dialogAction !== 'Delete' && (
          <AllocationRuleTemplatesForm
            id={formId}
            manualInputValidationStore={manualInputValidationStore}
            onSubmit={handleOnSubmit}
            allocationRuleTemplate={
              dialogUtilStore.dialogEntityId
                ? carrierVisitAllocationRulesV2Store.getById(dialogUtilStore.dialogEntityId)
                : undefined
            }
            externalDefaultValues={carrierVisitAllocationRulesV2UIStore.formExternalDefaultValues}
            weightClassUIStore={weightClassContainerUIStore}
            customerStore={customerStore}
            openWithOnlyContainerNumber={
              carrierVisitAllocationRulesV2UIStore.openDialogForOnlyContainerNumber
            }
          />
        )}
      </ConfirmationDialog>
    )
  },
)

export default CarrierVisitAllocationRuleListItemDialog
