export enum EventTypes {
  CarrierVisitRefresh = 'CarrierVisitRefresh',
  ReeferTemperatureUpserted = 'ReeferTemperatureUpserted',
  WorkInstructionDeleted = 'WorkInstructionDeleted',
  WorkInstructionFinished = 'WorkInstructionFinished',
  JobUpserted = 'JobUpserted',
  WorkInstructionConfirmed = 'WorkInstructionConfirmed',
  EquipmentsJobRefresh = 'EquipmentsJobRefresh',
  WagonWeightUpdated = 'WagonWeightUpdated',
  WorkInstructionJobsUpserted = 'WorkInstructionJobsUpserted',
  CoolingOrderUpserted = 'CoolingOrderUpserted',
  WorkInstructionUpserted = 'WorkInstructionUpserted',
  EquipmentLastPositionOnYard = 'EquipmentLastPositionOnYard',
  CarrierVisitWorkInstructionUpserted = 'CarrierVisitWorkInstructionUpserted',
  EquipmentUpserted = 'EquipmentUpserted',
  EquipmentDeleted = 'EquipmentDeleted',
}
