import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { RailVisitResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { RailcarAutocomplete } from '@planning/components'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import IconTruck from '@planning/components/icon/IconTruck'
import { RailVisitAutoComplete } from '@planning/components/visit/RailVisitAutoComplete'
import { VesselVisitAutoComplete } from '@planning/components/visit/VesselVisitAutoComplete'
import RailTrackAutocomplete from '@planning/pages/Order/components/RailTrackAutocomplete'
import { useTranslate } from '@tolgee/react'
import dayjs, { Dayjs } from 'dayjs'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { IssuesViewStore } from '../Stores/IssuesViewStore'

interface IProps {
  store: IssuesViewStore
}

const ChangeTransportForm: React.FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const { railcarTrackPositionItemStore, railTrackItemStore } = usePlanningStore()
  const [carrierType, setCarrierType] = useState<string>('')
  const [showDatetimeForPickUp, setShowDatetimeForPickUp] = useState(false)
  const [releaseExpires, setReleaseExpires] = useState<Dayjs | null>(null)
  const showErrors = store.showErrors

  useEffect(() => {
    railTrackItemStore.fetch()
  }, [railTrackItemStore])

  const fetchRailcarTrackPositions = (visit?: RailVisitResponseDto) => {
    if (visit?.railcarTrackPositionIds?.length) {
      railcarTrackPositionItemStore.fetchByIds(visit.railcarTrackPositionIds)
    }
  }

  const handleFieldChange = (field: keyof typeof store.transportData, value: any) => {
    store.updateTransportData(field, value)
  }

  const getRailTracksByVisit = (visitId?: number) => {
    const railTracks = railcarTrackPositionItemStore.getRailTracks()
    if (visitId) {
      const railTrackIds = store.selectedRailVisit?.assignedRailTrackIds || []
      return railTracks.filter(x => railTrackIds.includes(x.id))
    }
    return railTracks
  }

  const railTracks = computed(() => getRailTracksByVisit(store.transportData.carrierVisitId!)).get()

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <FormControl fullWidth error={showErrors && !carrierType}>
          <InputLabel id='carrier-select'>{t('carrier', 'Carrier')}</InputLabel>
          <Select
            labelId='carrier-select'
            label={t('carrier', 'Carrier')}
            value={carrierType}
            onChange={event => {
              handleFieldChange('carrierType', event.target.value)
              handleFieldChange('railTrackId', undefined)
              handleFieldChange('railcarId', undefined)
              handleFieldChange('carrierVisitId', undefined)
              handleFieldChange('releaseExpired', undefined)

              store.selectedVesselVisit = undefined
              store.selectedRailVisit = undefined

              setCarrierType(event.target.value)
            }}
          >
            <MenuItem value='Train'>{t('train', 'Train')}</MenuItem>
            <MenuItem value='Truck'>{t('truck', 'Truck')}</MenuItem>
            <MenuItem value='Vessel'>{t('vessel', 'Vessel')}</MenuItem>
          </Select>
          {showErrors && !carrierType && (
            <Typography color='error'>{t('fieldIsRequired', 'Field is required')}</Typography>
          )}
        </FormControl>
      </Grid>

      {carrierType === 'Train' && (
        <>
          <Grid item xs={3}>
            <RailVisitAutoComplete
              selectedRailVisit={store.selectedRailVisit}
              error={showErrors && !store.transportData.carrierVisitId}
              handleOnChange={async visit => {
                store.selectedRailVisit = visit
                fetchRailcarTrackPositions(visit)
                handleFieldChange('carrierVisitId', visit?.id)
              }}
              cargoType='Container'
            />
          </Grid>

          <Grid item xs={3}>
            <RailTrackAutocomplete
              label='Rail Track'
              hasError={showErrors && !store.transportData.railTrackId}
              value={store.transportData.railTrackId!}
              options={railTracks}
              onChange={id => handleFieldChange('railTrackId', id ?? null)}
            />
          </Grid>

          <Grid item xs={3}>
            <RailcarAutocomplete
              hasError={showErrors && !store.transportData.railcarId}
              label={'Railcar no.'}
              value={store.transportData.railcarId!}
              onChange={id => handleFieldChange('railcarId', id ?? null)}
            />
          </Grid>
        </>
      )}

      {carrierType === 'Vessel' && (
        <Grid item xs={5}>
          <VesselVisitAutoComplete
            selectedVesselVisit={store.selectedVesselVisit}
            error={showErrors && !store.transportData.carrierVisitId}
            handleOnChange={visit => {
              store.selectedVesselVisit = visit
              handleFieldChange('carrierVisitId', visit?.id)
            }}
            cargoType='Container'
          />
        </Grid>
      )}

      {carrierType === 'Truck' && (
        <>
          <Grid item xs={5}>
            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
              <IconTruck zoom={150} />
              <Typography variant='subtitle1' ml='.5rem'>
                {t('truck', 'Truck')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ mt: '1rem' }}>
              <Typography variant='subtitle2' mb={'.75rem'}>
                {`${t('containerIsReleasedForPickUp', 'Container is released for pick-up')}?`}
              </Typography>
              <Chip
                label={t('yes', 'Yes')}
                color='primary'
                sx={{ padding: '0 1rem', mr: '.75rem' }}
                variant={!showDatetimeForPickUp ? 'outlined' : undefined}
                onClick={() => {
                  setShowDatetimeForPickUp(true)
                  setReleaseExpires(dayjs())
                  handleFieldChange('releaseExpired', dayjs())
                }}
              />
              <Chip
                label={t('no', 'No')}
                color='primary'
                sx={{ padding: '0 1rem' }}
                variant={showDatetimeForPickUp ? 'outlined' : undefined}
                onClick={() => {
                  setShowDatetimeForPickUp(false)
                  setReleaseExpires(null)
                }}
              />
            </Box>

            {showDatetimeForPickUp && (
              <Box sx={{ mb: '1rem', mt: '1rem' }}>
                <CustomDateTimePicker
                  label={t('releaseExpiresAfter', 'Release expires after')}
                  value={releaseExpires?.toString()}
                  onChange={(value: Dayjs | null) => {
                    setReleaseExpires(value)
                    handleFieldChange('releaseExpired', value)
                  }}
                  popperPlacement={'top-end'}
                />
              </Box>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
})

export default ChangeTransportForm
